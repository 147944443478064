/// <reference path="../../../../../Content/angular/angular.d.ts" />
angular.module("Zotec.PatientExperience.Controls")
    .directive("zpPhone", ["$filter", "$browser", ($filter, $browser) => {
        return {
            restrict: "A",
            require: "ngModel",
            link: (scope: angular.IScope, element: JQuery, attrs: angular.IAttributes, ngModel: angular.INgModelController) => {
                element.attr("placeholder", "xxx-xxx-xxxx");
                var listener = () => {
                    var value = element.val().replace(/[^0-9]/g, "");
                    element.val($filter("tel")(value, false));
                }

                ngModel.$parsers.push((viewValue) => {
                    return viewValue.replace(/[^0-9]/g, "").slice(0, 10);
                });

                ngModel.$render = () => {
                    element.val($filter("tel")(ngModel.$viewValue, false));
                }

                element.bind("change", listener);
                element.bind("keydown", (event) => {
                    const key = event.keyCode;
                    //if the keys are CTRL, SHIFT, ALT, or META keys, or an arrow key, do nothing
                    const ignoredKeys = [91, 92, 16, 17, 18, 37, 38, 39, 40];
                    
                    if (ignoredKeys.includes(key)) {
                        return;
                    }
                    $browser.defer(listener);
                });

                element.bind("paste cut", () => {
                    $browser.defer(listener);
                });
            }
        };
    }]);