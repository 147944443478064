/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience").factory("$geocode", ["$http", ($http) => {
    function $geocode(a: zotec.patientExperience.Address, completion: Function) {
        if (a.streetAddress === null || a.city === null || a.state === null || a.zip === null) {
            completion(null);
            return;
        }
        const fullAddress = a.streetAddress + "," + a.city + "," + a.state + "," + a.zip;
        $http({
            method: "GET",
            url: "https://maps.googleapis.com/maps/api/geocode/json?address=" + fullAddress + "&key=AIzaSyDhdrRjAIoJwHfbqXoMji4zb9V_cxaPORI"
        }).then((response) => {
            if (response.data.results.length > 0) {
                completion(response.data.results[0].geometry.location);
            } else {
                completion(null);
            }
        }, () => {
            completion(null);
        });
    }

    return $geocode;
}]);
