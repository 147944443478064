/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Login", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.login", {
            url: "/login",
            template: "<zp-login></zp-login>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("Landing", entity);
                }]
            }
        });
    }]);