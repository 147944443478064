/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$serviceInfoService", ["$modal", "$rootScope", "$guarantor", "$siteSettings",
    function ($modal, $rootScope, $guarantor: zotec.patientExperience.Services.GuarantorService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService) {

        this.serviceInfo = (null as zotec.patientExperience.services.ServiceInfo);

        function $getServiceInfo(chargeId: number) {
            $guarantor.restangularized().one("charges", chargeId).get().then((serviceInfo: zotec.patientExperience.services.ServiceInfo) => {

                var scope = $rootScope.$new();

                if ($siteSettings.isGuarantorBillingClient) {
                    serviceInfo.eligibleForInsuranceUpdate = true;
                }

                scope.serviceInfo = serviceInfo;

                if (serviceInfo.procedureId !== null && serviceInfo.procedureId.trim() !== "") {
                    scope.imgUrl = `/api/ProcedureImage/${serviceInfo.procedureId}`;
                }

                var modal = $modal({
                    templateUrl: "Templates/Controls/Services/ServiceInfo",
                    scope: scope
                });
                scope.close = () => {
                    modal.hide();
                }
            }, (response) => {
                console.log("error with status code:", response.status);
            });
        }

        return $getServiceInfo;
    }
]);