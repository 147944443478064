/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />
/// <reference path="../../../../../Content/zotec/js/models.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpInsurancePolicy", ["$state", ($state) => {
        return {
            templateUrl: "Templates/Controls/Directives/InsurancePolicy",
            scope: {
                policy: "=",
                encounterDetails: "=",
                disabled: "=",
                shouldVerify: "="
            },
            link: (scope: InsurancePolicyScope, element: JQuery, attributes: angular.IAttributes) => {
                scope.popover = {
                    trigger: "hover",
                    content: "You must verify your insurance policy(s) before continuing."
                };
                scope.verifyInsurance = () => {
                    if (scope.policy === null || scope.policy.policyNumber === null) {
                        $state.go("entity.insurance-add", {
                            patientId: scope.policy.personId,
                            encounterDetails: scope.encounterDetails
                        });
                    } else {
                        $state.go("entity.insurance-edit", {
                            policy: scope.policy,
                            encounterDetails: scope.encounterDetails,
                        });
                    }
                };

            }
        };
    }]);

interface InsurancePolicyScope extends angular.IScope {
    popover: {};
    verifyInsurance: {};
    policy: zotec.patientExperience.insurance.InsurancePolicy;
    encounterDetails: zotec.patientExperience.insurance.EncounterDetails;
    disabled: boolean;
    shouldVerify: boolean;
}