/// <reference path="../../models.d.ts" />
angular
    .module('Zotec.PatientExperience')
    .directive('phoneCallLink', ["$phoneNumberUtils", ($phoneNumberUtils: zotec.patientExperience.Services.PhoneNumberService) => {
        return {
            restrict: 'A',
            scope: false,
            link: function (_, elem, attr) {
                var phoneNumber = $phoneNumberUtils.getUnformattedPhoneNumber(attr.phoneCallLink);
                elem.attr('href', 'tel:' + phoneNumber);
                elem.html($phoneNumberUtils.getFormattedPhoneNumber(phoneNumber));
            }
        };
    }]);