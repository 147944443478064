/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$mdsService",
    ["Restangular", "$siteSettings", "$guarantor", "$http", "$q",
        function (Restangular: restangular.IService,
            $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $guarantor: zotec.patientExperience.Services.GuarantorService,
            $http,
            $q: angular.IQService) {

            var loadQualtricsSurveyUrl = function () {                
                var request = {
                    appName: "PXP",
                    personId: $guarantor.guarantorId > 0 ? $guarantor.guarantorId : 0,
                    datasourceId: $siteSettings.dataSourceId,
                    clientId: $siteSettings.clientId,
                    billingGroupId: $siteSettings.billingGroupId
                }

                var deferred = $q.defer();
                Restangular.all("Survey").post(request)
                    .then((result : zotec.patientExperience.MDS.QualtricsSurveyUrlResult) => {
                        if (result) {   
                            deferred.resolve(result);
                        }
                    }, (error) => {                            
                            deferred.reject("ERROR getting Qualtrics Survey URL");
                    });

                return deferred.promise;
            }

            var loadQualtricsSurveyExpUrl = function () {
                var request = {
                    appName: "PXP",
                    personId: $guarantor.guarantorId > 0 ? $guarantor.guarantorId : 0,
                    datasourceId: $siteSettings.dataSourceId,
                    clientId: $siteSettings.clientId,
                }

                var deferred = $q.defer();
                Restangular.all("Survey/exp").post(request)
                    .then((result: zotec.patientExperience.MDS.QualtricsSurveyUrlResult) => {
                        if (result) {
                            deferred.resolve(result);
                        }
                    }, (error) => {
                        deferred.reject("ERROR getting Qualtrics Survey URL");
                    });

                return deferred.promise;
            }

            var initiateTosReceiptAfterSuccessfulPayment = function (chargeId) {
                var request = {
                    appName: "PXP",
                    personId: $guarantor.guarantorId > 0 ? $guarantor.guarantorId : 0,
                    datasourceId: $siteSettings.dataSourceId,
                    clientId: $siteSettings.clientId,
                    chargeId: chargeId,
                    billingGroupId: $siteSettings.billingGroupId
                }

                var deferred = $q.defer();
                $guarantor.restangularized().all("TelemedPayment/InitiateReceipt").post(request)
                    .then((result: zotec.patientExperience.MDS.InitiateReceiptResponse) => {
                        if (result) {
                            deferred.resolve(result);
                        }
                    }, (error) => {
                        deferred.reject("ERROR initiating TOS receipt after successful payment.");
                    });

                return deferred.promise;
            }

            return {
                loadQualtricsSurveyUrl: loadQualtricsSurveyUrl,
                loadQualtricsSurveyExpUrl: loadQualtricsSurveyExpUrl,
                initiateReceiptAfterSuccessfulPayment: initiateTosReceiptAfterSuccessfulPayment
            }
        }
    ]);