/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Insurance", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.insurance", {
            url: "/insurance",
            template: "<zp-insurance></zp-insurance>",
            params: {
                pending: false,
                noRefile: false,
                encounterDetails: (null as zotec.patientExperience.insurance.EncounterDetails)
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Insurance", entity);
                }]
            }
        });
    }]);