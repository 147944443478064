/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.PaymentPlan")
    .directive("zpPaymentPlan", () => {
        return {
            restrict: "AE",
            controller: "zpPaymentPlanController",
            controllerAs: "zpPaymentPlan",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/PaymentPlan/zpPaymentPlan"
        }
    });