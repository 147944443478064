/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$paymentFailureModal", ["$modal", "$rootScope",
    ($modal, $rootScope) => {

        function $paymentFailureModal() {
            var scope = $rootScope.$new();
            var modal = $modal({
                templateUrl: "Templates/Controls/Services/PaymentFailureModal",
                scope: scope
            });

            scope.acceptAction = () => {
                modal.hide();
                location.reload();
            };

            return modal;
        }

        return $paymentFailureModal;
    }
]);
