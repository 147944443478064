/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpMax", () => {
        return {
            restrict: "A",
            require: "ngModel",
            link: (scope, element, attrs, ctrl: any) => {
                var maxValue = 0;
                attrs.$observe("zpMax", function (value) {
                    const intVal = Number(value);
                    maxValue = isNaN(intVal) ? 0 : intVal;
                    ctrl.$validate();
                });

                ctrl.$validators.zpMax = function (modelValue, viewValue) {
                    return ctrl.$isEmpty(modelValue)
                        || modelValue <= maxValue;
                };
            }
        }
    });