/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpRemoveOnError", [() => {
        return {
            link: (scope, element, attrs) => {
                element.on("error", () => {
                    element.remove();
                });
            }
        };
    }]);