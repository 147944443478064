/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedBillPay")
    .controller("zpTeleMedBillPayController", ["$scope", "Restangular", "$state", "$siteSettings", "$guarantor", "$pdf", "$q",
        "$paymentFailureModal", "$emailReceiptModal", "$loadPaymentModuleLibrary", "$mdsService", "$window", "PaymentModuleFormService", "$patientExperienceSettings",
        function ($scope, Restangular: restangular.IService, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $guarantor: zotec.patientExperience.Services.GuarantorService, $pdf: zotec.patientExperience.Services.PdfService, 
            $q: angular.IQService, $paymentFailureModal, $emailReceiptModal, $loadPaymentModuleLibrary,
            $mdsService: zotec.patientExperience.Services.MDSService,
            $window: angular.IWindowService,
            $paymentModuleFormService: zotec.patientExperience.Services.IPaymentModuleFormService,
            $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings) {

            let ctrl = this;

            this.internalRequest = Restangular.one("internalnetwork").get().$object;

            this.LOADING_STATE = 0;
            this.NORMAL_STATE = 1;
            this.ERROR_STATE = 2;

            this.currentState = this.LOADING_STATE;

            this.PAYMENT_PAGE = 0;
            this.SUCCESS_PAGE = 1;
            this.pageCount = 2;

            this.currentPage = this.PAYMENT_PAGE;

            this.$onInit = () => {
                $scope.$watch(() => ctrl.paymentAmount, (newVal, oldVal) => {
                    if (newVal != undefined && newVal != oldVal && ctrl.paymentModuleFormOptions !== undefined) {
                        ctrl.paymentModuleFormOptions.paymentAmount = newVal;
                    }
                });
            }

            this.applePayCallbacks = {
                onError: (error) => {
                    ctrl.removeApplePayFromPaymentMethods();
                    ctrl.resetSelectedPaymentMethod();
                },
                onApproval: (response) => { },
                onDeclined: (response) => ctrl.removeApplePayFromPaymentMethods(),
                onCancelled: () => {
                    ctrl.removeApplePayFromPaymentMethods();
                    ctrl.resetSelectedPaymentMethod();
                },
            } as zotec.patientExperience.payments.ApplePayTransactionCallbacks;

            this.creditCardFormHandle = null;
            this.echeckFormHandle = null;
            this.isApplePayActive = false;
            this.enableCreditCardFormWithBillingAddress = false;

            this.isLoading = () => {
                return this.currentState == this.LOADING_STATE;
            }

            this.isInErrorState = () => {
                return this.currentState == this.ERROR_STATE;
            }

            this.isInNormalState = () => {
                return this.currentState == this.NORMAL_STATE;
            }

            this.shouldShowPaymentPage = () => {
                return this.isInNormalState() && this.currentPage == this.PAYMENT_PAGE;
            }

            this.shouldShowSuccessPage = () => {
                return this.isInNormalState() && this.currentPage == this.SUCCESS_PAGE;
            }

            this.moveForward = () => {
                if (this.currentPage + 1 >= this.pageCount) {
                    $state.reload();
                    return;
                }
                this.currentPage += 1;
            }

            this.moveBack = () => {
                if (this.currentPage - 1 < 0) {
                    return;
                }
                this.currentPage -= 1;
            }
           
            this.telemedBalance = {};
            this.guarantorBalance = 0;
            this.paymentAmount = 0;
            this.todaysCharge = [];
            this.priorCharges = [];
            this.charges = [];
            this.sessionData = null;
            this.originatingUserInfo = {};

            this.createPaymentModuleCharge = (paymentCharge) => {
                const charge = {
                    id: paymentCharge.chargeId,
                    discount: paymentCharge.discount,
                    discountCode: paymentCharge.discountCode,
                    isCredit: false,
                    paidInFull: paymentCharge.paidInFull,
                    paymentAmount: paymentCharge.paymentAmount,
                    originalAmount: paymentCharge.balance
                }

                return charge;
            }

            this.goToErrorState = (logErrorMessage: string) => {
                if (!this.isInErrorState()) {
                    this.currentState = this.ERROR_STATE;
                    $paymentFailureModal();
                }
                this.logError(logErrorMessage);
                console.log(logErrorMessage);
            }

            this.logError = (message: string) => {
                $siteSettings.billingGroupRestangularized().all("PostProcess").all("Log")
                    .post({ logMessage: message, guarantorId: $guarantor.guarantorId, transactionId: this.transactionId, consoleErrors: this.loadingLibraryErrors });
            }

            this.applePayInitErrorHandler = (message: string) => {
                this.logError(message);
            }

            this.splunkErrorLog = console.error.bind(console);
            this.loadingLibraryErrors = [];
            console.error = function () {
                var argumentsList = [];
                for (var i = 0; i < arguments.length; i++) {
                    argumentsList.push(arguments[i]);
                }
                this.loadingLibraryErrors.push(argumentsList);
                this.splunkErrorLog.apply(console, arguments);
            }.bind(this);

            this.getTermsOfUseUrl = () => {
                var returnUrl = $siteSettings.getTermsOfUseUrl();
                return returnUrl;
            }

            let paymentModuleLibraryPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("Library").get()
                .then((response: string) => {
                    $loadPaymentModuleLibrary(response);
                }, () => {
                    this.goToErrorState("Bill pay was unable to load the Payment Module.");
                });

            let sessionDataPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("SessionData").get()
                .then((data: string) => {
                    this.sessionData = data;
                }, () => {
                    this.goToErrorState("Bill pay was unable to load the Payment Module.");
                });

            let balancePromise = $guarantor.restangularized()
                .all("TelemedPayment").one("TelemedBalance").get()
                .then((telemedBalance: any) => {
                    this.telemedBalance = telemedBalance;
                    this.guarantorBalance = telemedBalance.paymentAmount || 0.0;
                    this.paymentAmount = telemedBalance.paymentAmount || 0.0;
                    this.todaysCharge = telemedBalance.todaysService;
                    this.priorCharges = telemedBalance.priorServices;

                    let isTodaysService = this.todaysCharge.balance > 0;
                    let paymentAmount = isTodaysService ? this.todaysCharge.paymentAmount : 0;
                    let discount = 0;
                    let discountCode = "";
                    let paidInFull = false;

                    _.forEach(telemedBalance.details || [],
                        (detail: any) => {
                            discount = detail.discountPercentage;
                            discountCode = detail.discountCode || "";
                            paidInFull = detail.paidInFull;
                        });

                    this.charges.push({
                        id: 0,
                        discount: discount,
                        discountCode: discountCode,
                        isCredit: false,
                        paidInFull: paidInFull,
                        paymentAmount: paymentAmount,
                        originalAmount: telemedBalance.todaysBalance
                    });
                    _.forEach(this.priorCharges, (chargeGroup: any) => {
                        _.forEach(chargeGroup.charges, (charge: any) => {
                           this.charges.push(this.createPaymentModuleCharge(charge));
                        });
                    });
                }, () => {
                    this.goToErrorState("Bill pay was unable to retrieve client information for user.");
                }); 

            this.availablePaymentMethods = [];
            this.payOnlyPhone = null as string;
            this.canPayOnline = null as boolean;

            let billingGroupClientInfoPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    if (info.acceptCreditCard) {
                        this.availablePaymentMethods.push({
                            name: "Credit Card",
                            key: "cc"
                        });
                    }
                    if (info.acceptElectronicCheck) {
                        this.availablePaymentMethods.push({
                            name: "Electronic Check",
                            key: "echk"
                        });
                    }
                    this.canPayOnline = this.availablePaymentMethods.length > 0;
                    this.payOnlyPhone = info.payOnlyPhone;
                }, () => {
                    this.goToErrorState("Bill pay was unable to retrieve client information for user.");
                });

            const originatingUserInfoPromise = $siteSettings.billingGroupRestangularized()
                .all("PaymentModule")
                .get("OriginatingUserInfo")
                .then((oui) => this.originatingUserInfo = oui)
                .catch(() => {
                    $siteSettings.billingGroupRestangularized().all("PaymentModule").all("Log").post({
                        clientId: $siteSettings.clientId,
                        billingGroupId: $siteSettings.billingGroupId,
                        logMessage: "Could not load the Payment Module library and getting originating user info."
                    });
                    $paymentFailureModal();
                });

            let enableCreditCardFormWithBillingAddressTogglePromise = Restangular.one("ApplicationFeatures").get({
                    featureName: $patientExperienceSettings.features.enableCreditCardFormWithBillingAddress,
                    featureVersion: "1.0.0"
                }).then((response: string) => {
                    this.enableCreditCardFormWithBillingAddress = response === 'True';
                }, () => {
                    this.enableCreditCardFormWithBillingAddress = false;
                });

            $q.all([enableCreditCardFormWithBillingAddressTogglePromise,
                paymentModuleLibraryPromise,
                originatingUserInfoPromise,
                sessionDataPromise,
                balancePromise,
                billingGroupClientInfoPromise
            ]).finally(() => {
                this.postInitHandler();
            });

            this.transactionId = null as string;
            this.acceptedCreditCards = [];
            this.selectedPaymentMethod = "";

            this.ccFormWatchUnregister = null;
            this.ecFormWatchUnregister = null;

            this.onPaymentMethodChanged = (form) => {
                var paymentModuleApi: zotec.patientExperience.payments.IPaymentModuleApiV3 = $window["PaymentModule"];
                this.disposeForms();
                this.isApplePayActive = this.selectedPaymentMethod === 'apple';

                switch (this.selectedPaymentMethod) {
                    case "cc":
                        const ccForm = paymentModuleApi.InitCreditCardForm("payment-module-credit-card-container", { includeSwipe: false, includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.creditCardFormHandle = $paymentModuleFormService.configureForm(ccForm, this.paymentModuleFormOptions);
                        this.ccFormWatchUnregister = $scope.$watch(() => form.$valid, (newVal, oldVal) => {
                            if (newVal != undefined && newVal != oldVal) {
                                if (newVal) {
                                    this.creditCardFormHandle.Enable();
                                } else {
                                    this.creditCardFormHandle.Disable();
                                }
                            }
                        });

                        break;
                    case "echk":
                        const ecForm = paymentModuleApi.InitECheckForm("payment-module-electronic-check-container", { includeNotes: false, includeBillingAddress: this.includeBillingAddress });
                        this.echeckFormHandle = $paymentModuleFormService.configureForm(ecForm, this.paymentModuleFormOptions);
                        this.ecFormWatchUnregister = $scope.$watch(() => form.$valid, (newVal, oldVal) => {
                            if (newVal != undefined && newVal != oldVal) {
                                if (newVal) {
                                    this.echeckFormHandle.Enable();
                                } else {
                                    this.echeckFormHandle.Disable();
                                }
                            }
                        });

                        break;
                    default:
                        break;
                }
            }

            this.disposeForms = () => {
                if (this.ccFormWatchUnregister) {
                    this.ccFormWatchUnregister();
                    this.ccFormWatchUnregister = null;
                }

                if (this.creditCardFormHandle) {
                    this.creditCardFormHandle.Dispose();
                    this.creditCardFormHandle = null;
                }

                if (this.ecFormWatchUnregister) {
                    this.ecFormWatchUnregister();
                    this.ecFormWatchUnregister = null;
                }

                if (this.echeckFormHandle) {
                    this.echeckFormHandle.Dispose();
                    this.echeckFormHandle = null;
                }
            }

            this.successfulPayment = (null as PaymentSummary);

            this.getPaymentId = (responses: zotec.patientExperience.payments.TOSPaymentResponse[]) => {
                if (responses && responses.length === 0) {
                    return 0;
                }

                var response = responses[0];
                if (response) {
                    return response.success ? response.paymentId : 0;
                } else {
                    return 0;
                }
            }

            this.getConfirmationNumber = (responses: zotec.patientExperience.payments.TOSPaymentResponse[]) => {
                if (responses && responses.length === 0) {
                    return 'Unknown';
                }

                return _.map(responses, r => r.approvalCode).join(', ');
            }

            this.handlePaymentCompletion = (response: zotec.patientExperience.payments.WebHookResponse) => {
                if (response.isSuccess) {
                    const method = ctrl.availablePaymentMethods.find(m => m.key == ctrl.selectedPaymentMethod);
                    response.parsedWebHookResponse = response.webhookResponse ? JSON.parse(response.webhookResponse) : undefined;
                    const paymentId = response.parsedWebHookResponse ? ctrl.getPaymentId(response.parsedWebHookResponse.responses) : 0;
                    const confirmationNumber = response.parsedWebHookResponse ? ctrl.getConfirmationNumber(response.parsedWebHookResponse.responses) :'Unknown';
                    ctrl.guarantorBalance = (ctrl.guarantorBalance - ctrl.paymentAmount).toFixed(2);
                    ctrl.successfulPayment = new PaymentSummary("Payment Successful", ctrl.paymentAmount
                        , ctrl.guarantorBalance, confirmationNumber, paymentId
                        , method.name + ' ' + response.maskedAccountNumber.substr(8));
                    ctrl.paymentAmount = ctrl.guarantorBalance;
                    ctrl.setSuccessButtonColClass();
                    ctrl.moveForward();
                    $mdsService.initiateReceiptAfterSuccessfulPayment(paymentId).then(
                        (receiptResult: zotec.patientExperience.MDS.InitiateReceiptResponse) => {
                            console.log(receiptResult.success);
                        },
                        (error) => {
                            ctrl.logError(error);
                        });
                } else {
                    response.parsedWebHookResponse = response.webhookResponse ? JSON.parse(response.webhookResponse) : undefined;
                    const errorMessage = response.parsedWebHookResponse ? response.parsedWebHookResponse.responses[0].errorMessage : "Something went wrong trying to make the payment.";
                    ctrl.goToErrorState(errorMessage);
                }
                $scope.$digest();
            }

            this.successButtonColClass = "col-md-6";

            this.setSuccessButtonColClass = () => {
                if (this.successfulPayment.remainingBalance > 0) {
                    this.successButtonColClass = "col-md-3";
                }
            }

            this.printReceipt = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/download", { paymentId: this.successfulPayment.paymentId, isPrePay: false, isTOSReceipt: true }, "Generating receipt...", null);
            }

            this.showEmailReceiptModal = () => {
                $emailReceiptModal(this.successfulPayment.paymentId, true);
            }

            this.logOut = () => {
                $guarantor.logOut();
            }

            this.postInitHandler = () => {
                if (!this.isInErrorState()) {
                    this.minPaymentAmountActual = Math.min(this.minPaymentAmountActual, this.guarantorBalance);
                    this.currentState = this.NORMAL_STATE;

                    this.paymentModuleApi = $window["PaymentModule"];

                    this.paymentModuleFormOptions = {
                        chargeType: "TOS",
                        appName: "TOS",
                        entityId: this.telemedBalance.entityId,
                        locationId: this.telemedBalance.locationId,
                        sessionData: this.sessionData,
                        charges: this.charges,
                        paymentAmount: ctrl.paymentAmount,
                        originatingUserInfo: this.originatingUserInfo,
                        onSuccessCallback: this.handlePaymentCompletion,
                        webhookUrl: this.buildWebhookUrl(),
                    } as zotec.patientExperience.PaymentModuleFormOptions;
                }
            }

            this.buildWebhookUrl = (): string => {
                return `${$window.location.origin}/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/PostProcess/TelemedPayment/${$guarantor.guarantorId}`
            }

            this.removeApplePayFromPaymentMethods = () => {
                this.availablePaymentMethods = this.availablePaymentMethods.filter((x) => x.key !== "apple");
            }

            this.resetSelectedPaymentMethod = () => {
                this.selectedPaymentMethod = "";
                $scope.$apply();
            }

            this.applePayClickHandler = () => {
                this.availablePaymentMethods.push({
                    name: "Apple Pay",
                    key: "apple"
                });

                this.selectedPaymentMethod = "apple";
                this.onPaymentMethodChanged(null);
            }
        }]);
