/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedPaymentPlan")
    .controller("zpTeleMedPaymentPlanController", ["Restangular", "$scope", "$siteSettings", "$guarantor", "$state", "$paymentFailureModal", "$emailReceiptModal",
        "$q", "$loadPaymentModuleLibrary", "$pdf", "$mdsService", "$patientExperienceSettings",
        function (Restangular: restangular.IService, $scope, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $guarantor: zotec.patientExperience.Services.GuarantorService,
            $state, $paymentFailureModal, $emailReceiptModal, $q: angular.IQService,
            $loadPaymentModuleLibrary, $pdf: zotec.patientExperience.Services.PdfService,
            $mdsService: zotec.patientExperience.Services.MDSService,
            $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings) {

            this.telemedBalance = {};
            this.guarantorBalance = 0;
            this.locationId = '';
            this.teleMedPaymentPlan = {};
            this.todaysCharge = {};
            this.priorCharges = [];
            this.charges = [];
            this.dependantResponsibles;
            this.monthlyIncome;
            this.selectedPaymentPlan;
            this.isPredefinedPlanSelected = false;
            this.defaultPlans = [];
            this.hasCalculatedPPOptions = false as boolean;
            this.enableCreditCardFormWithBillingAddress = false as boolean;
            this.byClickingText = '';
            this.personIdLog = 0;

            this.createPaymentModuleCharge = (paymentCharge) => {
                const charge = {
                    id: paymentCharge.chargeId,
                    discount: paymentCharge.discount,
                    discountCode: paymentCharge.discountCode || "",
                    isCredit: false,
                    paidInFull: paymentCharge.paidInFull,
                    paymentAmount: paymentCharge.paymentAmount,
                    originalAmount: paymentCharge.balance
                }

                return charge;
            }

            this.allowPaymentPlanUpFront = $siteSettings.allowPaymentPlanUpFront;

            this.LOADING_STATE = 0;
            this.NORMAL_STATE = 1;
            this.ERROR_STATE = 2;

            this.currentState = this.LOADING_STATE;
            this.setState = (state: number) => {
                this.currentState = state;
            }

            this.isLoading = () => {
                return this.currentState == this.LOADING_STATE;
            }

            this.isInErrorState = () => {
                return this.currentState == this.ERROR_STATE;
            }
            this.setSelectedPaymentPlan = (plan: DefaultPaymentPlan) => {
                
                this.isPredefinedPlanSelected = (plan != null && plan.paymentAmountPerMonth > 0);
                this.selectedPaymentPlan = plan;
            }

            this.MAIN_PAGE = 0;
            this.SUMMARY_PAGE = 1;
            this.page = 0;

            this.LAST_PAGE_INDEX = this.SUMMARY_PAGE;

            this.shouldShowMainPage = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.MAIN_PAGE;
            }

            this.shouldShowSummary = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.SUMMARY_PAGE;
            }

            this.paymentError = null;
            this.internalRequest = Restangular.one("internalnetwork").get().$object;

            this.email = "";
            this.minimumPaymentAmount = 10;
            this.clientMinPaymentAmount = 10;
            this.numberOfPayments = 1;
            this.paymentAmount = 10;
            this.sessionData = null;
            this.minStartDate = moment(new Date()).format("MM/DD/YYYY");
            this.maxStartDate = moment(new Date()).add(1, "months").format("MM/DD/YYYY");
            this.startDate = moment(new Date()).add(1, "months").format("MM/DD/YYYY");
            this.endDate = moment(new Date()).add(1, "months").format("MM/DD/YYYY");
            this.ppStartDate = moment(new Date()).add(1, "months").format("MM/DD/YYYY");

            this.paymentAmountChanged = () => {
                this.startDateChanged();
            }

            this.startDateChanged = () => {
                this.numberOfPayments = Math.min(this.maxNumberOfPayments, Math.ceil(this.guarantorBalance / this.paymentAmount));
                const mStartDate = moment(this.startDate, "MM/DD/YYYY");
                this.endDate = mStartDate.add(this.numberOfPayments - 1, "months").format("MM/DD/YYYY");
            }

            this.goToErrorState = (logErrorMessage: string) => {
                if (!this.isInErrorState()) {
                    this.currentState = this.ERROR_STATE;
                    $paymentFailureModal();
                }
                this.logError(logErrorMessage);
                console.log(logErrorMessage);
            }

            this.logError = (message: string) => {
                $siteSettings.billingGroupRestangularized().all("PostProcess").all("Log").post({ logMessage: message, guarantorId: $guarantor.guarantorId, transactionId: this.transactionId });
            }

            this.logPatientCensusInfo = (log: zotec.patientExperience.PaymentPlan.PatientCensusInfo) => {
                $siteSettings.billingGroupRestangularized().all("PatientCensusLog").post(log);
            }

            this.getTermsOfUseUrl = () => {
                var returnUrl = $siteSettings.getTermsOfUseUrl();
                return returnUrl;
            }

            this.getPrivacyPolicyUrl = () => {
                var returnUrl = $siteSettings.getPrivacyPolicyUrl();
                return returnUrl;
            }

            this.selectedPaymentMethod = $state.params.selectedPaymentMethod || "cc";
            this.availablePaymentMethods = [
                {
                    name: "Credit Card",
                    key: "cc"
                }
            ];

            if ($guarantor.guarantorId > 0) {
                $guarantor.restangularized()
                    .one("information").get().then((guarantor) => {
                        if (guarantor) {
                            this.email = guarantor.email;
                            this.personIdLog = guarantor.personId;
                        }
                    });
            }

            let paymentModuleLibraryPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("Library").get()
                .then((response: string) => {
                    $loadPaymentModuleLibrary(response);
                }, () => {
                    this.goToErrorState("Payment plans could not load the Payment Module.");
                });

            let sessionDataPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("SessionData").get()
                .then((data: string) => {
                    this.sessionData = data
                }, () => {
                    this.goToErrorState("Payment plans could not load the Payment Module.");
                });

            let clientInfoPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    if (info.acceptElectronicCheck) {
                        this.availablePaymentMethods.push({
                            name: "Electronic Check",
                            key: "echk"
                        });
                    }

                    this.clientMinPaymentAmount = info.minPaymentAmount;
                    this.minimumPaymentAmount = Math.max(Math.ceil(this.guarantorBalance / info.maxNumberOfPayments * 100) / 100, Math.min(info.minPaymentAmount, this.guarantorBalance));
                    this.paymentAmount = this.minimumPaymentAmount;
                    this.maxNumberOfPayments = info.maxNumberOfPayments;
                    this.startDateChanged();
                }, () => {
                    this.goToErrorState("Payment plans could not retrieve client details.");
                });

            let originatingUserInfo: any;
            const originatingUserInfoPromise = $siteSettings.billingGroupRestangularized()
                .all("PaymentModule")
                .get("OriginatingUserInfo")
                .then((oui) => originatingUserInfo = oui)
                .catch(() => {
                    $siteSettings.billingGroupRestangularized().all("PaymentModule").all("Log").post({
                        clientId: $siteSettings.clientId,
                        billingGroupId: $siteSettings.billingGroupId,
                        logMessage: "Could not load the Payment Module library and getting originating user info."
                    });
                    $paymentFailureModal();
                });

            let balancePromise = $guarantor.restangularized()
                .all("TelemedPayment").one("TelemedBalance").get()
                .then((tmBalance: any) => {
                    this.guarantorBalance = tmBalance.paymentAmount;
                    this.telemedBalance= tmBalance;
                    this.teleMedPaymentPlan = tmBalance.paymentPlan;
                    this.todaysCharge = tmBalance.todaysService;
                    this.priorCharges = tmBalance.priorServices;

                    if (this.teleMedPaymentPlan) {
                        let newTosPaymentPlan = this.todaysCharge.balance > 0;
                        if (newTosPaymentPlan) {
                            // includes today's service
                            this.charges.push({
                                id: 0,
                                discount: this.teleMedPaymentPlan.discount,
                                discountCode: this.teleMedPaymentPlan.discountCode || ``,
                                originalAmount: this.teleMedPaymentPlan.totalAmount,
                                paymentAmount: this.teleMedPaymentPlan.todayAmount,
                                paidInFull: this.teleMedPaymentPlan.paidInFull
                            });
                        }
                        else {
                            // prior balance only
                            this.charges.push({
                                id: 0,
                                discount: 0,
                                discountCode: ``,
                                isCredit: false,
                                originalAmount: this.teleMedPaymentPlan.finalAmount,
                                paymentAmount: 0,
                                paidInFull: false
                            });

                            if (this.todaysCharge.charges) {
                                _.forEach(this.todaysCharge.charges, charge => { this.charges.push(this.createPaymentModuleCharge(charge)) });
                            }
                            _.forEach(this.priorCharges, (charge: any) => {
                                _.forEach(charge.charges, (c: any) => {
                                    this.charges.push(this.createPaymentModuleCharge(c));
                                });
                            });
                        }
                    }

                    if (this.guarantorBalance === 0 || this.charges.length === 0) {
                        $state.go("entity.tele-med-account-history");
                    }
                }, () => {
                    this.goToErrorState("Bill pay was unable to retrieve client information for user.");
                });

            let enableCreditCardFormWithBillingAddressTogglePromise = Restangular.one("ApplicationFeatures").get({
                    featureName: $patientExperienceSettings.features.enableCreditCardFormWithBillingAddress,
                    featureVersion: "1.0.0"
                }).then((response: string) => {
                    this.enableCreditCardFormWithBillingAddress = response === 'True';
                }, () => {
                    this.enableCreditCardFormWithBillingAddress = false;
                });

            $q.all([enableCreditCardFormWithBillingAddressTogglePromise, paymentModuleLibraryPromise, originatingUserInfoPromise, sessionDataPromise, clientInfoPromise, balancePromise]).finally(() => {
                if (!this.isInErrorState()) {
                    this.setState(this.NORMAL_STATE);
                }
            });

            this.numberOfRemainingPayments = (numberOfPayments: number) => {
                return numberOfPayments - 1;
            }
                        
            this.onPaymentMethodChanged = () => {
                document.getElementById("payment-module-credit-card-container").style.display = 'none';
                document.getElementById("payment-module-electronic-check-container").style.display = 'none';

                var paymentModuleApi: zotec.patientExperience.payments.IPaymentModuleApiV3 = window["PaymentModule"];
                switch (this.selectedPaymentMethod) {
                    case "cc":
                        document.getElementById("payment-module-credit-card-container").style.display = '';
                        const ccForm = paymentModuleApi.InitCreditCardForm("payment-module-credit-card-container", { includeSwipe: false, includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.configurePaymentModuleForm(ccForm);
                        this.byClickingText = 'Pay with Credit Card';
                        break;
                    case "echk":
                        document.getElementById("payment-module-electronic-check-container").style.display = '';
                        const ecForm = paymentModuleApi.InitECheckForm("payment-module-electronic-check-container", { includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.configurePaymentModuleForm(ecForm);
                        this.byClickingText = 'Pay with eCheck';
                        break;
                    default:
                        break;
                }
            }

            this.configurePaymentModuleForm = (pmForm: zotec.patientExperience.payments.IPaymentModuleForm) => {
                pmForm.OnSuccess((data) => this.handlePaymentCompletion(data))
                    .WithCharges(this.charges)
                    .IncludePayment({ paymentAmount: this.teleMedPaymentPlan.paymentAmount })
                    .IncludePlan({
                        monthlyPaymentAmount: this.teleMedPaymentPlan.paymentAmount,
                        initialContractAmount: this.teleMedPaymentPlan.finalAmount,
                        firstInstallmentDate: ''
                    })
                    .WithChargeType("TOS")
                    .WithAppName("TOS")
                    .WithWebHook(`${window.location.origin}/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/PostProcess/TelemedPaymentPlan/${$guarantor.guarantorId}`, this.sessionData)
                    .WithOriginatingInformation(originatingUserInfo.originatingUserId, originatingUserInfo.originatingUserTypeId)
                    .ConfigurePatientInternal({
                        dataSourceId: $siteSettings.dataSourceId,
                        clientId: $siteSettings.clientId,
                        locationId: this.telemedBalance.locationId,
                        billingGroupId: $siteSettings.billingGroupId,
                        entityId: this.telemedBalance.entityId,
                        guarantorId: $guarantor.guarantorId,
                        patientId: 0,
                    })
                    .OnVerificationRequest(() => {
                        let response = $.ajax({
                            url: $siteSettings.billingGroupRestangularized().all("PaymentModule").one("AccessToken").getRestangularUrl(),
                            async: false,
                            cache: false,
                        }).responseText;
                        return JSON.parse(response);
                    });
            }

            this.planSummary = null;

            this.getPaymentId = (responses: zotec.patientExperience.payments.TOSPaymentResponse[]) => {
                if (responses && responses.length === 0) {
                    return 0;
                }

                var response = responses[0];
                if (response) {
                    return response.success ? response.paymentId : 0;
                } else {
                    return 0;
                }
            }

            this.handlePaymentCompletion = (response: zotec.patientExperience.payments.TOSPaymentPlanResponse) => {
                let parsedWebhookResponse = response.webhookResponse ? JSON.parse(response.webhookResponse) : undefined;
                if (response.isSuccess) {
                    const method = this.availablePaymentMethods.find(m => m.key == this.selectedPaymentMethod);
                    const paymentId = parsedWebhookResponse.paymentId;
                    this.planSummary = new PaymentPlanSummary(parsedWebhookResponse.approvalCode, this.teleMedPaymentPlan.paymentAmount, this.startDate
                        , this.endDate, method.name + ' ' + response.maskedAccountNumber.substring(8));
                    this.planSummary.paymentId = paymentId;
                    this.setState(this.NORMAL_STATE);
                    this.page = this.SUMMARY_PAGE;
                    $mdsService.initiateReceiptAfterSuccessfulPayment(paymentId).then(
                        (receiptResult: zotec.patientExperience.MDS.InitiateReceiptResponse) => {
                            console.log(receiptResult.success);
                        },
                        (error) => {
                            this.logError(error);
                        });
                } else {
                    
                    const errorMessage = response ? response.errorMessage : "Something went wrong trying to make the payment.";
                    this.goToErrorState(errorMessage);
                }
                $scope.$digest();
            }

            this.printReceipt = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/download", { paymentId: parseInt(this.planSummary.paymentId), isPrePay: false, isTOSReceipt: true }, "Generating receipt...", null);
            }

            this.showEmailReceiptModal = () => {
                if (this.planSummary.paymentId > 0) {
                    $emailReceiptModal(this.planSummary.paymentId, true);
                }
            }            

            this.logOut = () => {
                $guarantor.logOut();
            }
        }]);