/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../js/models.d.ts" />
/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module('Zotec.PatientExperience.ApplePay').component('zpApplePay', {
    templateUrl: 'Templates/PatientExperience/ApplePay/zpApplePay',
    controller: "zpApplePayController",
    controllerAs: "zpApplePay",
    bindings: {
        paymentAmount: '<',
        onClick: '&',
        onInitError: '&',
        paymentModuleApi: '<',
        paymentModuleFormOptions: '<',
        transactionResultCallbacks: '<',
        isActive: '<',
    }
});
