/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Support")
    .directive("zpSupport", () => {
        return {
            restrict: "AE",
            controller: "zpSupportController",
            controllerAs: "zpSupport",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Support/zpSupport"
        }
    });