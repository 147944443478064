/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Insurance")
    .directive("zpInsurance", () => {
        return {
            restrict: "AE",
            controller: "zpInsuranceController",
            controllerAs: "zpInsurance",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Insurance/zpInsurance"
        }
    });