/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceEncounters", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.insurance-encounters", {
            url: "/insurance-encounters",
            template: "<zp-insurance-encounters></zp-insurance-encounters>",
            params: {
                encounterDetails: (null as zotec.patientExperience.insurance.EncounterDetails),
                insurancePolicy: (null as zotec.patientExperience.insurance.InsurancePolicy),
                relatedEncounters: null as zotec.patientExperience.insurance.RelatedEncounter[]
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Insurance Encounters", entity);
                }]
            }
        });
    }]);