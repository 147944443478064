/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Contact", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.contact", {
            url: "/contact",
            template: "<zp-contact></zp-contact>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("Contact", entity);
                }]
            }
        });
    }]);