/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Thanks")
    .controller("zpThanksController",
        ["$siteSettings", "$sessionStorage", "Restangular",
            function ($siteSettings: zotec.patientExperience.Services.SiteSettingsService,
                $sessionStorage,
                Restangular: restangular.IService
            ) {

                this.clientId = $siteSettings.clientId || $sessionStorage.clientId || null;
                this.clientPhone = "";

                const rest = $siteSettings.billingGroupRestangularized();
                if (rest != null) {
                    rest.one("clientinfo").get().then((clientInfo: zotec.patientExperience.client.ClientInfo) => {
                        console.log("client info", clientInfo);
                        if (this.clientId === null) {
                            this.clientId = clientInfo.clientId;
                        }
                        this.clientPhone = clientInfo.phone || "";
                    }, () => {
                        console.log("error getting client info");
                    });
                }
            }   
        ]);