/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.GuarantorPatients")
    .controller("zpGuarantorPatientsController",
        ["Restangular", "$zpModal", "$staticModal", "$window", "$state", "$siteSettings", "$guarantor", "$promptModal", "$q", "$showAddressValidation",
            function (Restangular: restangular.IService, $zpModal, $staticModal, $window, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $promptModal, $q: angular.IQService, $showAddressValidation) {

            this.mode = "guarantor";
            this.guarantor = {};
            this.originalGuarantor = {};
            this.patients = ([]);
            this.originalPatients = ([]);
            this.clientName = "";
            this.chosenPatientId = 0;
            this.verifiedInformation = false;
            this.patient = ({} as zotec.patientExperience.guarantorPatient.Patient);

            this.genders = [
                {
                    key: "M",
                    display: "Male"
                },
                {
                    key: "F",
                    display: "Female"
                },
                {
                    key: "U",
                    display: "Other"
                }
            ];

            if ($siteSettings.companyNameDisplay && $siteSettings.companyNameDisplay.length > 0) {
                this.clientName = $siteSettings.companyNameDisplay;
            }
            else {
                $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                    .then((info: zotec.patientExperience.client.ClientInfo) => {

                            this.clientName = info.name;
                        },
                        () => {
                            console.log("error getting settings");
                        });
            }

            $guarantor.restangularized()
                .all("patients").getList().then((relatedPeople: [zotec.patientExperience.insurance.RelatedPerson]) => {
                    for (let r of relatedPeople) {
                        this.patients.push(r);
                    }
                    this.originalPatients = angular.copy(relatedPeople);
                }, (response) => {
                    console.log("error getting related people: ", response);
                });

            $guarantor.restangularized()
                .one("information").get().then((guarantor) => {
                    this.guarantor = guarantor;
                    this.originalGuarantor = angular.copy(guarantor);
                }, (response) => {
                    console.log("error getting related people: ", response);
                });

            this.switchMode = (mode: string) => {
                this.mode = mode;
            }

            this.getTermsOfUseUrl = () => {
                var returnUrl = "";
                if ($siteSettings.termsOfUseUrl === null || $siteSettings.termsOfUseUrl.length === 0) {
                    returnUrl = $state.href("entity.terms") || "#/global/terms";
                } else {
                    const regex = RegExp('[https?:\/\/|#].*');
                    if (regex.test($siteSettings.termsOfUseUrl)) {
                        returnUrl = $siteSettings.termsOfUseUrl;
                    } else {
                        returnUrl = "https://" + $siteSettings.termsOfUseUrl;
                    }
                }
                return returnUrl;
            }

            this.redirectTerms = () => {
                $window.open(this.getTermsOfUseUrl());
            }

            this.patientChanged = () => {
                this.patient = _.find(this.patients, (p: any) => {
                    return p.personId === this.chosenPatientId;
                });
                console.log("the patient is ", this.patient);
            }

            this.setaddress = (address: zotec.patientExperience.AddressValidation.Address) => {
                this.guarantor.address1 = address.address1;
                this.guarantor.address2 = address.address2;
                this.guarantor.city = address.city;
                this.guarantor.state = address.state;
                this.guarantor.zip = address.zip5;
            }

            this.setverified = (hasverified: boolean) => {
                this.verifiedInformation = hasverified;
                if (hasverified) {
                    this.guarantor.addressIndicator = "G";
                } else {
                    this.guarantor.addressIndicator = "U";
                }
            }

            this.openValidationModal = (setaddress: Function, setverified: Function) => {
                $showAddressValidation(
                    {
                        address1: this.guarantor.address1,
                        address2: "",
                        city: this.guarantor.city,
                        state: this.guarantor.state,
                        zip5: this.guarantor.zip
                    },
                    {
                        address1: this.suggestedaddress.address1,
                        address2: this.suggestedaddress.address2,
                        city: this.suggestedaddress.city,
                        state: this.suggestedaddress.state,
                        zip5: this.suggestedaddress.zip5
                    },
                    this.setaddress,
                    this.setverified);
                }

            this.showAddressValidation = (setaddress: Function, setverified: Function) => {
                if (this.originalGuarantor.addressIndicator !== "G"
                    || this.originalGuarantor.address1 !== this.guarantor.address1
                    || this.originalGuarantor.zip !== this.guarantor.zip) {

                    if (this.guarantor.address2 === null) {
                        this.guarantor.address2 = "";
                    }

                    var deferred = $q.defer();
                    let queryParams = {
                        address1: this.guarantor.address1,
                        address2: this.guarantor.address2,
                        city: this.guarantor.city,
                        state: this.guarantor.state,
                        zip5: this.guarantor.zip,
                        zip4: ""
                    };

                    Restangular
                        .one("VerifyAddress").one("usps").get(queryParams)
                        .then((response: zotec.patientExperience.AddressValidation.Address) => {
                            if (response.address1 !== null) {
                                this.suggestedaddress = response;
                                deferred.resolve();

                                this.openValidationModal(setaddress, setverified);
                            }
                            else {
                                this.verifiedInformation = true;
                            }
                        }, () => {
                            deferred.resolve();
                        });
                }
            };

            this.cancel = () => {
                $state.go("entity.account-history");
            }

            this.save = () => {
                if (this.mode === "guarantor") {
                    let modal = $staticModal("Updating...", () => {
                        this.guarantor.put().then((response) => {
                            modal.hide();
                            if (response) {
                                $zpModal("Success", "Your information was updated successfully.", () => { });
                            } else {
                                $zpModal("Error", "There was an issue updating your information. Please try again.", () => { });
                            }
                        }, () => {
                            modal.hide();
                            console.log("error updating guarantor information ");
                            $zpModal("Error", "There was an issue updating your information. Please try again.", () => { });
                        });
                    });
                } else {
                    const originalPatient = _.find(this.originalPatients, (patient: zotec.patientExperience.guarantorPatient.Patient) => { return patient.personId === this.patient.personId });
                    if (originalPatient != null) {
                        if (this.patient.personId === this.patient.guarantorId) {
                            if (originalPatient.firstName !== this.patient.firstName ||
                                originalPatient.middleName !== this.patient.middleName ||
                                originalPatient.lastName !== this.patient.lastName ||
                                originalPatient.dateOfBirth !== this.patient.dateOfBirth) {
                                this.patient.needsWorkfileUpdate = true;
                            } else {
                                this.patient.needsWorkfileUpdate = false;
                            }
                        }
                        if ((originalPatient.firstName !== this.patient.firstName ||
                            originalPatient.middleName !== this.patient.middleName ||
                            originalPatient.lastName !== this.patient.lastName ||
                            originalPatient.dateOfBirth !== this.patient.dateOfBirth) &&
                            originalPatient.insuranceProfileCount > 0) {
                            this.patient.needsRefile = true;
                        } else {
                            this.patient.needsRefile = false;
                        }
                    }
                    let modal = $staticModal("Updating...", () => {
                        this.patient.put().then((response) => {
                            modal.hide();
                            if (response) {
                                $zpModal("Success", "Patient information was submitted for an update. It may take some time for us to verify the information.", () => { });
                            } else {
                                $zpModal("Error", "There was an issue updating patient information. Please try again.", () => { });
                            }
                        }, () => {
                            modal.hide();
                            console.log("error updating patient information ");
                            $zpModal("Error", "There was an issue updating patient information. Please try again.", () => { });
                        });
                    });
                }
            }

            this.addressChanged = () => {
                if (this.guarantor.address1 !== this.originalGuarantor.address1) {
                    this.verifiedInformation = false;
                    this.guarantor.addressIndicator = "U";
                }
            }

            this.zipChanged = () => {
                if (this.guarantor.zip !== this.originalGuarantor.zip) {
                    this.verifiedInformation = false;
                    this.guarantor.addressIndicator = "U";
                }
                if (this.guarantor.zip.length < 5) {
                    this.guarantor.state = "";
                    this.guarantor.city = "";
                    return;
                } else if (this.guarantor.zip.length > 5) {
                    return;
                }
                const firstFive = this.guarantor.zip.substring(0, 5);
                Restangular.one("datasources", $siteSettings.dataSourceId)
                    .one("zipcodes", firstFive)
                    .get().then((zipCode: zotec.patientExperience.guarantorPatient.ZipCode) => {
                        if (zipCode == null) {
                            return;
                        }
                        if (zipCode.zip !== firstFive) {
                            return;
                        }
                        this.guarantor.state = zipCode.state;
                        this.guarantor.city = zipCode.city;
                    },
                    (response) => {
                        console.log("error getting related people: ", response);
                    });
            }

            this.optOutGuarantor = () => {
                this.optOutPerson($guarantor.guarantorId, "This will prevent you from logging in to this site in the future.");
            }

            this.optOutPatient = () => {
                this.optOutPerson(this.patient.personId, "This will prevent you from viewing information related to this patient's charges in the future.");
            }

            this.optOutPerson = (personId: number, text: string) => {
                $promptModal("Are you sure?", text, "Disable Online Account", "Go back", () => {
                    const modal = $staticModal("Removing account...", () => {
                        $guarantor.restangularized().one("patients", personId).one("optout").put().then((response: zotec.patientExperience.guarantorPatient.OptOutResult) => {
                            modal.hide();
                            if (response.success) {
                                if (response.guarantorOptedOut) {
                                    $guarantor.logOut();
                                } else {
                                    $state.go("entity.account-history");
                                }
                            } else {
                                console.log("an error occurred opting you out");
                            }
                        }, () => {
                            modal.hide();
                            console.log("an error occurred opting you out");
                        });
                    });
                }, true);
            }
        }
    ]);