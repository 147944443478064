/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$loadPaymentModuleLibrary", [
    () => {
        function $loadPMLibrary(scriptContent: string) {
            var id = "payment-module-library-string";
            if (document.getElementById(id) == null) {
                var scriptElement = document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.id = id;
                scriptElement.text = scriptContent;
                document.head.appendChild(scriptElement);
            }
        }

        return $loadPMLibrary;
    }
]);
