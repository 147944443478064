/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.GuarantorPatients", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.guar-pats", {
            url: "/guar-pats",
            template: "<zp-guarantor-patients></zp-guarantor-patients>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Guarantor And Patients", entity);
                }]
            }
        });
    }]);