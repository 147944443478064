/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$siteSettings", ["Restangular", "$q", "$state",
    function (Restangular: restangular.IService, $q: angular.IQService, $state) {
        this.isLoaded = false;
        this.dataSourceId = null as string;
        this.clientId = null as string;
        this.billingGroupId = 0;
        this.billingGroupLocalId = "";

        this.title = null as string;
        this.companyNameDisplay = null as string;
        this.companyWebsiteUrl = null as string;
        this.primaryColor = null as string;
        this.allowGoodFaithEstimate = false;
        this.allowPrePay = false;
        this.showIncomeQuestions = false;
        this.allowPaymentPlanUpFront = false;
        this.disablePaperlessCharity= false;
        this.requestPaperlessConsent = false;
        this.addPhone1Prefix = false;
        this.feedbackUrl = null as string;
        this.feedbackPromptAfterPayment = false;
        this.feedbackPromptText = null as string;
        this.feedbackPromptButtonText = null as string;
        this.faqUrl = undefined as string;
        this.hiddenFaq = null as string;
        this.privacyPolicyUrl = null as string;
        this.termsOfUseUrl = null as string;

        this.billingGroupRestangularized = () => {
            if (this.dataSourceId === null || this.clientId === null || this.billingGroupId === null) {
                return null;
            }
            return Restangular.one("datasources", this.dataSourceId).one("clients", this.clientId).one("billinggroups", this.billingGroupId);
        }

        this.clientRestangularized = () => {
            if (this.dataSourceId === null || this.clientId === null) {
                return null;
            }
            return Restangular.one("datasources", this.dataSourceId).one("clients", this.clientId);
        }

        this.primaryColorTrimmed = () => {
            if (this.primaryColor != null && _.startsWith(this.primaryColor, "#")) {
                return this.primaryColor.substring(1);
            }
            return null;
        }

        this.clearValues = () => {
            this.isLoaded = false;
            this.dataSourceId = (null as string);
            this.clientId = (null as string);
            this.billingGroupId = 0;
        }

        this.recaptchaToggle = (queryParams: any) => {
            return Restangular.one("applicationfeatures").get<string>(queryParams)
                .then((enabled) => {
                    if (enabled === "True") {
                        return true;
                    }
                    return false;
                }, () => {
                    console.log("error in get appsettings");
                    return false;
                });
        }

        this.getTermsOfUseUrl = () => {
            var returnUrl = "";
            if (this.termsOfUseUrl === null || this.termsOfUseUrl.length === 0) {
                returnUrl = $state.href("entity.terms") || "#/global/terms";
            }
            else {
                const regex = RegExp('[https?:\/\/|#].*');
                if (regex.test(this.termsOfUseUrl)) {
                    returnUrl = this.termsOfUseUrl;
                } else {
                    returnUrl = "https://" + this.termsOfUseUrl;
                }
            }
            return returnUrl;
        }

        this.getPrivacyPolicyUrl = () => {
            var returnUrl = "";
            if (this.privacyPolicyUrl === null || this.privacyPolicyUrl.length === 0) {
                returnUrl = $state.href("entity.privacy") || "#/global/privacy";
            } else {
                const regex = RegExp('https?:\/\/.*');
                if (regex.test(this.privacyPolicyUrl)) {
                    returnUrl = this.privacyPolicyUrl;
                } else {
                    returnUrl = "https://" + this.privacyPolicyUrl;
                }
            }
            return returnUrl;
        }

        return this;
    }]
);
