/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$zpModal", ["$modal", "$rootScope",
    ($modal, $rootScope) => {

        return (title: string, text: string, onHide: Function) => {
            var scope = $rootScope.$new();
            scope.title = title;
            scope.text = text;

            var modal = $modal({
                scope: scope,
                templateUrl: "/Templates/Controls/Services/Modal",
                backdrop: "static"
            });

            scope.close = () => {
                onHide();
                modal.hide();
            }

            return modal;

        }
    }
]);
