/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$loadCheckoutJSLibrary",
    ["Restangular",
    (
        Restangular: restangular.IService,
    ) => {
        function $loadCheckoutJSLibrary(src: string) {
            let id = "checkout-js-library";
            let libraryElement = document.getElementById(id);
            if (libraryElement) {
                libraryElement.remove();
                window["ConvergeEmbeddedPayment"] = null;
            }

            if (!src) {
                console.error('Invalid CheckoutJS settings');
                return;
            }

            var scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";
            scriptElement.id = id;
            scriptElement.src = src;
            document.body.appendChild(scriptElement);
        }
        return $loadCheckoutJSLibrary;
    }
]);
