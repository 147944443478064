/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Default")
    .directive("zpDefault", () => {
        return {
            restrict: "E",
            controller: "zpDefaultController",
            controllerAs: "zpDefault",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Default/zpDefault"
        }
    });