angular.module("Zotec.PatientExperience.Video")
    .directive("zpVideo", () => {
        return {
            restrict: "E",
            controller: "zpVideoController",
            controllerAs: "zpVideo",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Video/zpVideo"
        }
    });