/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Entity")
    .controller("zpEntityController", ["Restangular", "$state", "$siteSettings", "$guarantor", "$timeout", "$location",
        (Restangular: restangular.IService, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $timeout, $location) => {
            
            if ($state.current.name === "entity") {
                $timeout(() => {
                    $state.go("entity.login");
                }, 2000);
            }

        }
    ]);
