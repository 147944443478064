/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Document")
    .directive("zpDocument", () => {
        return {
            restrict: "AE",
            controller: "zpDocumentController",
            controllerAs: "zpDocument",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Document/zpDocument"
        }
    });