/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedAccountHistory")
    .controller("zpTeleMedAccountHistoryController", ["$guarantor",
        "$anchorScroll",
        function (
            $guarantor: zotec.patientExperience.Services.GuarantorService,
            $anchorScroll
        ) {
            var self = this;
            self.balance = {};
            self.openBalance = 0;
            self.todaysCharge = {};
            self.priorCharges = [];
            self.teleMedPaymentPlan = {};
            self.isPaymentPlan;

            self.loadingCharges = true;

            $guarantor.restangularized().all("TelemedPayment").one("TelemedBalance").get().then((telemedBalance: any) => {
                self.balance = telemedBalance;
                self.openBalance = telemedBalance.paymentAmount;
                self.todaysCharge = telemedBalance.todaysService;
                self.priorCharges = telemedBalance.priorServices;
                self.teleMedPaymentPlan = telemedBalance.paymentPlan;
                self.isPaymentPlan = telemedBalance.isPaymentPlan;
                self.loadingCharges = false;
            }, (response) => {
                self.loadingCharges = false;
                console.log("there was an error in the response", response);
            });

            self.shouldShowPaymentPlanButton = () => {
                if (self.loadingCharges) return false;
                return self.isPaymentPlan;
            }

            self.scrollToTop = () => {
                $anchorScroll();
            };
        }]);
