/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Feedback", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.feedback", {
            url: "/feedback",
            template: "<zp-feedback></zp-feedback>",
            params: {
                source: null
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("Feedback", entity);
                }]
            }
        });
    }]);