/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceAdd", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.insurance-add", {
            url: "/insurance-add",
            template: "<zp-insurance-add></zp-insurance-add>",
            params: {
                patientId: null,
                encounterDetails: (null as zotec.patientExperience.insurance.EncounterDetails)
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Insurance Add", entity);
                }]
            }
        });
    }]);