/// <reference path="../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience")
    .filter("zip", () => {
        return (zip: string) => {
            switch (true) {
                case zip.length <= 5:
                    return zip;
                case zip.length <= 9:
                    return zip.slice(0, 5) + "-" + zip.slice(5, zip.length);
                default:
                    return zip;
            }
        };
    });