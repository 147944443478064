/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module('Zotec.PatientExperience.ApplePay')
    .controller("zpApplePayController",
        ["$scope", "Restangular", "$siteSettings", "$guarantor", "$loadCheckoutJSLibrary", "PaymentModuleFormService", "$window", "$q",
            function($scope,
                Restangular: restangular.IService,
                $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
                $guarantor: zotec.patientExperience.Services.GuarantorService,
                $loadCheckoutJSLibrary,
                PaymentModuleFormService: zotec.patientExperience.Services.IPaymentModuleFormService,
                $window: angular.IWindowService,
                $q: angular.IQService,
            ) {

                enum ComponentStates {
                    HiddenState,
                    LoadingState,
                    NormalState,
                    ErrorState
                }

                interface ApplePayError {
                    error: any;
                    message: string;
                }                

                var ctrl = this;

                ctrl.currentState = ComponentStates.HiddenState;
                ctrl.ComponentStates = ComponentStates;
                ctrl.isActiveWatcher = null;

                ctrl.$onInit = () => {
                    if (!ctrl.paymentModuleApi) {
                        console.log("Payment Module API was not provided. Apple Pay disabled.");
                        return;
                    }

                    if (ctrl.isApplePaySupportedByDevice()) {
                        ctrl.currentState = ComponentStates.LoadingState;
                        ctrl.acceptedCardsPromise()
                            .then((cards: zotec.patientExperience.payments.AcceptedCards) => {
                                if (cards.applePay) {
                                    let requestDwSessionTokenPromise = ctrl.requestDwSessionToken();
                                    let loadCheckoutJsSettingsAndLibraryPromise = ctrl.loadCheckoutJsSettingsAndLibrary();                                    

                                    $q.all([
                                        requestDwSessionTokenPromise,
                                        loadCheckoutJsSettingsAndLibraryPromise
                                    ]).then(() => {
                                        ctrl.currentState = ComponentStates.NormalState;
                                    }).catch(err => {
                                        if (err && err.message && err.error) {
                                            this.handleError(err.message, err.error);
                                        } else {
                                            this.handleError("Unknown error with Apple Pay", err);
                                        }
                                    });
                                }
                            })
                            .catch((err) => {
                                this.handleError("Apple Pay disabled. Failed loading accepted cards from payment module.", err);
                            });
                    }
                };

                ctrl.$onChanges = (changes) => {
                    if (changes.isActive != undefined) {
                        ctrl.handleIsActiveChanged(changes.isActive);
                    }
                }

                ctrl.$onDestroy = () => {
                    this.cleanupDwForm();
                }

                ctrl.isApplePaySupportedByDevice = (): boolean => {
                    return "ApplePaySession" in window && ApplePaySession !== undefined;
                }

                this.dwPaymentData = null;
                this.requestDwSessionToken = (): angular.IPromise<void> => {
                    return $siteSettings.billingGroupRestangularized().all("PaymentModule/WalletSessionToken").post({
                        amount: this.paymentAmount,
                        digitalWalletType: "apple-pay",
                        guarantorId: $guarantor.guarantorId > 0 ? $guarantor.guarantorId : 0,

                    }).then((token: string) => {
                        ctrl.dwPaymentData = {
                            ssl_txn_auth_token: token,
                        };

                        let convergeEmbeddedPayment: zotec.patientExperience.payments.IConvergeEmbeddedPayment =
                            $window["ConvergeEmbeddedPayment"];
                        convergeEmbeddedPayment.initApplePay('additional-option-applepay',
                            ctrl.dwPaymentData,
                            ctrl.dwCallback);
                    }).catch(err => {
                        throw {
                            error: err,
                            message: "Apple Pay disabled. Digital wallet session token request failed."
                        } as ApplePayError;
                    });
                }

                let transactionResultCallbacks: zotec.patientExperience.payments.ApplePayTransactionCallbacks = ctrl.transactionResultCallbacks;
                this.dwForm = null;
                this.dwCallback = {
                    onError: (error) => {
                        if (transactionResultCallbacks && transactionResultCallbacks.onError) {
                            transactionResultCallbacks.onError(error);
                        }
                        logDigitalWalletResult("error", error);
                        ctrl.cleanupDwForm();
                    },
                    onDeclined: (response) => {
                        if (transactionResultCallbacks && transactionResultCallbacks.onDeclined) {
                            transactionResultCallbacks.onDeclined(response);
                        }
                        handleDigitalWalletResponse(response, "declined");
                        // don't cleanup the form here to allow for async processing
                    },
                    onApproval: (response) => {
                        if (transactionResultCallbacks && transactionResultCallbacks.onApproval) {
                            transactionResultCallbacks.onApproval(response);
                        }
                        handleDigitalWalletResponse(response, "approved");
                        // don't cleanup the form here to allow for async processing
                    },
                    onCancelled: () => {
                        if (transactionResultCallbacks && transactionResultCallbacks.onCancelled) {
                            transactionResultCallbacks.onCancelled();
                        }
                        logDigitalWalletResult("cancelled", null);
                        ctrl.cleanupDwForm();
                    },
                };

                this.handleError = (message: string, err) => {
                    ctrl.currentState = ComponentStates.ErrorState;
                    console.error(message, err);

                    if (ctrl.onInitError != null && typeof ctrl.onInitError === "function") {
                        ctrl.onInitError({ message: message });
                    }
                }

                this.handleClick = () => {
                    if (ctrl.onClick != null && typeof ctrl.onClick === "function") {
                        ctrl.onClick();
                    }

                    let paymentModuleApi: zotec.patientExperience.payments.IPaymentModuleApiV3 =
                        ctrl.paymentModuleApi;
                    let form = paymentModuleApi.InitDWForm("payment-module-digital-wallet-container");
                    ctrl.dwForm = PaymentModuleFormService.configureForm(form, ctrl.paymentModuleFormOptions);
                }

                this.handleIsActiveChanged = (isActive) => {
                    if (isActive === false) {
                        ctrl.cleanupDwForm();
                    }
                }

                function handleDigitalWalletResponse(response, status: string) {
                    let digitalWalletTransactionResponse = JSON.stringify(response);
                    logDigitalWalletResult(status, digitalWalletTransactionResponse);
                    ctrl.dwForm.SubmitCheckoutResponse(
                        { digitalWalletTransactionResponse, digitalWalletType: 'apple-pay' } as zotec.patientExperience.
                        payments.ICheckoutResponse);
                }

                this.cleanupDwForm = () => {
                    if (ctrl.dwForm) {
                        ctrl.dwForm.Dispose();
                        ctrl.dwForm = null;
                    }
                }

                function logDigitalWalletResult(status: string, result) {
                    console.log('Transaction status: ' + status);
                    console.log(result);
                }

                this.loadCheckoutJsSettingsAndLibrary = (): angular.IPromise<void> => {
                    const promise = Restangular.one("AppSettings").one("checkoutjs").get({ appName: "PXP" })
                        .then((settings: zotec.patientExperience.AppSettings.CheckoutJsSettings) => {
                            if (settings) {
                                let checkoutJsSource = settings.librarySource;
                                $loadCheckoutJSLibrary(checkoutJsSource);
                            }
                        }).catch(err => {
                            throw {
                                error: err,
                                message: "Apple Pay disabled. Settings failed to load."
                            } as ApplePayError;
                        });

                    return promise;
                }

                this.acceptedCardsPromise = (): angular.IPromise<zotec.patientExperience.payments.AcceptedCards> => {
                    const promise = $siteSettings.billingGroupRestangularized().one("PaymentModule").one("AcceptedCards").get<zotec.patientExperience.payments.AcceptedCards>();

                    return promise;
                }
            }]);

namespace zotec.patientExperience.payments {
    export interface ApplePayTransactionCallbacks {
        onError: (error: any) => void;
        onDeclined: (response: any) => void;
        onApproval: (response: any) => void;
        onCancelled: () => void;
    }    
}