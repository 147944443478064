/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.EbcPassThrough", [])
    .config(["$stateProvider", "$urlMatcherFactoryProvider", ($stateProvider, $urlMatcherFactoryProvider) => {
        $urlMatcherFactoryProvider.caseInsensitive(true);
        $urlMatcherFactoryProvider.strictMode(false);
        $stateProvider.state("ebcpassthrough", {
            url: "/ebcPassThrough?t",
            template: "<zp-ebcpassthrough></zp-ebcpassthrough>"
        });
    }]);