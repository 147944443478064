/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpCharge", ["$serviceInfoService", ($serviceInfoService) => {
        return {
            templateUrl: "Templates/Controls/Directives/Charge",
            scope: {
                charge: "=",
                index: "=",
                expanded: "=",
                hasInsuranceOnFile: "=",
                isOpenCharge: "=",
                showName: "=",
                showEncounterHeader: "="
            },
            link: function(scope) {
                scope.clickServiceInfo = function () {
                    if (this.charge.chargeId === 0) {
                        return;
                    }
                    $serviceInfoService(this.charge.chargeId);
                }
                scope.showInsuranceBasedInfo = function () {
                    return this.expanded && (!this.isOpenCharge || this.hasInsuranceOnFile);
                }
            }
        };
    }]);