/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.AccountHistory")
    .controller("zpAccountHistoryController", ["$guarantor", "$state", "$paperlessBillingSelector", "$pdf",
        "$anchorScroll", "$window", "$sessionStorage", "$scope", "$paperlessBillingState", "$siteSettings",
        "$patientExperienceSettings", "$loggingService", "$q", "$PaymentPendingModalService", "Restangular",
        function (
            $guarantor: zotec.patientExperience.Services.GuarantorService,
            $state,
            $paperlessBillingSelector,
            $pdf: zotec.patientExperience.Services.PdfService,
            $anchorScroll,
            $window,
            $sessionStorage,
            $scope: angular.IScope,
            $paperlessBillingState: zotec.patientExperience.Services.PaperlessBillingStateService,
            $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings,
            $loggingService: zotec.patientExperience.Services.LoggingService,
            $q: angular.IQService,
            $PaymentPendingModalService,
            Restangular: restangular.IService
        ) {
            const self = this;
            this.dataSourceId = $siteSettings.dataSourceId || null;
            this.clientId = $siteSettings.clientId || null;
            this.billingGroupId = $siteSettings.billingGroupId || null;
            this.patientsOpenCharges = [];
            this.patientsPendingCharges = [];
            this.isGuarantorBillingClient = $siteSettings.isGuarantorBillingClient;
            this.allClosedCharges = [];
            this.filteredClosedCharges = [];
            this.pmCharges = [];
            this.guarantorBalance = 0;
            this.loadingCharges = true;
            this.destination = $guarantor.destination || $sessionStorage.destination || "";
            this.$showPaymentPlanButton = false;
            this.hasPaymentPlan = false as boolean;
            this.acceptPaymentPlan = false;
            this.minPaymentAmountActual = 0;
            this.isMinimumPaymentBalance = false;
            this.allowPaymentPlanUpFront = $siteSettings.allowPaymentPlanUpFront;
            this.hasCalculatedPPOptions = false as boolean;
            this.clientMinPaymentAmount = 10;
            this.maxNumberOfPayments = 0;
            this.accountNumber = 0;
            this.isGuarantorBillingClient = $siteSettings.isGuarantorBillingClient;

            this.showEncounterHeader = false;
            this.showEncounterLevelInsuranceButtons = $siteSettings.isGuarantorBillingClient;
            this.hideAccountLevelInsuranceButton = $siteSettings.isGuarantorBillingClient;
            this.rollUpEncounterInsurancePaymentsOnStatement = false;
            this.daysToDisplayAtAgencyBalanceWarning = 0;
            this.daysPastForIVRAgencyCharges = 0;

            this.pendingSectionExpanded = false;
            this.closedExpanded = false;
            this.historySectionExpanded = false;
            this.hasInsuranceOnFile = false;

            this.pendingTransaction = false;
            this.pendingTransactionAmount = 0;
            this.enablePendingPaymentWarnings = false;

            this.itemizedEndDate = moment();
            this.itemizedStartDate = moment().subtract(1, "years");

            this.itemizedStartMonth = this.itemizedStartDate.format("MM");
            this.itemizedStartDay = this.itemizedStartDate.format("DD");
            this.itemizedStartYear = this.itemizedStartDate.format("YYYY");

            this.itemizedEndMonth = this.itemizedEndDate.format("MM");
            this.itemizedEndDay = this.itemizedEndDate.format("DD");
            this.itemizedEndYear = this.itemizedEndDate.format("YYYY");
            
            this.EnrollmentStatesArray = $paperlessBillingState.EnrollmentStatesArray;

            this.includesAtAgencyStatement = false;
            this.showErrorGettingBalance = false;

            const today = new Date();

            const evaluatePaperlessBillingBanner = () => {
                if ($sessionStorage.hidePaperlessSettingsPrompt == true) return;
                $scope.$evalAsync((scope) => {
                    scope.paperlessBillingState = $paperlessBillingState.enrollmentState;
                    scope.fromDirectLink = $sessionStorage.fromDirectLink;
                });
            };


            this.getAccountInfo = $guarantor.restangularized().one("information").get();
            this.getAccountInfo.then((guarantor) => {
                if (guarantor) {
                    this.accountNumber = guarantor.personId > 0 ? guarantor.personId.toString() : "";
                }
            });
                
            let enablePendingPaymentWarningsQueryParams = {
                featureName: $patientExperienceSettings.features.enablePendingPaymentWarnings,
                featureVersion: "1.0.0",
                dataSourceId: $siteSettings.dataSourceId,
                clientId: $siteSettings.clientId
            };

            Restangular.one("applicationfeatures")
                .get(enablePendingPaymentWarningsQueryParams)
                .then((featureStatus: string) => {
                    if (featureStatus === "False") {
                        self.enablePendingPaymentWarnings = false;
                    } else {
                        self.enablePendingPaymentWarnings = true;
                        self.getPendingTransaction();
                    }

                });

            this.getPendingTransaction = () => {
                if (this.enablePendingPaymentWarnings) {
                    let pendingTransactionPromise = $siteSettings.billingGroupRestangularized()
                        .all("PaymentModule")
                        .one("PendingTransaction")
                        .get({ dataSourceId: $siteSettings.dataSourceId, clientId: $siteSettings.clientId, guarantorId: $guarantor.guarantorId });

                    pendingTransactionPromise.then(
                        (response: zotec.patientExperience.PendingPayment.PendingPaymentResponse) => {
                            if (response !== undefined && response !== null) {
                                self.pendingTransaction = response.pendingTransactions;
                                self.pendingTransactionAmount = response.amount;                            
                            }
                        },
                        (response) => {
                        console.log("Bill pay was unable to retrieve pending transaction information for the user.", response);
                        }
                    );
                }
            };
            

            this.emailSettings = {
                emailPriority: false,
                offerEmail: false
            };

            this.paperlessOptedOutText = () => {
                if (this.emailSettings.offerEmail === true)
                    return "You opted out of paperless billing. You will no longer receive our email or text message reminders.";
                else
                    return "You opted out of paperless billing. You will no longer receive our text message reminders.";
            };

            const evaluatePaperlessBillingModal = () => {
                if ($sessionStorage.hidePaperlessSettingsPrompt == true) return;

                const forceForDiscoveredEmail = $siteSettings.usePxPDiscoveredEmailFlow && $paperlessBillingState.enrollmentState === $paperlessBillingState.EnrollmentStates.DISCOVERED;

                if ($paperlessBillingState.enrollmentState === $paperlessBillingState.EnrollmentStates.NOT_ENROLLED || forceForDiscoveredEmail) {
                    if ($sessionStorage.fromDirectLink && !$siteSettings.requestPaperlessConsent && !forceForDiscoveredEmail) {
                        if (this.EnrollmentStatesArray.includes($paperlessBillingState.enrollmentState)) {
                            $paperlessBillingState.enroll().then(enrollment => {

                                var enrollState = $paperlessBillingState.EnrollmentStates.ENROLLED;


                                $paperlessBillingState.enrollmentState =
                                    enrollment ? enrollState : $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;

                                $guarantor.isEnrolled = enrollment;
                            }).catch(() => {
                                $paperlessBillingState.enrollmentState = $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;
                            });
                        }
                    } else if (!$guarantor.hasPaperlessBillingPromptShown) {
                        $paperlessBillingSelector(this.emailSettings);
                        $guarantor.hasPaperlessBillingPromptShown = true;
                        $loggingService.logEvent("First time enrollment dialog shown");
                    }
                }
            };

            this.openPaperlessBillingModal = () => {
                $loggingService.logEvent("Open paperless billing modal from banner click");
                $paperlessBillingSelector(this.emailSettings);
            };

            $scope.$watch(() => $paperlessBillingState.enrollmentState, (newVal, oldVal) => {
                if (newVal !== undefined && oldVal !== newVal) {
                    evaluatePaperlessBillingBanner();
                    evaluatePaperlessBillingModal();

                    if (!$sessionStorage.fromDirectLink) {
                        if (oldVal === $paperlessBillingState.EnrollmentStates.LOADING_ENROLLMENTS &&
                            newVal === $paperlessBillingState.EnrollmentStates.NOT_ENROLLED) {
                            $loggingService.logEvent("Paperless billing page loaded");
                        }

                        if (oldVal !== $paperlessBillingState.EnrollmentStates.LOADING_ENROLLMENTS &&
                            newVal === $paperlessBillingState.EnrollmentStates.ENROLLED) {
                            $loggingService.logEvent("Paperless billing enrolled");
                        }

                        if (newVal === $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL) {
                            $loggingService.logEvent("Paperless billing enrollment failure");
                        }
                    }
                }
            });

            let statePromise = $guarantor.getEnrollmentState();
            let emailSettingsPromise = $paperlessBillingState.getEmailSettings();
            $q.all([statePromise, emailSettingsPromise]).then(results => {
                $paperlessBillingState.enrollmentState = results[0];
                this.emailSettings = results[1];
            }).then(() => {
                evaluatePaperlessBillingBanner();
                evaluatePaperlessBillingModal();
            });

            if ($window.innerWidth >= 700) {
                this.pendingSectionExpanded = true;
                this.historySectionExpanded = true;
            }

            let paymentsPlanPromise = $guarantor.restangularized()
                .all("paymentplan").one("hasplan").get();

            paymentsPlanPromise.then((hasPlan: boolean) => {
                this.hasPaymentPlan = hasPlan;
            }, () => {
                this.goToErrorState("Bill pay was unable to retrieve payment plan information for the user.");
            });

            this.billingGroupClientInfoPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get();
            this.balancePromise = $guarantor.restangularized().all("charges").one("balance").get();

            $q.all([this.billingGroupClientInfoPromise, this.balancePromise]).then(results => {
                this.setBillingGroupClientInfo(results[0]);
                this.loadChargeData(results[1]);
            }, () => {
                this.goToErrorState("Bill pay was unable to retrieve client and charge information for user.");
            });

            this.setBillingGroupClientInfo = (info: zotec.patientExperience.client.ClientInfo) => {
                this.acceptPaymentPlan = info.acceptPaymentPlan;
                this.minPaymentAmountActual = info.minPaymentAmount;
                this.maxNumberOfPayments = info.maxNumberOfPayments;
                this.showEncounterHeader = info.displayEncounterHeaderOnStatement;
                this.rollUpEncounterInsurancePaymentsOnStatement = info.rollUpEncounterInsurancePaymentsOnStatement;
                this.daysPastForIVRAgencyCharges = info.daysPastForIVRAgencyCharges;
                this.daysToDisplayAtAgencyBalanceWarning = info.daysToDisplayAtAgencyBalanceWarning;
            }

            this.loadChargeData = (balance: zotec.patientExperience.guarantorCommunication.GuarantorBalance) => {
                if (balance.returnCode === 0) {
                    this.showErrorGettingBalance = true;
                }
                this.guarantorBalance = balance.balance;
                this.allClosedCharges = balance.closedCharges;
                this.patientsOpenCharges = balance.openChargesByPatients;
                this.patientsPendingCharges = balance.pendingChargesByPatients;
                this.filteredClosedCharges = this.allClosedCharges;

                var allCharges = [];
                allCharges.concat(balance.closedCharges);
                allCharges.concat(balance.openCharges);

                _.forEach(balance.pendingChargesByPatients, (charge: zotec.patientExperience.guarantorCommunication.PatientCharges) => {
                    allCharges.concat(charge.charges);
                });
                var chargesAtAgency = allCharges.filter(chg => chg.atAgency);

                if (chargesAtAgency.length > 0) {
                    var mostRecentAtAgencyDate = chargesAtAgency.sort((a, b) => a.atAgencyDate > b.atAgencyDate ? -1 : 1)[0].atAgencyDate;
                    this.triggerAtAgencyBalanceWarning(mostRecentAtAgencyDate);
                }

                _.forEach(balance.openCharges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                    this.pmCharges.push({
                        id: charge.chargeId,
                        discount: 0,
                        discountCode: "",
                        isCredit: false,
                        paidInFull: true,
                        paymentAmount: charge.balance,
                        originalAmount: charge.baseFee,
                    });
                });

                _.forEach(this.patientsOpenCharges, (patient) => {
                    _.forEach(patient.groupedCharges, (encounter: zotec.patientExperience.guarantorCommunication.GroupedCharges) => {
                        encounter.isSelfPay = !encounter.charges.some((charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                            return charge.filingProfileId != "SP";
                        });
                    });
                });

                this.searchHistoryRange();
                this.loadingCharges = false;
            };

            this.triggerAtAgencyBalanceWarning = (agencyDate: Date) => {
                if (this.daysToDisplayAtAgencyBalanceWarning <= 0) return;

                let displayWarningStart = addDays(agencyDate, this.daysPastForIVRAgencyCharges);
                let displayWarningCutoff = addDays(displayWarningStart, this.daysToDisplayAtAgencyBalanceWarning);

                if (moment(today).isAfter(displayWarningStart, 'day') && moment(today).isSameOrBefore(displayWarningCutoff, 'day')) {
                    this.includesAtAgencyStatement = true;
                }
            }

            this.checkForInsuranceForPatient = (personId, profileId) => {
                $guarantor.restangularized()
                    .one("patients", personId)
                    .one("insurance")
                    .one("profileType", profileId)
                    .get().then((profile: zotec.patientExperience.insurance.InsuranceProfile) => {
                        if (profile != null) {
                            this.hasInsuranceOnFile = true;
                        }
                    }, (response) => {
                        console.log("there was an error in the response", response);
                    });
            };

            $guarantor.restangularized()
                .all("patients").getList().then((people: [zotec.patientExperience.insurance.RelatedPerson]) => {
                    for (let p of people) {
                        for (let profile of ["IB", "ID", "IV"]) {
                            this.checkForInsuranceForPatient(p.personId, profile);
                        }
                    }
                }, (response) => {
                    console.log("error getting related people: ", response);
                });

            this.goToBillPay = () => {
                if (this.pendingTransaction && this.enablePendingPaymentWarnings) {
                    this.PendingPaymentPlanModal(true);
                } else {
                    $state.go("entity.bill-pay");
                }
            };

            this.startAPaymentPlan = () => {
                if (this.pendingTransaction && this.enablePendingPaymentWarnings) {
                    this.PendingPaymentPlanModal(false);
                } else {
                    $state.go("entity.payment-plan", { selectedPaymentMethod: this.selectedPaymentMethod, guarantorBalance: this.guarantorBalance, pmCharges: this.pmCharges });
                }
            }

            this.popoverCurrentBill = {
                content: "These charges have gone through your insurance (if applicable) and the resulting balance is your responsibility."
            };

            this.popoverPendingCharges = {
                content: "We have billed your insurance provider for these charges. We will let you know when we get a response or require action on your part."
            };

            this.popoverBillingHistory = {
                content: "These are charges that have been closed or resolved."
            };

            this.toggleOpen = (patient) => {
                patient.isExpanded = !patient.isExpanded;
            };

            this.shouldShowPaymentPlanButton = () => {
                this.calculatePaymentPlanOptions();
                return this.allowPaymentPlanUpFront && this.acceptPaymentPlan && !this.hasPaymentPlan && this.guarantorBalance >= this.minPaymentAmountActual && this.hasCalculatedPPOptions;
            }

            this.calculatePaymentPlanOptions = () => {
                if (this.guarantorBalance && this.minPaymentAmountActual && this.maxNumberOfPayments) {
                    this.clientMinPaymentAmount = Math.max(Math.ceil(this.guarantorBalance / this.maxNumberOfPayments * 100) / 100, Math.min(this.minPaymentAmountActual, this.guarantorBalance));
                    let maxPaymentsWithMinimumAmount = Math.floor(this.guarantorBalance / this.clientMinPaymentAmount);
                    this.hasCalculatedPPOptions = maxPaymentsWithMinimumAmount > 1;
                }
            }

            this.togglePendingSection = () => {
                this.pendingSectionExpanded = !this.pendingSectionExpanded;
            };

            this.toggleClosed = () => {
                this.closedExpanded = !this.closedExpanded;
            }

            this.toggleHistorySection = () => {
                this.historySectionExpanded = !this.historySectionExpanded;
            }

            this.itemizedBillError = null;
            this.statementError = null;

            this.tryUpdateItemizedRange = () => {
                this.itemizedBillError = null;

                if (this.itemizedStartMonth == null || this.itemizedStartDay == null || this.itemizedStartYear == null
                    || this.itemizedEndMonth == null || this.itemizedEndDay == null || this.itemizedEndYear == null) {
                    this.itemizedBillError = 'Please enter a date range.';
                    return false;
                }
                var startDate = moment(this.itemizedStartYear + '-' + this.itemizedStartMonth + '-' + this.itemizedStartDay, "YYYY-MM-DD");
                var endDate = moment(this.itemizedEndYear + '-' + this.itemizedEndMonth + '-' + this.itemizedEndDay, "YYYY-MM-DD");
                if (!startDate.isValid() || !endDate.isValid()) {
                    this.itemizedBillError = 'Please enter a valid date range.';
                    return false;
                }

                this.itemizedStartDate = startDate <= endDate ? startDate : endDate;
                this.itemizedEndDate = endDate >= startDate ? endDate : startDate;
                return true;
            }

            this.searchHistoryRange = () => {
                if (this.tryUpdateItemizedRange()) {
                    this.filteredClosedCharges = _.filter(this.allClosedCharges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                        const chargeDate = moment(charge.activityDate, "MM/DD/YYYY");
                        return chargeDate >= this.itemizedStartDate && chargeDate <= this.itemizedEndDate;
                    });
                }
            }

            this.generateItemizedBillPdf = () => {
                if (this.tryUpdateItemizedRange()) {
                    this.searchHistoryRange();
                    const startDate = this.itemizedStartDate.format("MM/DD/YYYY");
                    const endDate = this.itemizedEndDate.format("MM/DD/YYYY");
                    $loggingService.logEvent("Download of itemized bill started");
                    $pdf.open($guarantor.restangularized().getRestangularUrl() + "/accountpdfs/itemizedbill", { startDate: startDate, endDate: endDate }, "Generating itemized bill...", (success: boolean) => {
                        if (!success) {
                            this.itemizedBillError = "An unknown error occured. Please try again.";
                            $loggingService.logEvent("Download of itemized bill failed");

                        } else {
                            this.itemizedBillError = null;
                            $loggingService.logEvent("Download of itemized bill completed");
                        }
                    });
                }
            }

            this.clickStatement = () => {
                $loggingService.logEvent("Download of statement started");
                $pdf.open($guarantor.restangularized().getRestangularUrl() + "/accountpdfs/statement", {}, "Generating statement...", (success: boolean) => {
                    if (!success) {
                        this.statementError = "An unknown error occured. Please try again.";
                        $loggingService.logEvent("Download of statement failed");
                    } else {
                        this.statementError = null;
                        $loggingService.logEvent("Download of statement completed");
                    }
                });
            }

            this.generateTosReceiptPdf = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/tos", { paymentId: $sessionStorage.chargeId }, "Generating receipt...", null);
            }

            this.scrollToTop = () => {
                $anchorScroll();
            };

            this.getText = (value) => {
                return value ? value : "Patient opted out";
            }

            function addDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
            }

            this.PendingPaymentPlanModal = (IsPayInFull) => {
                $PaymentPendingModalService(IsPayInFull, this.pendingTransactionAmount, this.selectedPaymentMethod, this.guarantorBalance, this.pmCharges);
            }
        }]);
