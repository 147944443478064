/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$promptModal", ["$modal", "$rootScope",
    ($modal, $rootScope) => {

        function $promptModal(title: string, text: string, yesText: string, noText: string, yesAction: Function, noAction: Function,yesColorRed:boolean = false) {
            var scope = $rootScope.$new();
            scope.title = title;
            scope.text = text;
            scope.yesText = yesText;
            scope.yesColorRed = yesColorRed;
            scope.noText = noText;
            var modal = $modal({
                templateUrl: "Templates/Controls/Services/PromptModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom"
            });

            scope.close = () => {
                if(noAction !== null){
                    noAction();
                }
                modal.hide();
            }

            scope.yesAction = () => {
                yesAction();
                modal.hide();
            };

            return modal;
        }

        return $promptModal;
    }
]);
