/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$staticModal", ["$modal", "$rootScope",
    function ($modal, $rootScope) {

        return function (text: string, show: Function, hide: Function) {
            var scope = $rootScope.$new();
            scope.text = text;

            scope.$on("modal.show",
                function() {
                    show();
                }
            );

            scope.$on("modal.hide",
                function () {
                    if (hide) hide();
                }
            );

            return $modal({
                scope: scope,
                templateUrl: "/Templates/Controls/Services/StaticModal",
                backdrop: "static"
            });

        }
    }
]);
