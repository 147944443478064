/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />
/// <reference path="../../../../numeral/index.d.ts" />
/// <reference path="../../../../lodash/lodash.d.ts" />
/// <reference path="../../../../angular-strap/angular-strap.d.ts"/>

angular.module("Zotec.PatientExperience.Controls")
    .factory("$paperlessBillingSelector", ["Restangular", "$modal", "$rootScope", "$timeout", "$guarantor", "$siteSettings", "$sessionStorage", "$paperlessBillingState", "$loggingService", "$q", "$window", "$state", "$showAddressValidation",
        (Restangular: restangular.IService, $modal, $rootScope, $timeout, $guarantor: zotec.patientExperience.Services.GuarantorService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $sessionStorage, $paperlessBillingState: zotec.patientExperience.Services.PaperlessBillingStateService,
            $loggingService: zotec.patientExperience.Services.LoggingService,
            $q: angular.IQService, $window, $state, $showAddressValidation) => {

            return (settings: zotec.patientExperience.paperless.PaperlessEmailSettings) => {
                const scope = $rootScope.$new();
                const states = {
                    StartPaperless: 'StartPaperless',
                    ConfirmContactInfo: 'ConfirmContactInfo',
                    RestartPaperless: 'RestartPaperless',
                    ChangePreference: 'ChangePreference',
                    Confirmed: 'Confirmed',
                };

                this.EnrollmentStatesArray = $paperlessBillingState.EnrollmentStatesArray;

                scope.requestPaperlessConsent = $siteSettings.requestPaperlessConsent;
                scope.disablePaperlessCharity = $siteSettings.disablePaperlessCharity;

                scope.startingState = () => {
                    if ($paperlessBillingState.enrollmentState === $paperlessBillingState.EnrollmentStates.OPTED_OUT) {
                        return $siteSettings.usePxPDiscoveredEmailFlow ? states.ConfirmContactInfo : states.RestartPaperless;
                    } else if ($paperlessBillingState.enrollmentState === $paperlessBillingState.EnrollmentStates.ENROLLED) {
                        return states.ChangePreference;
                    }
                    else {
                        return $siteSettings.usePxPDiscoveredEmailFlow ? states.ConfirmContactInfo : states.StartPaperless;
                    }
                };

                if ($siteSettings.companyNameDisplay && $siteSettings.companyNameDisplay.length > 0) {
                    scope.clientName = $siteSettings.companyNameDisplay;
                }
                else {
                    $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                        .then((info: zotec.patientExperience.client.ClientInfo) => {

                            scope.clientName = info.name;
                        },
                            () => {
                                console.log("error getting settings");
                            });
                }

                scope.setaddress = (address: zotec.patientExperience.AddressValidation.Address) => {
                    scope.model.address1 = address.address1;
                    scope.model.address2 = address.address2;
                    scope.model.city = address.city;
                    scope.model.state = address.state;
                    scope.model.zip = address.zip5;
                }

                scope.setverified = (hasverified: boolean) => {
                    scope.model.verifiedInformation = hasverified;
                    if (hasverified) {
                        scope.model.addressIndicator = "G";
                    } else {
                        scope.model.addressIndicator = "U";
                    }
                }

                scope.openValidationModal = (setaddress: Function, setverified: Function) => {
                    $showAddressValidation(
                        {
                            address1: scope.model.address1,
                            address2: scope.model.address2,
                            city: scope.model.city,
                            state: scope.model.state,
                            zip5: scope.model.zip
                        },
                        {
                            address1: scope.suggestedaddress.address1,
                            address2: scope.suggestedaddress.address2,
                            city: scope.suggestedaddress.city,
                            state: scope.suggestedaddress.state,
                            zip5: scope.suggestedaddress.zip5
                        },
                        setaddress,
                        setverified);
                }

                scope.showAddressValidation = (setaddress: Function, setverified: Function) => {
                    if (scope.model.communicationPreference === "PAP") {
                        if (scope.model.originalGuarantor.addressIndicator !== "G"
                            || scope.model.originalGuarantor.address1 !== scope.model.address1
                            || scope.model.originalGuarantor.zip !== scope.model.zip) {

                            if (scope.model.address2 === null) {
                                scope.model.address2 = "";
                            }

                            var deferred = $q.defer();
                            let queryParams = {
                                address1: scope.model.address1,
                                address2: scope.model.address2,
                                city: scope.model.city,
                                state: scope.model.state,
                                zip5: scope.model.zip,
                                zip4: ""
                            };

                            Restangular.one("VerifyAddress").one("usps").get(queryParams)
                                .then((response: zotec.patientExperience.AddressValidation.Address) => {
                                    if (response.address1 !== null) {
                                        scope.suggestedaddress = response;
                                        deferred.resolve();

                                        scope.openValidationModal(setaddress, setverified);
                                    }
                                    else {
                                        scope.model.verifiedInformation = true;
                                    }
                                }, () => {
                                    deferred.resolve();
                                });
                        }
                    }
                }

                scope.getTermsOfUseUrl = () => {
                    var returnUrl = "";
                    if ($siteSettings.termsOfUseUrl === null || $siteSettings.termsOfUseUrl.length === 0) {
                        returnUrl = $state.href("entity.terms") || "#/global/terms";
                    } else {
                        const regex = RegExp('[https?:\/\/|#].*');
                        if (regex.test($siteSettings.termsOfUseUrl)) {
                            returnUrl = $siteSettings.termsOfUseUrl;
                        } else {
                            returnUrl = "https://" + $siteSettings.termsOfUseUrl;
                        }
                    }
                    return returnUrl;
                }

                scope.redirectTerms = () => {
                    $window.open(scope.getTermsOfUseUrl());
                }

                let guarantor = null;

                scope.model = {
                    clientId: null,
                    currentState: scope.startingState(),
                    emailSettings: settings,
                    communicationPreference: 'SMS',
                    guarantorsNumber: "",
                    email: '',
                    thanks: false,
                    fromDirectLink: $sessionStorage.fromDirectLink,
                    usePxPDiscoveredEmailFlow: $siteSettings.usePxPDiscoveredEmailFlow,
                    verifiedInformation: false,
                    hasError: false,
                    errorMessage: '',
                };

                scope.guarantorsPhoneValidationPattern = /^(\d{3}-\d{3}-\d{4})$/;

                let informationPromise = $guarantor.restangularized()
                    .one('information')
                    .get();
                let preferencePromise = $guarantor.restangularized()
                    .one("paperless")
                    .one("communicationPreference")
                    .get();
                $q.all([informationPromise, preferencePromise]).then(results => {
                    guarantor = results[0];
                    const basicCommPreference = results[1] == null ? 'SMS' : results[1];
                    const commPreference = (scope.model.usePxPDiscoveredEmailFlow && scope.model.fromDirectLink && guarantor.emailStatus == 'D') ? 'EML' : basicCommPreference;
                    scope.model.communicationPreference = commPreference;
                    scope.model.email = guarantor.email;
                    scope.model.guarantorsNumber = guarantor.mobilePhone || guarantor.homePhone;
                    scope.model.originalEmail = guarantor.email;
                    scope.model.originalEmailIndicator = guarantor.emailStatus;
                    scope.model.originalPhone = guarantor.mobilePhone || guarantor.homePhone;
                    scope.model.originalPhoneIndicator = scope.model.originalPhone == guarantor.mobilePhone ? guarantor.mobilePhoneIndicator : guarantor.phoneIndicator;
                    scope.model.address1 = guarantor.address1;
                    scope.model.address2 = guarantor.address2;
                    scope.model.city = guarantor.city;
                    scope.model.state = guarantor.state;
                    scope.model.zip = guarantor.zip;
                    scope.model.originalGuarantor = guarantor;
                    scope.model.addressIndicator = scope.model.originalGuarantor.addressIndicator;
                }).catch(reason => {
                    if (reason.status === 401) {
                        scope.hideModal();
                    }
                });

                scope.notificationOffering = () => {
                    return scope.model.emailSettings.offerEmail ? 'an email or text' : '';
                }

                var modal: mgcrea.ngStrap.modal.IModal = $modal({
                    scope: scope,
                    templateUrl: "/Templates/Controls/Services/PaperlessBillingSelector",
                    backdrop: false
                });

                $rootScope.paperlessModalShown = true;

                scope.startPaperlessComplete = () => {
                    scope.model.currentState = $sessionStorage.fromDirectLink ? ($siteSettings.usePxPDiscoveredEmailFlow ? states.Confirmed : states.ConfirmContactInfo) : states.ConfirmContactInfo;
                }

                scope.restartPaperlessComplete = () => {
                    scope.model.currentState = states.ConfirmContactInfo;
                    $loggingService.logEvent("User began re-enrollment process");
                }

                scope.confirmCommunicationPreferenceComplete = () => {
                    if (!$siteSettings.usePxPDiscoveredEmailFlow) {
                        scope.model.communicationPreference === 'EML'
                            ? scope.confirmEmailAddressComplete()
                            : scope.confirmContactInfoComplete();
                    } else {
                        switch (scope.model.communicationPreference) {
                            case 'EML': {
                                scope.confirmEmailAddressComplete();
                                break;
                            }
                            case 'SMS': {
                                scope.confirmContactInfoComplete();
                                break;
                            }
                            default: {
                                scope.confirmPaperPreference();
                                break;
                            }
                        }
                    }
                }

                scope.confirmEmailAddressComplete = () => {
                    if (scope.model.email.length <= 4) {
                        return;
                    }
                    if ($siteSettings.usePxPDiscoveredEmailFlow) {
                        if (scope.model.email == scope.model.originalEmail && scope.model.originalEmailIndicator == 'B') {
                            scope.model.errorMessage = "The email you have entered cannot receive emails properly. Please use another email."
                            scope.model.hasError = true;
                            return;
                        }
                    }
                    if ($paperlessBillingState.EnrollmentStatesArray.includes($paperlessBillingState.enrollmentState)) {
                        $paperlessBillingState.enrollWithEmail(scope.model.email)
                            .then((enrollment: boolean) => {
                                $paperlessBillingState.enrollmentState =
                                    enrollment
                                        ? $paperlessBillingState.EnrollmentStates.ENROLLED
                                        : $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;
                                $guarantor.isEnrolled = enrollment;
                            }).catch(() => {
                                $paperlessBillingState.enrollmentState =
                                    $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;
                            });
                    }
                    if ($paperlessBillingState.enrollmentState !== $paperlessBillingState.EnrollmentStates.ENROLLED) {
                        $loggingService.logEvent("User enrolled in paperless billing with email");
                        scope.model.thanks = true;
                        $paperlessBillingState.enrollmentState = $paperlessBillingState.EnrollmentStates.ENROLLED;
                    }
                    else {
                        $loggingService.logEvent("User changed email paperless preference");
                    }
                    scope.showConfirmedModal();
                }

                scope.confirmContactInfoComplete = () => {
                    if (scope.model.guarantorsNumber.length !== 10) {
                        return;
                    }
                    if ($siteSettings.usePxPDiscoveredEmailFlow) {
                        if (scope.model.guarantorsNumber == scope.model.originalPhone && scope.model.originalPhoneIndicator == 'B') {
                            scope.model.errorMessage = "The number you have entered cannot accept text messages. Please use another number."
                            scope.model.hasError = true;
                            return;
                        }
                    }
                    if ($paperlessBillingState.EnrollmentStatesArray.includes($paperlessBillingState.enrollmentState)) {
                        var request = $paperlessBillingState.enrollWithMobile(scope.model.guarantorsNumber);
                        request.then(enrollment => {
                            if (enrollment) {
                                if ($paperlessBillingState.enrollmentState !==
                                    $paperlessBillingState.EnrollmentStates.ENROLLED) {
                                    $loggingService.logEvent("User enrolled in paperless billing with text");
                                } else {
                                    $loggingService.logEvent("User changed text paperless preference");
                                }
                            }

                            $paperlessBillingState.enrollmentState =
                                enrollment
                                    ? $paperlessBillingState.EnrollmentStates.ENROLLED
                                    : $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;
                            $guarantor.isEnrolled = enrollment;
                            scope.showConfirmedModal();
                        }).catch(() => {
                            $paperlessBillingState.enrollmentState =
                                $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;
                        });
                    }
                    scope.model.thanks = true;
                }

                scope.hideModal = () => {
                    $rootScope.paperlessModalShown = undefined;
                    modal.hide();
                }

                scope.unenrollWithoutAddress = () => {
                    $guarantor.restangularized()
                        .one("paperless")
                        .one("unenroll")
                        .put().then(() => {
                            $guarantor.isEnrolled = false;
                            $sessionStorage.fromDirectLink = false;
                            $loggingService.logEvent("User has unenrolled from paperless billing");
                            $paperlessBillingState.enrollmentState = $paperlessBillingState.EnrollmentStates.OPTED_OUT;
                        });
                    scope.hideModal();
                }

                scope.confirmPaperPreference = () => {
                    $guarantor.restangularized()
                        .all("paperless")
                        .all("unenroll")
                        .post({
                            address1: scope.model.address1,
                            address2: scope.model.address2,
                            zip: scope.model.zip,
                            addressIndicator: scope.model.addressIndicator
                        }).then(() => {
                            $guarantor.isEnrolled = false;
                            $sessionStorage.fromDirectLink = false;
                            $loggingService.logEvent("User has unenrolled from paperless billing");
                            $paperlessBillingState.enrollmentState = $paperlessBillingState.EnrollmentStates.OPTED_OUT;
                            scope.showConfirmedModal();
                        }).catch(() => {
                            $paperlessBillingState.enrollmentState =
                                $paperlessBillingState.EnrollmentStates.FAILED_TO_ENROLL;
                        });
                    scope.model.thanks = true;
                }

                scope.cancelUpdatePreference = () => {
                    scope.hideModal();
                }

                scope.showConfirmedModal = () => {
                    if ($siteSettings.usePxPDiscoveredEmailFlow) {
                        scope.model.currentState = states.Confirmed;
                    } else {
                        $timeout(scope.hideModal, 1500);
                    }     
                }

                scope.btnEndStartPaperlessText = () => {
                    return $sessionStorage.fromDirectLink ? "Opt In to Paperless Billing" : "Enroll";
                }

                scope.btnEndConfirmContactInfoText = () => {
                    if (!$siteSettings.usePxPDiscoveredEmailFlow) {
                        if (scope.model.currentState === 'ChangePreference')
                            return "Save";
                        else
                            return scope.model.thanks ? "Thanks!" : "Opt In to Paperless Billing";
                    } else {
                        let contactTypeText = ''
                        switch (scope.model.communicationPreference) {
                            case 'EML':
                                contactTypeText = 'Emails';
                                break;
                            case 'SMS':
                                contactTypeText = 'Text Messages';
                                break;
                            default:
                                contactTypeText = 'Paper Statements';
                                break;
                        }
                        return `Opt into ${contactTypeText}`;
                    }
                }

                scope.btnEndConfirmContactInfoTextDisabled = () => {
                    return !scope.model.verifiedInformaton === true;
                }

                scope.communicationSettingsTitle = () => {
                    return "Confirm your Consent and Communication Preferences";
                }

                scope.communicationSettingsText = () => {
                    return (scope.model.emailSettings.offerEmail || $siteSettings.usePxPDiscoveredEmailFlow)
                    ? "How would you like to receive future communication?"
                        : "Mobile Phone Number";
                }

                scope.buttonEnabled = () => {
                    let emailPattern = /^\b[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*\b$/i;
                    let guarantorsNumberValidationPattern = /^(\d{10})$/;
                    if (!$siteSettings.usePxPDiscoveredEmailFlow) {
                        if (scope.model.communicationPreference === 'SMS') {
                            return guarantorsNumberValidationPattern.test(scope.model.guarantorsNumber);
                        } else
                            return emailPattern.test(scope.model.email);
                    } else {
                        let result = false;
                        if (scope.model.communicationPreference === 'SMS') {
                            result = guarantorsNumberValidationPattern.test(scope.model.guarantorsNumber);
                        } else if (scope.model.communicationPreference === 'EML') {
                            result = emailPattern.test(scope.model.email);
                        } else {
                            result = (scope.model.address1 != null && scope.model.address1.length > 0 && scope.model.zip != null && scope.model.zip.length >= 5)
                        }

                        result = result ? scope.model.verifiedInformation : result;

                        return result;
                    }
                }

                scope.addressText = () => {
                    return `${scope.model.address1}${(scope.model.address2 == '' ? '' : '' + scope.model.address2)} ${scope.model.city}, ${scope.model.state} ${scope.model.zip}`
                }

                scope.formattedPhone = () => {
                    var cleaned = ('' + scope.model.guarantorsNumber).replace(/\D/g, '');
                    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (match) {
                        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
                    }
                    return null;
                }

                scope.communicationTypeSelectedText = () => {
                    switch (scope.model.communicationPreference) {
                        case 'EML': {
                            return "email";
                        }
                        case 'SMS': {
                            return "text"
                        }
                        default: {
                            return "paper statements"
                        }
                    }
                }

                scope.switchToPaperless = () => {
                    scope.model.communicationPreference = 'SMS';
                    scope.model.verifiedInformation = false;
                }

                scope.switchToPaper = () => {
                    scope.model.communicationPreference = 'PAP';
                    scope.model.verifiedInformation = false;
                }

                scope.onCommunicationTypeChanged = () => {
                    scope.model.verifiedInformation = false;
                    scope.model.guarantorsNumber = scope.model.originalPhone;
                    scope.model.email = scope.model.originalEmail;
                    scope.model.address1 = scope.model.originalGuarantor.address1;
                    scope.model.address2 = scope.model.originalGuarantor.address2;
                    scope.model.zip = scope.model.originalGuarantor.zip;
                    scope.model.city = scope.model.originalGuarantor.city;
                    scope.model.state = scope.model.originalGuarantor.state;
                    scope.model.addressIndicator = scope.model.originalGuarantor.addressIndicator;
                    scope.model.hasError = false;
                    scope.model.errorMessage = '';
                }

                scope.zipChanged = () => {
                    if (scope.model.zip == null || scope.model.zip.length < 5) {
                        scope.model.state = "";
                        scope.model.city = "";
                        return;
                    } else if (scope.model.zip.length > 5) {
                        return;
                    }
                    const firstFive = scope.model.zip.substring(0, 5);
                    Restangular.one("datasources", $siteSettings.dataSourceId)
                        .one("zipcodes", firstFive)
                        .get().then((zipCode: zotec.patientExperience.guarantorPatient.ZipCode) => {
                            if (zipCode == null) {
                                return;
                            }
                            if (zipCode.zip !== firstFive) {
                                return;
                            }
                            scope.model.state = zipCode.state;
                            scope.model.city = zipCode.city;
                        },
                            (response) => {
                                console.log("error getting zip code information: ", response);
                            });
                }

                scope.dismissModalText = () => {
                    return "Get a paper bill by mail instead";
                }

                scope.showDismissalButton = () => {
                    return $paperlessBillingState.enrollmentState == $paperlessBillingState.EnrollmentStates.ENROLLED ||
                        $paperlessBillingState.enrollmentState == $paperlessBillingState.EnrollmentStates.OPTED_OUT;
                }

                return modal;
            }
        }
    ]);