/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpFooter", ["$siteSettings", "$window", "$state", "$sessionStorage", ($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $window, $state, $sessionStorage) => {
        return {
            templateUrl: "Templates/Controls/Directives/Footer",
            restrict: "E",
            link: (scope: FooterScope) => {

                scope.isTelepayPatientFooter = () => {
                    return $state.is("entity.tele-med-account-history") || $state.is("entity.tele-med-payment-plan") || $state.is("entity.tele-med-bill-pay") || $sessionStorage.destination === 'TOSPayment';
                }

                scope.getPrivacyPolicyUrl = () => {
                    var returnUrl = $siteSettings.getPrivacyPolicyUrl();
                    return returnUrl;
                }

                scope.getTermsOfUseUrl = () => {
                    var returnUrl = $siteSettings.getTermsOfUseUrl();
                    return returnUrl;
                }

                scope.redirectPrivacy = () => {
                    $window.open(scope.getPrivacyPolicyUrl());
                }

                scope.redirectTerms = () => {
                    $window.open(scope.getTermsOfUseUrl());
                }
            }
        };
    }]);

interface FooterScope extends angular.IScope {
    getPrivacyPolicyUrl: Function;
    getTermsOfUseUrl: Function;
    redirectPrivacy: Function;
    redirectTerms: Function;
    privacyPolicyUrl: string;
    termsOfUseUrl: string;
    isTelepayPatientFooter:Function;
}