/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$PaymentPendingModalService", ["$modal", "$rootScope","$state",
    function ($modal, $rootScope, $state) {

        function getPendingTransactionsModal(isPayInFull, pendingPaymentAmount, selectedPaymentMethod, openBalance, pmCharges) {
            var scope = $rootScope.$new();

            scope.paymentAmount = pendingPaymentAmount;

            var modal = $modal({
                templateUrl: "Templates/Controls/Services/PendingPaymentModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom",
                show: true
            });
            scope.close = () => {
                    modal.hide();
            }

            scope.reject = () => {
                modal.hide();
            }

            scope.accept = () => {
                    if (isPayInFull) {
                        modal.hide();
                        $state.go("entity.bill-pay");
                    } else {
                        modal.hide();
                        $state.go("entity.payment-plan", { selectedPaymentMethod: selectedPaymentMethod, guarantorBalance: openBalance, pmCharges: pmCharges});
                    }
                }



            , (response) => {
                console.log("Bill pay was unable to retrieve pending transaction information for the user.", response);
                };
            }

        return getPendingTransactionsModal;
    }
]);