angular.module("Zotec.PatientExperience.WordDocument")
    .directive("zpWordDocument", () => {
        return {
            restrict: "E",
            controller: "zpWordDocumentController",
            controllerAs: "zpWordDocument",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Document/zpWordDocument"
        }
    });
