/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceEncounters")
    .controller("zpInsuranceEncountersController", ["Restangular", "$q", "$scope", "$state", "$siteSettings", "$guarantor", "$updateInsurance", "$serviceInfoService",
        function (Restangular: restangular.IService, $q: angular.IQService, $scope, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $updateInsurance: zotec.patientExperience.Services.UpdateInsuranceService, $serviceInfoService) {
            if (!$siteSettings.isGuarantorBillingClient) {
                $state.go("entity.insurance");
                return;
            }

            if ($state.params.insurancePolicy == null || $state.params.encounterDetails == null) {
                $state.go("entity.account-history");
                return;
            }
            
            this.insurancePolicy = $state.params.insurancePolicy;
            this.encounterDetails = $state.params.encounterDetails;

            this.allChecked = true;

            this.personId = this.encounterDetails.patientId;
            this.allEncounters = $state.params.relatedEncounters as zotec.patientExperience.insurance.RelatedEncounter[];
            this.relatedEncounters = null as zotec.patientExperience.insurance.RelatedEncounter[];
                
            this.relatedEncounters = this.allEncounters.filter(encounter => encounter.encounterNumber != this.encounterDetails.patientEncounterNumber);
            this.relatedEncounters.forEach(encounter => encounter.included = true);

            this.getServices = (encounterNumber: number) => {
                return this.allEncounters.find(encounter => encounter.encounterNumber === encounterNumber).charges;
            }

            this.savedServices = this.getServices(this.encounterDetails.patientEncounterNumber);

            this.clickServiceInfo = (chargeId: number) => {
                if (chargeId === 0) {
                    return;
                }
                $serviceInfoService(chargeId);
            }

            this.checkedAll = () => {
                _.forEach(this.relatedEncounters, (encounter: zotec.patientExperience.insurance.RelatedEncounter) => { encounter.included = this.allChecked; });
            };

            this.skip = () => {
                $state.go("entity.insurance");
            }

            this.submit = () => {
                var relatedChargesToUpdate = [];
                _.forEach(this.relatedEncounters, (encounter: zotec.patientExperience.insurance.RelatedEncounter) => {
                    if (encounter.included === true) {
                        _.forEach(encounter.charges, (charge: zotec.patientExperience.insurance.ChargeDetail) => {
                            relatedChargesToUpdate.push(charge.chargeId);
                        });
                    }
                });

                this.insurancePolicy.chargesToUpdate = this.encounterDetails.chargeIds.concat(relatedChargesToUpdate);
                $updateInsurance.update(this.insurancePolicy, this.encounterDetails);
            }
        }]);
