/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$updateInsurance", ["Restangular", "$staticModal", "$state", "$guarantor", "$rootScope", "$modal", "$siteSettings", "$q",
    function(Restangular: restangular.IService,
        $staticModal,
        $state,
        $guarantor,
        $rootScope,
        $modal,
        $siteSettings,
        $q: angular.IQService) {

        this.update = (insurancePolicy: zotec.patientExperience.insurance.InsurancePolicy, encounterDetails: zotec.patientExperience.insurance.EncounterDetails) => {
            let modal = $staticModal("Updating...", () => {
                const relationshipTypes: { [key: number]: string } = {};
                const tryAgainMessage = "There was an error updating your insurance. Please try again.";
                Restangular.one("datasources", $siteSettings.dataSourceId)
                    .all("relationshiptypes")
                    .getList()
                    .then((relTypes: [zotec.patientExperience.insurance.RelationshipType]) => {
                        for (let relType of relTypes) {
                            relationshipTypes[relType.relationshipTypeId] = relType.description;
                        }

                        $guarantor.restangularized()
                            .one("patients", insurancePolicy.personId)
                            .all("insurance")
                            .post(insurancePolicy)
                            .then(
                                (result: zotec.patientExperience.insurance.UpdateInsurancePolicyResult) => {
                                    if (result.errorMessage) {
                                        this.showFailureModal(result.errorMessage, encounterDetails);
                                    } else if (!result.eligible || result.requiresCorrections) {
                                        this.showEligibilityErrorModal(result, insurancePolicy, relationshipTypes, encounterDetails);
                                    } else if (!result.refiled) {
                                        $state.go("entity.insurance", { noRefile: true, encounterDetails: encounterDetails });
                                    } else {
                                        $state.go("entity.insurance", { pending: true, encounterDetails: encounterDetails });
                                    }
                                },
                                () => this.showFailureModal(tryAgainMessage, encounterDetails)
                            )
                            .finally(() => modal.hide());
                    }, (response) => {
                        console.log("error getting relationship types: ", response);
                        this.showFailureModal(tryAgainMessage, encounterDetails);
                        modal.hide();
                    });
            });
        }

        this.showFailureModal = (message: string, encounterDetails: zotec.patientExperience.insurance.EncounterDetails) => {
            var scope = $rootScope.$new();
            scope.message = message;

            var modal = $modal({
                templateUrl: "Templates/PatientExperience/Insurance/Modals/InsuranceUpdateFailureModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom",
                show: true
            });
            scope.close = () => {
                modal.hide();
            }
            scope.pay = () => {
                modal.hide();
                $state.go("entity.bill-pay");
            }
            scope.contact = () => {
                modal.hide();
                $state.go("entity.contact");
            }
            scope.verify = () => {
                modal.hide();
                $state.go("entity.insurance", {encounterDetails: encounterDetails});
            }
        }

        this.showEligibilityErrorModal = (
            result: zotec.patientExperience.insurance.UpdateInsurancePolicyResult,
            insurancePolicy: zotec.patientExperience.insurance.InsurancePolicy,
            relationshipTypes: {[key: number] : string},
            encounterDetails: zotec.patientExperience.insurance.EncounterDetails) => {
            if (!result.isAgent) {
                if (result.requiresCorrections) {
                    this.showFailureModal("Your insurance plan is telling us that one or more fields are different from what they have on file. You may correct this information to match what is on file or contact us.", encounterDetails);
                } else {
                    this.showFailureModal("We could not confirm eligibility with your insurance plan, please review your coverage information.", encounterDetails);
                }
            } else {
                if (result.eligible && result.requiresCorrections) {
                    this.showAgentEligibleWithEditsModal(this.mapCorrectionsToDialogContent(insurancePolicy, result.corrections, relationshipTypes), insurancePolicy, result.corrections, encounterDetails);
                } else if (!result.eligible && result.requiresCorrections) {
                    this.showAgentIneligibleWithEditsModal(this.mapCorrectionsToDialogContent(insurancePolicy, result.corrections, relationshipTypes), insurancePolicy, result.corrections, encounterDetails);
                } else {
                    this.showAgentIneligibleWithoutEditsModal(insurancePolicy, encounterDetails);
                }
            }
        }

        this.updateInsurancePolicyForForceUpdate = (corrections: zotec.patientExperience.insurance.UpdateInsurancePolicyCorrections, insurancePolicy: zotec.patientExperience.insurance.InsurancePolicy) => {
            insurancePolicy.bypassPayerEdits = true;

            if (corrections.policy != null && corrections.policy.carrierId != null) {
                insurancePolicy.carrierId = corrections.policy.carrierId;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.firstName != null) {
                insurancePolicy.policyOwner.firstName = corrections.policyOwner.firstName;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.lastName != null) {
                insurancePolicy.policyOwner.lastName = corrections.policyOwner.lastName;
            }

            if (corrections.policy != null && corrections.policy.policyNumber != null) {
                insurancePolicy.policyNumber = corrections.policy.policyNumber;
            }

            if (corrections.policy != null && corrections.policy.groupNumber != null) {
                insurancePolicy.groupNumber = corrections.policy.groupNumber;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.relationship != null) {
                insurancePolicy.policyOwner.relationship = corrections.policyOwner.relationship;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.address1 != null) {
                insurancePolicy.policyOwner.address1 = corrections.policyOwner.address1;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.address2 != null) {
                insurancePolicy.policyOwner.address2 = corrections.policyOwner.address2;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.city != null) {
                insurancePolicy.policyOwner.city = corrections.policyOwner.city;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.state != null) {
                insurancePolicy.policyOwner.state = corrections.policyOwner.state;
            }

            if (corrections.policyOwner != null && corrections.policyOwner.zipcode != null) {
                insurancePolicy.policyOwner.zip = corrections.policyOwner.zipcode;
            }
        }

        this.mapCorrectionsToDialogContent = (submittedPolicyInfo: zotec.patientExperience.insurance.InsurancePolicy,
            corrections: zotec.patientExperience.insurance.UpdateInsurancePolicyCorrections,
            relationshipTypes: {[key: number]: string}) => {
            var results = [] as zotec.patientExperience.insurance.CorrectionsDialogField[];

            if (corrections.policyOwner != null && (corrections.policyOwner.firstName != null || corrections.policyOwner.lastName != null)) {
                var correction = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                correction.fieldName = "Policy Holder";
                correction.enteredValue = submittedPolicyInfo.policyOwner.firstName + " " + submittedPolicyInfo.policyOwner.lastName;
                correction.correctedValue = (corrections.policyOwner.firstName != null ? corrections.policyOwner.firstName : submittedPolicyInfo.policyOwner.firstName) + " " +
                    (corrections.policyOwner.lastName != null ? corrections.policyOwner.lastName : submittedPolicyInfo.policyOwner.lastName);

                results.push(correction);
            }

            if (corrections.policy != null && (corrections.policy.policyNumber != null || corrections.policy.groupNumber != null)) {
                var policyNumberCorrection = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                policyNumberCorrection.fieldName = "Policy Number";
                policyNumberCorrection.enteredValue = submittedPolicyInfo.policyNumber;
                policyNumberCorrection.correctedValue = corrections.policy.policyNumber != null ? corrections.policy.policyNumber : submittedPolicyInfo.policyNumber;

                results.push(policyNumberCorrection);

                var groupNumberCorrection = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                groupNumberCorrection.fieldName = "Group Number";
                groupNumberCorrection.enteredValue = submittedPolicyInfo.groupNumber;
                groupNumberCorrection.correctedValue = corrections.policy.groupNumber != null ? corrections.policy.groupNumber : submittedPolicyInfo.groupNumber;

                results.push(groupNumberCorrection);
            }

            if (corrections.policyOwner != null && corrections.policyOwner.relationship != null) {
                var correction = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                correction.fieldName = "Relationship";
                correction.enteredValue = relationshipTypes[submittedPolicyInfo.policyOwner.relationship];
                correction.correctedValue = relationshipTypes[corrections.policyOwner.relationship];

                results.push(correction);
            }

            if (corrections.policyOwner != null && (corrections.policyOwner.address1 != null ||
                corrections.policyOwner.address2 != null ||
                corrections.policyOwner.city != null ||
                corrections.policyOwner.state != null ||
                corrections.policyOwner.zipcode != null)) {

                var addressCorrection = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                addressCorrection.fieldName = "Address 1";
                addressCorrection.enteredValue = submittedPolicyInfo.policyOwner.address1;
                addressCorrection.correctedValue = corrections.policyOwner.address1 != null ? corrections.policyOwner.address1 : submittedPolicyInfo.policyOwner.address1;

                results.push(addressCorrection);

                var address2Correction = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                address2Correction.fieldName = "Address 2";
                address2Correction.enteredValue = submittedPolicyInfo.policyOwner.address2;
                address2Correction.correctedValue = corrections.policyOwner.address2 != null ? corrections.policyOwner.address2 : submittedPolicyInfo.policyOwner.address2;

                results.push(address2Correction);

                var cityStateZipCorrection = {} as zotec.patientExperience.insurance.CorrectionsDialogField;
                cityStateZipCorrection.fieldName = "City, State Zip";
                cityStateZipCorrection.enteredValue = submittedPolicyInfo.policyOwner.city + ", " + submittedPolicyInfo.policyOwner.state + " " + submittedPolicyInfo.policyOwner.zip;
                cityStateZipCorrection.correctedValue = (corrections.policyOwner.city != null ? corrections.policyOwner.city : submittedPolicyInfo.policyOwner.city) + ", " +
                    (corrections.policyOwner.state != null ? corrections.policyOwner.state : submittedPolicyInfo.policyOwner.state) + " " +
                    (corrections.policyOwner.zipcode != null ? corrections.policyOwner.zipcode : submittedPolicyInfo.policyOwner.zip);

                results.push(cityStateZipCorrection);
            }

            return results;
        }

        this.showAgentEligibleWithEditsModal = (edits, insurancePolicy: zotec.patientExperience.insurance.InsurancePolicy, corrections: zotec.patientExperience.insurance.UpdateInsurancePolicyCorrections, encounterDetails: zotec.patientExperience.insurance.EncounterDetails) => {
            var scope = $rootScope.$new();
            scope.edits = edits;

            var modal = $modal({
                templateUrl: "Templates/PatientExperience/Insurance/Modals/InsuranceUpdateAgentEligibleWithEditsModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom",
                show: true
            });

            scope.cancel = () => {
                modal.hide();
                $state.go("entity.insurance", {encounterDetails: encounterDetails});
            }

            scope.reject = () => {
                modal.hide();
                insurancePolicy.bypassPayerEdits = true;
                let updateModal = $staticModal("Updating...", () => {
                    $guarantor.restangularized()
                        .one("patients", insurancePolicy.personId)
                        .all("insurance")
                        .post(insurancePolicy)
                        .then($state.go("entity.insurance", { pending: true, encounterDetails: encounterDetails }))
                        .finally(() => updateModal.hide());
                });
            }

            scope.accept = () => {
                modal.hide();
                this.updateInsurancePolicyForForceUpdate(corrections, insurancePolicy);
                let updateModal = $staticModal("Updating...", () => {
                    $guarantor.restangularized()
                        .one("patients", insurancePolicy.personId)
                        .all("insurance")
                        .post(insurancePolicy)
                        .then($state.go("entity.insurance", { pending: true, encounterDetails: encounterDetails }))
                        .finally(() => updateModal.hide());
                });
            }
            
        }

        this.showAgentIneligibleWithEditsModal = (edits, insurancePolicy: zotec.patientExperience.insurance.InsurancePolicy, corrections: zotec.patientExperience.insurance.UpdateInsurancePolicyCorrections, encounterDetails: zotec.patientExperience.insurance.EncounterDetails) => {
            var scope = $rootScope.$new();
            scope.edits = edits;

            var modal = $modal({
                templateUrl: "Templates/PatientExperience/Insurance/Modals/InsuranceUpdateAgentIneligibleWithEditsModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom",
                show: true
            });

            scope.cancel = () => {
                modal.hide();
                $state.go("entity.insurance", {encounterDetails: encounterDetails});
            }

            scope.reject = () => {
                modal.hide();
                insurancePolicy.bypassPayerEdits = true;
                let updateModal = $staticModal("Updating...", () => {
                    $guarantor.restangularized()
                        .one("patients", insurancePolicy.personId)
                        .all("insurance")
                        .post(insurancePolicy)
                        .then($state.go("entity.insurance", { pending: true, encounterDetails: encounterDetails }))
                        .finally(() => updateModal.hide());
                });

            }

            scope.accept = () => {
                modal.hide();
                this.updateInsurancePolicyForForceUpdate(corrections, insurancePolicy);
                let updateModal = $staticModal("Updating...", () => {
                    $guarantor.restangularized()
                        .one("patients", insurancePolicy.personId)
                        .all("insurance")
                        .post(insurancePolicy)
                        .then($state.go("entity.insurance", { pending: true, encounterDetails: encounterDetails }))
                        .finally(() => updateModal.hide());
                });
            }
            
        }

        this.showAgentIneligibleWithoutEditsModal = (insurancePolicy: zotec.patientExperience.insurance.InsurancePolicy, encounterDetails: zotec.patientExperience.insurance.EncounterDetails) => {
            var scope = $rootScope.$new();

            var modal = $modal({
                templateUrl: "Templates/PatientExperience/Insurance/Modals/InsuranceUpdateAgentIneligibleWithoutEditsModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom",
                show: true
            });

            scope.cancel = () => {
                modal.hide();
                $state.go("entity.insurance", {encounterDetails: encounterDetails});
            }

            scope.override = () => {
                modal.hide();
                insurancePolicy.bypassPayerEdits = true;
                let updateModal = $staticModal("Updating...", () => {
                    $guarantor.restangularized()
                        .one("patients", insurancePolicy.personId)
                        .all("insurance")
                        .post(insurancePolicy)
                        .then($state.go("entity.insurance", { pending: true, encounterDetails: encounterDetails }))
                        .finally(() => updateModal.hide());
                });
            }
        }
        
        return this as zotec.patientExperience.Services.UpdateInsuranceService;
    }]
);