/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Welcome", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.welcome", {
            url: "/welcome",
            template: "<zp-welcome></zp-welcome>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Welcome", entity);
                }]
            }
        });
    }]);