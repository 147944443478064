/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpBindHtmlCompile", [
        "$compile", ($compile) => {
            return {
                restrict: "A",
                link: (scope, element, attrs) => {
                    scope.$watch(() => {
                        return scope.$eval(attrs.zpBindHtmlCompile);
                    }, (value) => {
                        element.html(value && value.toString());
                        var compileScope = scope;
                        if (attrs.bindHtmlScope) {
                            compileScope = scope.$eval(attrs.bindHtmlScope);
                        }
                        $compile(element.contents())(compileScope);
                    });
                }
            };
        }
    ]);