/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$showAddressValidation", ["Restangular", "$modal", "$rootScope", "$q",
    (Restangular: restangular.IService, $modal, $rootScope,  $q: angular.IQService) => {

        return (enteredaddress: zotec.patientExperience.AddressValidation.Address,
            suggestedaddress: zotec.patientExperience.AddressValidation.Address,
            setaddress: Function, setverified: Function) => {

            var scope = $rootScope.$new();

            scope.enteredaddress = enteredaddress;
            scope.suggestedaddress = suggestedaddress;
            scope.useEnteredaddress = true;

            var modal = $modal({
                scope: scope,
                templateUrl: "/Templates/PatientExperience/AddressValidation/zpAddressValidation",
                backdrop: "static"
            });

            scope.close = () => {
                setverified(false);
                modal.hide();
            }

            scope.savedaddress = (useEnteredaddress: boolean) => {
                if (useEnteredaddress) {
                    setaddress(scope.enteredaddress);
                } else {
                    setaddress(scope.suggestedaddress);
                }
                setverified(true);
                modal.hide();
            }

            return modal;
        }
    }
]);
