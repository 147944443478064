/// <reference path="../../../../.../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Models")
    .factory("SiteModel", ["Restangular", "$siteSettings", "$q", "$guarantor",
        (Restangular: restangular.IService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $q: angular.IQService, $guarantor: zotec.patientExperience.Services.GuarantorService) => {
            return {
                getSettings: (url: string, entityId: string) => {
                    var deferred = $q.defer();
                    Restangular.one("siteurldetails").get({ siteUrl: url, entityId: entityId }).then((details: zotec.patientExperience.SiteUrlDetails) => {
                        $siteSettings.isLoaded = true;

                        if (details != null) {
                            $siteSettings.entityId = details.entityId;
                            $siteSettings.dataSourceId = details.dataSourceId;
                            $siteSettings.clientId = details.clientId;
                            $siteSettings.billingGroupId = details.billingGroupId;
                            $siteSettings.billingGroupLocalId = details.billingGroupLocalId;
                            $siteSettings.isGuarantorBillingClient = details.isGuarantorBillingClient;

                            $siteSettings.companyNameDisplay = details.companyNameDisplay;
                            $siteSettings.companyWebsiteUrl = details.companyWebsiteUrl;
                            $siteSettings.primaryColor = details.primaryColor;
                            $siteSettings.allowGoodFaithEstimate = details.allowGoodFaithEstimate;
                            $siteSettings.allowPrePay = details.allowPrePay;
                            $siteSettings.showIncomeQuestions = details.showIncomeQuestions;
                            $siteSettings.allowPaymentPlanUpFront = details.allowPaymentPlanUpFront;
                            $siteSettings.disablePaperlessCharity = details.disablePaperlessCharity;
                            $siteSettings.enableContactUsForm = details.enableContactUsForm;
                            $siteSettings.requestPaperlessConsent = details.requestPaperlessConsent;
                            $siteSettings.addPhone1Prefix = details.addPhone1Prefix;

                            $siteSettings.feedbackUrl = details.feedbackUrl;
                            $siteSettings.feedbackPromptAfterPayment = details.feedbackPromptAfterPayment;
                            $siteSettings.sendPatientSurveyUponPaymentAndNoFeedback = details.sendPatientSurveyUponPaymentAndNoFeedback;
                            $siteSettings.feedbackPromptText = details.feedbackPromptText;
                            $siteSettings.feedbackPromptButtonText = details.feedbackPromptButtonText;
                            $siteSettings.faqUrl = details.faqUrl;
                            $siteSettings.hiddenFaq = details.hiddenFaq;
                            $siteSettings.privacyPolicyUrl = details.privacyPolicyUrl;
                            $siteSettings.sessionExpirationInMinutes = details.sessionExpirationInMinutes;
                            $siteSettings.termsOfUseUrl = details.termsOfUseUrl;
                            $siteSettings.usePxPDiscoveredEmailFlow = details.usePxPDiscoveredEmailFlow;
                            $siteSettings.hoursOfOperation = details.hoursOfOperation;
                            $siteSettings.usePaymentRounding = details.usePaymentRounding;
                            $guarantor && $guarantor.isLoggedIn && $guarantor.loadPaperless();
                            $siteSettings.insuranceUpdateWaitWindow = details.insuranceUpdateWaitWindow;
                        }
                        deferred.resolve(details);
                    }, () => {
                        console.log("error fetching client info from url");
                        deferred.resolve();
                    });
                    return deferred.promise;
                }
            } as zotec.patientExperience.resolvers.SiteModel;
        }])