angular.module("Zotec.PatientExperience.Video")
    .controller("zpVideoController",
        ["$state",
            function ($state) {
                const videoId = +$state.params.videoId;

                if (videoId !== 1) {
                    $state.go("default");
                }
            }
        ]);