/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpPopover", ["$popover", ($popover) => {
        return {
            restrict: "A",
            link: (scope: PopoverScope, element: JQuery, attributes: PopoverAttributes) => {

                element.css("text-decoration", "underline");

                var title = "";
                var content = "";

                switch (attributes.zpPopover) {
                    case "guarantor":
                        title = "Guar-an-tor";
                        content = "A guarantor (or, responsible party) is the person held accountable for the patient's bill. The guarantor is always the patient, unless the patient is a minor or an incapacitated adult.";
                        break;
                    case "patient":
                        title = "Patient";
                        content = "The patient is the person upon whom the services were performed.";
                        break;
                    case "guarantorID":
                        title = "Guarantor ID";
                        content = "This is the ID for the guarantor account and can be found on your statement.";
                        break;
                    case "clientID":
                        title = "Client ID";
                        content = "This is the ID for the client and can be found on your statement.";
                        break;
                    case "denial":
                        title = "Denial";
                        content = "A denial occurs when your insurance provider informs us that they will not cover the cost of an encounter.";
                        break;
                }

                if (title === "") {
                    return;
                }

                $popover(element, {
                    title: title,
                    content: content,
                    placement: "auto top",
                    target: element
                });
            }
        };
    }]);

interface PopoverScope extends angular.IScope {

}

interface PopoverAttributes extends angular.IAttributes {
    zpPopover: string;
}