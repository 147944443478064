/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Default", [])
    .config(["$stateProvider", "$urlMatcherFactoryProvider", ($stateProvider, $urlMatcherFactoryProvider) => {
        $urlMatcherFactoryProvider.caseInsensitive(true);
        $urlMatcherFactoryProvider.strictMode(false);
        $stateProvider.state("default", {
            url: "/?u",
            template: "<zp-default></zp-default>"
        });
    }]);