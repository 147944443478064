/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />
/// <reference path="../../../../../restangular/restangular.d.ts"/>

angular.module("Zotec.PatientExperience.InsuranceAdd")
    .controller("zpInsuranceAddController", ["Restangular", "$q", "$scope", "$state", "$siteSettings", "$guarantor", "$updateInsurance",
        function (Restangular: restangular.IService, $q: angular.IQService, $scope, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $updateInsurance) {

            if ($state.params.patientId == null) {
                $state.go("entity.insurance");
                return;
            }

            const self = this;

            this.insurancePolicy = ({} as zotec.patientExperience.insurance.InsurancePolicy);
            this.encounterDetails = $state.params.encounterDetails;
            this.isGuarantorBillingClient = $siteSettings.isGuarantorBillingClient;
            this.relatedEncounters = null as zotec.patientExperience.insurance.RelatedEncounter[];
            
            this.curState = 0;
            this.insuranceType = "medical";
            this.availableStates = ["policy", "group", "policy-owner"];

            this.relationshipTypes = [] as zotec.patientExperience.insurance.RelationshipType[];
            this.relatedPeople = [] as zotec.patientExperience.insurance.RelatedPerson[];
            this.friendlyCarriers = [] as zotec.patientExperience.insurance.FriendlyCarrier[];
            this.foundCarriers = [];
            
            this.dentalEnabled = false;
            this.visionEnabled = false;

            this.showExtraCarrierFields = false;

            this.policyNumberRegEx = (null as string);
            this.samplePolicyNumber = (null as string);
            this.groupNumberRequired = false;
            this.policyNumberValid = false;
            this.matchedCarrier = null;

            if (this.isGuarantorBillingClient && this.encounterDetails == null) {
                $state.go("entity.account-history");
                return;
            }
            
            this.getProfileId = () => {
                switch (this.insuranceType) {
                    case "medical" :
                        return "IB";
                    case "dental" :
                        return "ID";
                    case "vision" :
                        return "IV";
                    case "workers":
                        return "WC";
                    case "auto":
                        return "AA";
                    default:
                        return "IB";
                }
            };

            if (!this.isGuarantorBillingClient) {
                $guarantor.restangularized()
                    .one("patients", $state.params.patientId)
                    .one("insurance")
                    .one("profileType", "IB")
                    .one("policy", 0)
                    .get()
                    .then((policy: zotec.patientExperience.insurance.InsurancePolicy) => {
                            this.insurancePolicy = policy.plain();
                        },
                        (response) => {
                            console.log("error in the response: ", response);
                        });
            } else {
                this.insurancePolicy.personId = this.encounterDetails.patientId;
                this.insurancePolicy.chargesToUpdate = this.encounterDetails.chargeIds;
            }

            this.clientPhone = "";

            const rest = $siteSettings.billingGroupRestangularized();
            if (rest != null) {
                rest.one("clientinfo").get().then((clientInfo: zotec.patientExperience.client.ClientInfo) => {
                    this.clientPhone = clientInfo.phone || "";
                    this.dentalEnabled = clientInfo.enableDentalProfile;
                    this.visionEnabled = clientInfo.enableVisionProfile;
                }, () => {
                    console.log("error getting client info");
                });
            }

            let carrierQueryParams = {
                dataSourceId: $siteSettings.dataSourceId,
                billingGroupId: $siteSettings.billingGroupId,
                groupCode: this.encounterDetails != null ? this.encounterDetails.locationGroupCode : ""
            };

            this.friendlyCarriers = $siteSettings.clientRestangularized()
                .all("carriers")
                .getList(carrierQueryParams);

            this.getCarriers = (searchText: string) => {
                if (!(typeof (searchText) === "string") || searchText.length < 2) {
                    return $q.when([]);
                }

                let foundCarriers = [];

                let queryParams = {
                    carrierName: searchText,
                    profileId: this.getProfileId(),
                    dataSourceId: $siteSettings.dataSourceId,
                    billingGroupId: $siteSettings.billingGroupId,
                    groupCode: this.encounterDetails != null ? this.encounterDetails.locationGroupCode : ""
                };

                return $siteSettings.clientRestangularized().all("carriers")
                    .getList(queryParams)
                    .then((carriers) => {
                        foundCarriers = carriers;
                        this.foundCarriers = foundCarriers;

                        this.carrierChanged();

                        return this.friendlyCarriers
                            .$object
                            .concat(foundCarriers);
                    });
            };

            Restangular.one("datasources", $siteSettings.dataSourceId)
                .all("relationshiptypes")
                .getList().then((relationshipTypes: [zotec.patientExperience.insurance.RelationshipType]) => {
                    for (let relType of relationshipTypes) {
                        if (relType.description === 'Self') {
                            this.relationshipTypes.splice(0, 0, relType);
                        }
                        else {
                            this.relationshipTypes.push(relType);
                        }
                    }
                }, (response) => {
                    console.log("error getting relationship types: ", response);
                });

            $guarantor.restangularized()
                .all("relatedpeople").getList().then((relatedPeople: [zotec.patientExperience.insurance.RelatedPerson]) => {
                    this.relatedPeople.push({
                        personId: 0,
                        name: "Other"
                    });
                    for (let r of relatedPeople) {
                        this.insurancePolicy.policyOwnerId = r.personId;
                        this.relatedPeople.push(r);
                    }
                }, (response) => {
                    console.log("error getting related people: ", response);
                });

            this.currentState = () => {
                return this.availableStates[this.curState];
            };

            this.goBack = () => {
                if (this.curState === 0) {
                    $state.go("entity.insurance", { encounterDetails: this.encounterDetails });
                } else {
                    this.curState -= 1;
                }
            };

            this.isInsuranceBill = () => {
                return this.insuranceType === "medical" ||
                    this.insuranceType === "dental" ||
                    this.insuranceType === "vision";
            };
            
            this.goForward = () => {
                if (this.curState === this.availableStates.length - 1) {

                    this.insurancePolicy.profileId = this.getProfileId();
                    
                    if (!this.isInsuranceBill() && this.matchedCarrier == null) {
                        // Use the values typed by the user
                        this.insurancePolicy.carrierName = this.insurancePolicy.carrier;
                        this.insurancePolicy.carrierId = null;
                    } else {
                        // Use the values of the selected carrier
                        this.insurancePolicy.carrierId = this.matchedCarrier.id;
                        this.insurancePolicy.carrierName = this.matchedCarrier.name;
                        this.insurancePolicy.carrier = this.matchedCarrier;
                        this.insurancePolicy.address = null;
                        this.insurancePolicy.zip = null;
                    }

                    if ($siteSettings.isGuarantorBillingClient) {
                        self.insurancePolicy.chargesToUpdate = self.encounterDetails.chargeIds;
                        $guarantor.restangularized()
                            .one("patients", self.insurancePolicy.personId)
                            .one("insurance")
                            .one("encounter", self.encounterDetails.patientEncounterNumber)
                            .get({ chargeIds: self.encounterDetails.chargeIds }).then((profile: zotec.patientExperience.insurance.InsuranceProfile) => {
                                self.assignInsuranceBucket(profile);
                                $guarantor.restangularized().one("patients", self.insurancePolicy.personId).one("insurance").one("insuranceBucket", self.insurancePolicy.id).one("relatedEncounters", self.encounterDetails.patientEncounterNumber).getList().then((relatedEncounters: zotec.patientExperience.insurance.RelatedEncounter[]) => {
                                    self.relatedEncounters = relatedEncounters.filter(encounter => encounter.charges.some((charge) => charge.filingProfileId != "SP"));
                                }).then(() => {
                                    if (self.relatedEncounters.length > 1) {
                                        $state.go("entity.insurance-encounters", { encounterDetails: self.encounterDetails, insurancePolicy: self.insurancePolicy, relatedEncounters: self.relatedEncounters });
                                    }
                                    else {
                                        $updateInsurance.update(this.insurancePolicy, this.encounterDetails);
                                    }
                                });
                            });
                    } else {
                        $state.go("entity.insurance-charges", {
                            insurancePolicy: this.insurancePolicy
                        });
                    }

                    return;

                } else {
                    this.curState += 1;
                }
            };

            this.popoverCarrier = {
                content: "Your insurance carrier (ex: BCBS of CA)."
            };

            this.popoverPolicyNumber = {
                content: "Your insurance policy number (ex: 5651684684654)."
            };

            this.popoverGroupNumber = {
                content: "Your insurance policy group number (ex: 498987654987)."
            };

            this.popoverPolicyOwner = {
                content: "Who is the policy owner of the insurance (ex: James Smith)?"
            };

            this.popoverPatientRelationship = {
                content: "How is the patient related to the policy owner (ex: Father or Mother)?"
            };

            this.popoverCarrierAddress = {
                content: "We need the address for your carrier, because it's not one we are familiar with at first glance."
            };

            this.popoverCarrierZip = {
                content: "We also need the zip code for your carrier."
            };

            this.findCarrierMatch = () => {
                const carrier = this.insurancePolicy.carrier;
                if (carrier == null) {
                    return null;
                }
                if (typeof carrier === "string") {
                    return _.find(this.foundCarriers, (c: zotec.patientExperience.insurance.IInsuranceCarrier) => {
                        return c.name.toUpperCase() === this.insurancePolicy.carrier.toUpperCase();
                    });
                }
                return carrier;
            };

            this.carrierChanged = () => {
                this.showExtraCarrierFields = false;
                this.policyNumberRegEx = null;
                this.samplePolicyNumber = null;
                this.groupNumberRequired = false;

                this.matchedCarrier = this.findCarrierMatch();
                if (this.matchedCarrier == null) {
                    this.showExtraCarrierFields = !this.isInsuranceBill();
                    return;
                }

                this.policyNumberRegEx = this.matchedCarrier.policyNumberRegEx;
                this.samplePolicyNumber = this.matchedCarrier.samplePolicyNumber;
                this.groupNumberRequired = this.matchedCarrier.groupNumberRequired;
                this.policyNumberChanged();
            };

            this.policyNumberChanged = () => {
                if (this.policyNumberRegEx == null) {
                    this.policyNumberValid = true;
                    return;
                }
                const regex = new RegExp(this.policyNumberRegEx);
                this.policyNumberValid = regex.test(this.insurancePolicy.policyNumber);
            };

            this.insuranceTypeChanged = () => {
                if (this.insurancePolicy.carrier != null) {
                    this.insurancePolicy.carrier = null;
                    this.insurancePolicy.policyNumber = null;
                    this.carrierChanged();
                }
            };

            this.carrierValid = () => {
                return !!this.matchedCarrier || !this.isInsuranceBill();
            };

            this.forwardButtonValid = () => {
                if (this.currentState() === 'policy' && this.isInsuranceBill()) {
                    return this.carrierValid();
                }
                return true;
            };

            this.forwardButtonAction = () => {
               if (this.currentState() === 'policy-owner' && $siteSettings.isGuarantorBillingClient) {
                   return 'Save';
               }
               return 'Next';
            }

            this.showCarrierNotFound = () => {
                return !this.matchedCarrier && this.insurancePolicy.carrier && this.isInsuranceBill();
            };

            this.policyOwnerZipChanged = () => {
                if (this.insurancePolicy.policyOwner.zip.length < 5) {
                    this.insurancePolicy.policyOwner.state = "";
                    this.insurancePolicy.policyOwner.city = "";
                    return;
                } else if (this.insurancePolicy.policyOwner.zip.length > 5) {
                    return;
                }
                const firstFive = this.insurancePolicy.policyOwner.zip.substring(0, 5);
                Restangular.one("datasources", $siteSettings.dataSourceId)
                    .one("zipcodes", firstFive)
                    .get().then((zipCode: zotec.patientExperience.insurance.ZipCode) => {
                        if (zipCode == null) {
                            return;
                        }
                        if (zipCode.zip !== firstFive) {
                            return;
                        }
                        this.insurancePolicy.policyOwner.state = zipCode.state;
                        this.insurancePolicy.policyOwner.city = zipCode.city;
                    },
                        (response) => {
                            console.log("error getting zip code: ", response);
                        });
            }

            this.assignInsuranceBucket = (profile: zotec.patientExperience.insurance.InsuranceProfile) => {
                if (profile == null || profile.primaryPolicy == null) {
                    this.insurancePolicy.id = zotec.patientExperience.insurance.InsuranceBucket.Primary;
                }
                else if (profile.secondaryPolicy == null) {
                    this.insurancePolicy.id = zotec.patientExperience.insurance.InsuranceBucket.Secondary;
                }
                else {
                    this.insurancePolicy.id = zotec.patientExperience.insurance.InsuranceBucket.Tertiary;
                }
            }

        }]);
