/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$guarantor", ["Restangular", "$staticModal", "$state", "$zpModal", "$siteSettings", "$q", 
    function (Restangular: restangular.IService, $staticModal, $state, $zpModal, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
        $q: angular.IQService) {

        this.destination = "";

        this.guarantorId = 0;
        this.chargeId = 0;
        this.dateOfBirth = (null as Date);

        this.firstName = "";
        this.lastName = "";

        this.isLoggedIn = false;
        this.isEnrolled = false;
        this.enrollmentLoaded = false;
        this.hasPaperlessBillingPromptShown = false;

        this.restangularized = () => {
            const siteRest = $siteSettings.billingGroupRestangularized();
            if (siteRest === null) {
                return null;
            }
            return siteRest.all("guarantor");
        }

        var deferred = $q.defer();
        this.safeRestangularized = function () {
            return deferred.promise;
        }

        this.clearValues = () => {
            //$siteSettings.clearValues();

            this.guarantorId = 0;
            this.chargeId = 0;
            this.dateOfBirth = (null as Date);

            this.firstName = "";
            this.lastName = "";

            this.isLoggedIn = false;
            this.isEnrolled = false;
            this.enrollmentLoaded = false;
            this.hasPaperlessBillingPromptShown = false;
        }

        this.logIn = (person: zotec.patientExperience.authentication.Person) => {
            this.guarantorId = person.personId;
            this.firstName = person.firstName;
            this.lastName = person.lastName;
            this.dateOfBirth = person.dateOfBirth;

            this.isLoggedIn = true;
        }

        this.loadPaperless = () => {
            (!this.enrollmentLoaded) && this.restangularized() &&
                this.restangularized()
                    .one("paperless")
                    .one("enrolled")
                    .get().then((enrollment: string) => {
                        this.isEnrolled = enrollment == "ENROLLED";
                        this.enrollmentLoaded = true;
                        deferred.resolve(this.restangularized());
                    });
        }

        this.getEnrollmentState = () => {
           return this.restangularized().one("paperless")
                .one("enrolled")
                .get();

        }

        this.logOut = () => {
            var modal = $staticModal("Signing out...", () => {
                Restangular.all("authentication")
                    .remove().then((result: boolean) => {
                        if (!result) {
                            modal.hide();
                            $zpModal("Uh-Oh", "There was an error logging you out!", () => { });
                            return;
                        }

                        this.clearValues();

                        $state.go("entity.login").then(() => {modal.hide();});
                    }, (response) => {
                        modal.hide();
                        console.log(response);
                    });
            });
        }

        return this;
    }]
);
