/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Survey")
    .directive("zpSurvey", () => {
        return {
            restrict: "AE",
            controller: "zpSurveyController",
            controllerAs: "zpSurvey",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Survey/zpSurvey"
        }
    });