/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedAccountHistory")
    .directive("zpTeleMedAccountHistory", () => {
        return {
            restrict: "AE",
            controller: "zpTeleMedAccountHistoryController",
            controllerAs: "zpTeleMedAccountHistory",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/TeleMedAccountHistory/zpTeleMedAccountHistory"
        }
    });