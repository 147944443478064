/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Insurance")
    .controller("zpInsuranceController", ["Restangular", "$scope", "$state", "$siteSettings", "$guarantor", function (Restangular: restangular.IService, $scope, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService) {

        this.pending = $state.params.pending;
        this.noRefile = $state.params.noRefile;

        this.insuranceProfiles = [] as zotec.patientExperience.insurance.InsuranceProfile[];
        this.medicalInsuranceProfile = null;
        this.dentalInsuranceProfile = null;
        this.visionInsuranceProfile = null;
        this.patients = [];
        this.chosenPersonId = null;
        this.encounterDetails = (null as zotec.patientExperience.insurance.EncounterDetails);
        this.insuranceUpdateWaitWindow = $siteSettings.insuranceUpdateWaitWindow;
        
        this.primaryInsuranceIsLocked = false;
        this.primaryInsuranceMessage = null as string;

        this.secondaryInsuranceIsLocked = false;
        this.allowAddInsurance = true;

        this.isGuarantorBillingClient = $siteSettings.isGuarantorBillingClient;
        if (this.isGuarantorBillingClient) {
            if ($state.params.encounterDetails != null) {
                this.chosenPersonId = $state.params.encounterDetails.patientId;
                this.encounterDetails = $state.params.encounterDetails;

                $guarantor.restangularized()
                    .one("patients", this.chosenPersonId)
                    .one("insurance")
                    .one("encounter", this.encounterDetails.patientEncounterNumber)
                    .get({ chargeIds: this.encounterDetails.chargeIds }).then((profile: zotec.patientExperience.insurance.InsuranceProfile) => {
                        if (profile != null) {
                            this.insuranceProfiles = [ profile ];
                            this.updateRestrictions(profile.encounterDetails);

                            if (profile.profileId === "IB") {
                                this.medicalInsuranceProfile = profile;
                            }
                            if (profile.profileId === "ID") {
                                this.dentalInsuranceProfile = profile;
                            }
                            if (profile.profileId === "IV") {
                                this.visionInsuranceProfile = profile;
                            }
                        }
                    }, (response) => {
                        console.log("there was an error in the response", response);
                    });
            }
            else {
                $state.go("entity.account-history");
                return;
            }
        }
        else {
            $guarantor.restangularized()
                .all("patients").getList().then((people: [zotec.patientExperience.insurance.RelatedPerson]) => {
                    for (let p of people) {
                        this.patients.push(p);
                    }
                }, (response) => {
                    console.log("error getting related people: ", response);
                });

            this.personChanged = () => {
                this.insuranceProfile = [] as zotec.patientExperience.insurance.InsuranceProfile[];
                this.medicalInsuranceProfile = null;
                this.dentalInsuranceProfile = null;
                this.visionInsuranceProfile = null;

                $guarantor.restangularized()
                    .one("patients", this.chosenPersonId)
                    .one("insurance")
                    .get().then((profiles: zotec.patientExperience.insurance.InsuranceProfile[]) => {
                        if (profiles != null && profiles.length > 0) {
                            this.insuranceProfiles = profiles;

                            let medicalInsuranceProfiles = profiles.filter((profile: zotec.patientExperience.insurance.InsuranceProfile) => profile.profileId === "IB");
                            if (medicalInsuranceProfiles != null && medicalInsuranceProfiles.length > 0) {
                                this.medicalInsuranceProfile = medicalInsuranceProfiles[0];
                            }

                            let dentalInsuranceProfiles = profiles.filter((profile: zotec.patientExperience.insurance.InsuranceProfile) => profile.profileId === "ID");
                            if (dentalInsuranceProfiles != null && dentalInsuranceProfiles.length > 0) {
                                this.dentalInsuranceProfile = dentalInsuranceProfiles[0];
                            }

                            let visionInsuranceProfiles = profiles.filter((profile: zotec.patientExperience.insurance.InsuranceProfile) => profile.profileId === "IV");
                            if (visionInsuranceProfiles != null && visionInsuranceProfiles.length > 0) {
                                this.visionInsuranceProfile = visionInsuranceProfiles[0];
                            }
                        }
                    }, (response) => {
                        console.log("there was an error in the response", response);
                    });
            };

            this.popover = {
                content: "You can view/edit insurance information for any of the patients that you are financially responsible for."
            };
        }

        this.verifyInsurance = () => {
            return !this.isGuarantorBillingClient;
        }

        this.matchEncounterReasonCode = (reasonCode: string, reasonCodesToMatch: string[]) => {
            return reasonCode != null && reasonCodesToMatch.includes(reasonCode);
        }

        this.primaryHasProcessedClaim = (reasonCode: string) => {
            return this.isGuarantorBillingClient &&
                this.matchEncounterReasonCode(reasonCode,
                    [
                        "CO5", "CO50", "CO96", "CO119", "CO167", "CO197", "CO288", "COB20", "MA18", "OA23", "PR2",
                        "PR49", "PR100", "PR151", "PR242", "PRA1", "PRB20"
                    ]);
        }

        this.primaryHasIncorrectInformation = (reasonCode: string) => {
            return this.isGuarantorBillingClient &&
                this.matchEncounterReasonCode(reasonCode,
                    ["CO16", "CO20", "CO22", "CO31", "COB7", "COB11", "PR19", "PR31"]);
        }

        this.updateRestrictions = (insuranceEncounterDetails: zotec.patientExperience.insurance.InsuranceProfileEncounterDetails) => {
            this.primaryInsuranceIsLocked = false;
            this.primaryInsuranceMessage = null;
            this.secondaryInsuranceIsLocked = false;
            this.allowAddInsurance = true;

            if (insuranceEncounterDetails == null) {
                return;
            }

            if (this.primaryHasProcessedClaim(insuranceEncounterDetails.remitReasonCode)) {
                this.primaryInsuranceIsLocked = true;
                this.primaryInsuranceMessage =
                    "Primary has processed your claim. The remaining balance is your financial responsibility, or you may provide updated secondary coverage.";
                
            } else if (insuranceEncounterDetails.billed === insuranceEncounterDetails.owed &&
                this.primaryHasIncorrectInformation(insuranceEncounterDetails.remitReasonCode)) {
                this.primaryInsuranceMessage =
                    "Your insurance claim could not be processed due to incorrect primary policy information. Please provide corrected primary coverage.";
                
            } else if (insuranceEncounterDetails.billed !== insuranceEncounterDetails.owed &&
                insuranceEncounterDetails.hasInsurancePaymentsOrAdjustments) {
                this.primaryInsuranceIsLocked = true;
                this.primaryInsuranceMessage =
                    "Primary has processed your claim. The remaining balance is your financial responsibility.";
            }

            if (insuranceEncounterDetails.filingCarrierBucket != 1 && 
                this.primaryHasIncorrectInformation(insuranceEncounterDetails.remitReasonCode)) {
                this.secondaryInsuranceIsLocked = true;
                this.allowAddInsurance = false;
            }
        }
    }]);