/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpTeleMedCharge", ["$serviceInfoService", ($serviceInfoService) => {
        return {
            templateUrl: "Templates/Controls/Directives/TeleMedCharge",
            scope: {
                tmcCharge: "=charge",
                index: "="
            }
        };
    }]);