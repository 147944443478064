/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.PrePay")
    .controller("zpPrePayController",
        ["$scope", "Restangular", "$zpModal", "$siteSettings", "$pdf", "$q", "$paymentFailureModal", "$loadPaymentModuleLibrary", "$patientExperienceSettings",
            function ($scope, Restangular: restangular.IService, $zpModal, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $pdf: zotec.patientExperience.Services.PdfService, $q: angular.IQService, $paymentFailureModal, $loadPaymentModuleLibrary, $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings) {

            this.LOADING_STATE = 0;
            this.NORMAL_STATE = 1;
            this.ERROR_STATE = 2;

            this.currentState = this.LOADING_STATE;

            this.PATIENT_PAGE = 0;
            this.METHOD_PAGE = 1;
            this.SUCCESS_PAGE = 2;
            this.pageCount = 3;

            this.currentPage = this.PATIENT_PAGE;

            this.isLoading = () => {
                return this.currentState == this.LOADING_STATE;
            }

            this.isInErrorState = () => {
                return this.currentState == this.ERROR_STATE;
            }

            this.isInNormalState = () => {
                return this.currentState == this.NORMAL_STATE;
            }

            this.shouldShowPatientPage = () => {
                return this.isInNormalState() && this.currentPage == this.PATIENT_PAGE;
            }

            this.shouldShowMethodPage = () => {
                return this.isInNormalState() && this.currentPage == this.METHOD_PAGE;
            }

            this.shouldShowSuccessPage = () => {
                return this.isInNormalState() && this.currentPage == this.SUCCESS_PAGE;
            }

            this.isInternalRequest = null as boolean;
            this.enableCreditCardFormWithBillingAddress = false as boolean;
            this.sessionData = null;
            this.paymentAmount = 0.00;
            this.minimumPaymentAmount = 0.00;
            this.availablePaymentMethods = [
                {
                    name: "Credit Card",
                    key: "cc"
                }
            ];

            this.goToErrorState = (logErrorMessage: string) => {
                if (!this.isInErrorState()) {
                    this.currentState = this.ERROR_STATE;
                    $paymentFailureModal();
                }
                this.logError(logErrorMessage);
                console.log(logErrorMessage);
                }

            this.logError = (message: string) => {
                $siteSettings.billingGroupRestangularized().all("PostProcess").all("PrePayLog").post({ logMessage: message, guarantorId: this.guarantorId, transactionId: this.transactionId, encryptedGuarantorId: this.encryptedGuarantorId });
            }

            let internalRequestPromise = Restangular.one("internalnetwork").get()
                .then((internalNetwork: zotec.patientExperience.internalNetwork.InternalRequest) => {
                    this.isInternalRequest = internalNetwork.isInternal;
                }, () => {
                    this.goToErrorState("Pre-pay was unable to determine whether IP Address was internal.")
                });

            let paymentModuleLibraryPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("Library").get()
                .then((response: string) => {
                    $loadPaymentModuleLibrary(response);
                }, () => {
                        this.goToErrorState("Pre-pay was unable to load the Payment Module.")
                });

            let sessionDataPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("SessionData").get()
                .then((data: string) => {
                    this.sessionData = data
                }, () => {
                    this.goToErrorState("Pre-pay was unable to load the Payment Module.")
                });

            let paymentOptionsPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    if (info.acceptElectronicCheck) {
                        this.availablePaymentMethods.push({
                            name: "Electronic Check",
                            key: "echk"
                        });
                    }
                    this.minimumPaymentAmount = info.minPaymentAmount;
                    this.paymentAmount = info.minPaymentAmount;
                }, () => {
                    this.goToErrorState("Pre-pay was unable to retrieve client info.")
                });

            let generalLocationPromise = $siteSettings.billingGroupRestangularized().all("prepay").one("GeneralLocation").get({ clientId: $siteSettings.clientId })
                .then((locationId: string) => {
                    this.generalLocationId = locationId;
            }, () => {
                this.goToErrorState("Pre-pay was unable to retrieve general location for client.")
            });

            let enableCreditCardFormWithBillingAddressTogglePromise = Restangular.one("ApplicationFeatures").get({
                featureName: $patientExperienceSettings.features.enableCreditCardFormWithBillingAddress,
                featureVersion: "1.0.0"
                }).then((response: string) => {
                    this.enableCreditCardFormWithBillingAddress = response === 'True';
                }, () => {
                    this.enableCreditCardFormWithBillingAddress = false;
                });

            $q.all([internalRequestPromise, paymentOptionsPromise, generalLocationPromise, enableCreditCardFormWithBillingAddressTogglePromise, paymentModuleLibraryPromise, sessionDataPromise]).finally(() => {
                if (!this.isInErrorState()) {
                    this.currentState = this.NORMAL_STATE;
                }
            });

            this.selectedPaymentMethod = "";

            this.locationPage = {
                items: [] as zotec.patientExperience.prePay.PrePayLocation[],
                total: 0
            } as zotec.patientExperience.Page<zotec.patientExperience.prePay.PrePayLocation>;
            this.locationOfService = (null as zotec.patientExperience.prePay.PrePayLocation);

            this.getLocations = (searchTerm, select) => {
                $siteSettings.billingGroupRestangularized().all("prepay").one("locations").get({ filter: searchTerm, page: 1 })
                    .then((locationPage: zotec.patientExperience.Page<zotec.patientExperience.prePay.PrePayLocation>) => {
                        this.locationPage = locationPage;
                        select.refreshItems();
                    }, () => {
                        this.goToErrorState("Pre-pay was unable to retrieve locations for procedure.");
                    });
            }

            this.transactionId = null as string;
            this.acceptedCreditCards = [];

            this.getPatientGuarantorAndLoadPaymentInfo = () => {
                this.currentState = this.LOADING_STATE;
                this.emailAddress = null == this.emailAddress ? "" : this.emailAddress;

                $siteSettings.billingGroupRestangularized().all("prepay").one("guarantor")
                    .get({ firstName: this.firstName, lastName: this.lastName, dateOfBirth: this.dateOfBirth, streetAddress: this.address, zipCode: this.zipCode, email: this.emailAddress })
                    .then((response: zotec.patientExperience.prePay.PrePayGuarantor) => {
                        this.guarantorId = response.guarantorId;
                        this.encryptedGuarantorId = response.encryptedGuarantorId;
                        this.partialUrl = response.partialUrl;

                        this.currentPage = this.METHOD_PAGE;
                        this.currentState = this.NORMAL_STATE;
                    }, () => {
                        this.goToErrorState("Pre-pay was unable to find or create guarantor id for patient information.");
                    });
            }

            this.onPaymentMethodChanged = (form) => {
                document.getElementById("payment-module-credit-card-container").style.display = 'none';
                document.getElementById("payment-module-electronic-check-container").style.display = 'none';

                var paymentModuleApi: zotec.patientExperience.payments.IPaymentModuleApiV3 = window["PaymentModule"];
                switch (this.selectedPaymentMethod) {
                    case "cc":
                        document.getElementById("payment-module-credit-card-container").style.display = '';
                        const ccForm = paymentModuleApi.InitCreditCardForm("payment-module-credit-card-container", { includeSwipe: false, includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.configurePaymentModuleForm(ccForm);
                        this.ccFormWatchUnregister = $scope.$watch(() => form.$valid, (newVal, oldVal) => {
                            if (newVal != undefined && newVal != oldVal) {
                                if (newVal) {
                                    ccForm.Enable();
                                } else {
                                    ccForm.Disable();
                                }
                            }
                        });
                        break;
                    case "echk":
                        document.getElementById("payment-module-electronic-check-container").style.display = '';
                        const ecForm = paymentModuleApi.InitECheckForm("payment-module-electronic-check-container", { includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.configurePaymentModuleForm(ecForm);
                        this.ecFormWatchUnregister = $scope.$watch(() => form.$valid, (newVal, oldVal) => {
                            if (newVal != undefined && newVal != oldVal) {
                                if (newVal) {
                                    ecForm.Enable();
                                } else {
                                    ecForm.Disable();
                                }
                            }
                        });
                        break;
                    default:
                        break;
                }
            }

            this.configurePaymentModuleForm = (pmForm: zotec.patientExperience.payments.IPaymentModuleForm) => {
                pmForm.OnSuccess((data) => this.handlePaymentCompletion(data))
                    .WithCharges([{
                        id: 0,
                        discount: 0,
                        discountCode: "",
                        isCredit: false,
                        paidInFull: false,
                        paymentAmount: this.paymentAmount,
                        originalAmount: 0,
                    }])
                    .IncludePayment({ paymentAmount: this.paymentAmount })
                    .WithChargeType("PatientPrePay")
                    .WithAppName("PXP")
                    .WithWebHook(`${window.location.origin}/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/PostProcess/${this.partialUrl}`, this.sessionData)
                    .WithDateOfService(this.dateOfService)
                    .ConfigurePatientInternal({
                        dataSourceId: $siteSettings.dataSourceId,
                        clientId: $siteSettings.clientId,
                        locationId: this.locationOfService && this.locationOfService.id || this.generalLocationId,
                        billingGroupId: $siteSettings.billingGroupId,
                        entityId: "",
                        guarantorId: this.guarantorId,
                        encryptedGuarantorId: this.encryptedGuarantorId,
                        patientId: 0,
                    })
                    .OnVerificationRequest(() => {
                        let response = $.ajax({
                            url: $siteSettings.billingGroupRestangularized().all("PaymentModule").one("AccessToken").getRestangularUrl(),
                            async: false,
                            cache: false,
                        }).responseText;
                        return JSON.parse(response);
                    });
            }

            this.handlePaymentCompletion = (response: zotec.patientExperience.payments.WebHookResponse) => {
                this.sendSuccessMessageToPaymentModule();
                $scope.$digest();
                var paymentId = parseInt(response.webhookResponse);
                if (paymentId > 0) {
                    $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/download", { paymentId: parseInt(response.webhookResponse), isPrePay: true, isTOSReceipt: false }, "Generating receipt...",
                        (success: boolean) => {
                            if (success) {
                                this.currentPage = this.SUCCESS_PAGE;
                            } else {
                                this.receiptIssueModal();
                            }
                        });
                } else {
                    this.receiptIssueModal();
                }
            }

            this.receiptIssueModal = () => {
                $zpModal("Error", "The transaction completed successfully, but there was an error generating the receipt.", () => {
                    this.currentPage = this.SUCCESS_PAGE;
                });
            }

            this.sendSuccessMessageToPaymentModule = () => {
                if (this.eCheckFormHandle) {
                    this.eCheckFormHandle.dispose();
                }
                if (this.creditCardFormHandle) {
                    this.creditCardFormHandle.dispose();
                }
            }
        }
    ]);

interface PrePayResponse {
    success: boolean;
    cvvRequired: boolean;
    paymentId: string;
}