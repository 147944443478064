/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Welcome")
    .directive("zpWelcome", () => {
        return {
            restrict: "AE",
            controller: "zpWelcomeController",
            controllerAs: "zpWelcome",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Welcome/zpWelcome"
        }
    });