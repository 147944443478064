/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpBanner", ["$siteSettings", "$sessionStorage", ($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $sessionStorage) => {
        return {
            replace: true,
            templateUrl: "Templates/Controls/Directives/Banner",
            link: (scope: BannerScope, element: JQuery, attributes: angular.IAttributes) => {
                if ($siteSettings.dataSourceId == null || $siteSettings.clientId == null || $siteSettings.billingGroupId == null) {
                    scope.hasCustomBanner = false;
                } else {
                    scope.hasCustomBanner = true;
                    if ($sessionStorage.chargeId == null || $sessionStorage.chargeId === 0) {
                        scope.src = `/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/images/banner`;
                    } else {
                        scope.src = `/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/images/banner/${$sessionStorage.chargeId}`;
                    }
                }
            }
        };
    }]);

interface BannerScope extends angular.IScope {
    src: string;
    hasCustomBanner: boolean;
}