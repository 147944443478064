/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../restangular/restangular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Document")
    .controller("zpDocumentController", ["Restangular", "$state", function (Restangular: restangular.IService, $state) {

        this.document = (null as Document);

        Restangular.all("documents").one($state.params.type).get()
            .then((document: Document) => {
                this.document = document;
            }, () => {
                console.log("error getting document");
            });

    }]);
