/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience")
    .directive("zpApp", () => ({
        restrict: "AE",
        controller : "zpAppController",
        controllerAs : "zpApp",
        bindToController : true,
        replace : true,
        templateUrl : "Templates/PatientExperience/zpApp"
    }))