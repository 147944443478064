/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Document", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.terms", {
            url: "/terms",
            template: "<zp-document></zp-document>",
            params: {
                type: "terms"
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("TermsOfUse", entity);
                }]
            }
        });
        $stateProvider.state("entity.questTerms", {
            url: "/QuestTermsOfUse",
            template: "<zp-document></zp-document>",
            params: {
                type: "questTerms"
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("TermsOfUse", entity);
                }]
            }
        });
        $stateProvider.state("entity.privacy", {
            url: "/privacy",
            template: "<zp-document></zp-document>",
            params: {
                type: "privacy"
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("PrivacyPolicy", entity);
                }]
            }
        });
    }]);