/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceCharges")
    .controller("zpInsuranceChargesController", ["Restangular", "$state", "$guarantor", "$updateInsurance", "$siteSettings",
    function (Restangular: restangular.IService, $state, $guarantor: zotec.patientExperience.Services.GuarantorService, $updateInsurance: zotec.patientExperience.Services.UpdateInsuranceService, $siteSettings) {
        this.loadingCharges = true;

        this.allChecked = true;

        this.updateError = (null as string);

        this.insurancePolicy = $state.params.insurancePolicy as zotec.patientExperience.insurance.InsurancePolicy;

        if (this.insurancePolicy == null) {
            $state.go("entity.insurance");
        }

        this.chargeList = [] as zotec.patientExperience.guarantorCommunication.Charge[];

        $guarantor.restangularized().all("charges").one("balance").get({ profileId: this.insurancePolicy.profileId }).then((balance: zotec.patientExperience.guarantorCommunication.GuarantorBalance) => {
            let openCharges = [];
            _.forEach(balance.openCharges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                openCharges.push(charge);
            });

            let pendingCharges = [];
            _.forEach(balance.pendingChargesByPatients, (patientCharges: zotec.patientExperience.guarantorCommunication.PatientCharges) => {
                _.forEach(patientCharges.charges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                    pendingCharges.push(charge);
                });
            });

            if (openCharges.length as number === 0) {
                this.loadingCharges = false;
                this.save();
                return;
            }
            _.forEach(openCharges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                charge.included = true;
            });

            _.forEach(pendingCharges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                charge.included = true;
            });
            this.chargeList = _.sortBy(_.filter(openCharges.concat(pendingCharges), (charge: zotec.patientExperience.guarantorCommunication.Charge) => { return charge.chargeId > 0 }), ["activityDate"]);
            this.loadingCharges = false;
        }, (response) => {
            this.loadingCharges = false;
            console.log("there was an error in the response", response);
        });

        this.checkedAll = () => {
            _.forEach(this.chargeList, (charge: zotec.patientExperience.guarantorCommunication.Charge) => { charge.included = this.allChecked; });
        };

        this.save = () => {
            this.insurancePolicy.chargesToUpdate = _.filter(this.chargeList, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                return charge.included;
            }).map((charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                 return charge.chargeId;
            });

            $updateInsurance.update(this.insurancePolicy, null);
        }
    }]);
