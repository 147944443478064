/// <reference path="../../../../.../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Models")
    .factory("AuthenticationModel", ["Restangular", "$guarantor", "$q", "$siteTimeoutService",
        (Restangular: restangular.IService, $guarantor: zotec.patientExperience.Services.GuarantorService, $q: angular.IQService, $siteTimeoutService: zotec.patientExperience.Services.SiteTimeoutService) => {
        return {
            tryAuthenticate: (event: string, details: zotec.patientExperience.SiteUrlDetails) => {
                if (details === null || details.dataSourceId === null || details.clientId === null) {
                    return $q.when();
                }
                var deferred = $q.defer();
                Restangular.one("authentication").get({ eventName: event, dataSourceId: details.dataSourceId, clientId: details.clientId, billingGroupId: details.billingGroupId })
                    .then((auth: zotec.patientExperience.authentication.AuthenticationResult) => {
                        if (auth.authenticated) {
                            $guarantor.logIn(auth.person);
                            $siteTimeoutService.SetSiteTimeout(details.sessionExpirationInMinutes);
                        } else {
                            $guarantor.isLoggedIn = false;
                        }
                        deferred.resolve();
                    }, () => {
                        console.log("error in tryAuthenticate");
                        deferred.resolve();
                    });
                return deferred.promise;
            },
            authenticate: (event: string, details: zotec.patientExperience.SiteUrlDetails) => {
                if (details == null) {
                    return $q.reject();
                }
                var deferred = $q.defer();
                Restangular.one("authentication").get({ eventName: event, dataSourceId: details.dataSourceId, clientId: details.clientId, billingGroupId: details.billingGroupId })
                    .then((auth: zotec.patientExperience.authentication.AuthenticationResult) => {
                        if (auth.authenticated) {
                            $guarantor.logIn(auth.person);
                            $siteTimeoutService.SetSiteTimeout(details.sessionExpirationInMinutes);
                            deferred.resolve();
                        } else {
                            $guarantor.isLoggedIn = false;
                            deferred.reject();
                        }
                    }, () => {
                        console.log("error in authenticate");
                        deferred.reject();
                    });
                return deferred.promise;
            }
        } as zotec.patientExperience.resolvers.AuthenticationModel;
    }])