/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Entity")
    .directive("zpEntity", () => {
        return {
            restrict: "E",
            controller: "zpEntityController",
            controllerAs: "zpEntity",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Entity/zpEntity"
        }
    });