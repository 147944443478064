/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Welcome")
    .controller("zpWelcomeController", ["$state", "$siteSettings", "$guarantor", "$staticModal", "$pdf", "$http", "$sessionStorage",
        function ($state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $staticModal, $pdf: zotec.patientExperience.Services.PdfService, $http: angular.IHttpService, $sessionStorage) {

            this.name = $guarantor.firstName;

            this.destination = $guarantor.destination || $sessionStorage.destination || "";

            this.chargeId = $sessionStorage.chargeId;

            $sessionStorage.chargeId = null;

            this.screen = (null as zotec.patientExperience.welcome.AccountScenario);
            if (this.destination === "TOSReceipt") {
                const screen1: zotec.patientExperience.welcome.AccountScenario = {
                    scenarioText: "Thank you for your recent payment. If you'd like to view your receipt, click the button below.",
                    primaryAction: "TOSReceipt",
                    primaryTitle: "View receipt",
                    secondaryAction: null,
                    secondaryTitle: null
                };
                this.screen = screen1;
            } else {
                $guarantor.restangularized().one("welcome")
                    .get()
                    .then((screen: zotec.patientExperience.welcome.AccountScenario) => {
                        if (screen != null) {
                            this.screen = screen;
                        } else {
                            this.createFallbackScreen();
                        }
                    },
                    (response) => {
                        console.log("error getting screens: ", response.status);
                        this.createFallbackScreen();
                    });
            }

            this.createFallbackScreen = () => {
                const screen2: zotec.patientExperience.welcome.AccountScenario = {
                    scenarioText: "MyDocBill is the home for all of your medical billing information.",
                    primaryAction: "PayBill",
                    primaryTitle: "Pay my bill",
                    secondaryAction: "UpdateInsurance",
                    secondaryTitle: "Update my insurance"
                };
                this.screen = screen2;
            }

            this.goToPrimaryDestination = () => {
                this.goToDestination(this.screen.primaryAction);
            };

            this.goToSecondaryDestination = () => {
                this.goToDestination(this.screen.secondaryAction);
            };

            this.goToDestination = (destination: string) => {
                switch (destination) {
                    case "PayBill":
                        $state.go("entity.bill-pay");
                        break;
                    case "UpdateInsurance":
                        $state.go("entity.insurance");
                        break;
                    case "TOSReceipt":
                        this.showTOSReceipt();
                        break;
                    default:
                        $state.go("entity.insurance");
                        break;
                }
            }

            this.showTOSReceipt = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/tos", { paymentId: this.chargeId }, "Generating receipt...", null);
            }

    }]);