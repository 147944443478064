/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../js/models.d.ts" />
/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../lodash/lodash.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").component("zpGoogleAnalytics",
    {
        template: '',
        controllerAs: "zpGoogleAnalytics",
        controller: ["Restangular", "$q", "$timeout", "$window", "$rootScope",
            function (Restangular: restangular.IService, $q: angular.IQService, $timeout: angular.ITimeoutService, $window: any, $rootScope) {
                this.googleID = '';
                $rootScope.$on("$stateChangeSuccess", (event, toState, toParams, fromState, fromParams) => {
                    if ($window.gtag) {
                        sendToGA($window, this.googleID, toState);
                    }
                });

                this.loadScript = (url: string, googleAnalyticsID: string) => {
                    let node = document.createElement('script'); // creates the script tag
                    node.src = url; // sets the source (insert url in between quotes)
                    node.type = 'text/javascript'; // set the script type
                    //node.async = true; // makes script run asynchronously
                    node.charset = 'utf-8';
                    node.onload = () => {
                        sendToGA($window, googleAnalyticsID, '');
                    };
                    // append to head of document
                    document.getElementsByTagName('head')[0].appendChild(node);
                }

                var deferred = $q.defer();
                Restangular.one("AppSettings").one("google").get({ appName: "PXP" })
                    .then((settings: zotec.patientExperience.AppSettings.GoogleAnalyticsSettings) => {
                        var me = this.loadScript;
                        this.googleID = settings.googleAnalyticsID;
                        me(settings.googleAnalyticsUrl, settings.googleAnalyticsID);
                        deferred.resolve();
                    }, () => {
                        console.log("error in get appsettings");
                        deferred.resolve();
                    });
            }]
    });

function sendToGA($window: any, googleAnalyticsID: string, toState: any) {
    $window.dataLayer = $window.dataLayer || [];
    $window.gtag = function () {
        $window.dataLayer.push(arguments);
    };
    $window.gtag('js', new Date());
    $window.gtag('config', googleAnalyticsID, { 'page_path': toState.url });
}