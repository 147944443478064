/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceEdit")
    .controller("zpInsuranceEditController", ["Restangular", "$q", "$staticModal", "$promptModal", "$scope", "$state", "$siteSettings", "$guarantor", "$timeout", "$updateInsurance",
        function (Restangular: restangular.IService, $q: angular.IQService, $staticModal, $promptModal, $scope, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $timeout, $updateInsurance: zotec.patientExperience.Services.UpdateInsuranceService) {

            this.isGuarantorBillingClient = $siteSettings.isGuarantorBillingClient;

            if ($state.params.policy == null) {
                if (this.isGuarantorBillingClient) {
                    $state.go("entity.account-history");
                }
                else {
                    $state.go("entity.insurance");
                }
                return;
            }

            this.encounterDetails = ($state.params.encounterDetails as zotec.patientExperience.insurance.EncounterDetails);

            if (this.isGuarantorBillingClient && this.encounterDetails == null) {
                $state.go("entity.account-history");
                return;
            }

            this.insurancePolicy = ($state.params.policy as zotec.patientExperience.insurance.InsurancePolicy);

            this.matchedCarrier = (null as zotec.patientExperience.insurance.IInsuranceCarrier);
            this.existingCarrier = (null as zotec.patientExperience.insurance.IInsuranceCarrier);

            this.error = (null as string);

            this.relationshipTypes = [] as zotec.patientExperience.insurance.RelationshipType[];
            this.relatedPeople = [] as zotec.patientExperience.insurance.RelatedPerson[];
            this.friendlyCarriers = [] as zotec.patientExperience.insurance.FriendlyCarrier[];
            this.relatedEncounters = null as zotec.patientExperience.insurance.RelatedEncounter[];

            this.foundCarriers = [];
            this.groupNames = [];

            this.showExtraCarrierFields = false;
            this.isAgent = false;
            this.isGuarantorBillingClient = false;

            this.policyNumberRegEx = (null as string);
            this.samplePolicyNumber = (null as string);
            this.groupNumberRequired = false;
            this.policyNumberValid = true;
            this.genericCarrierSelected = false;

            this.clientPhone = "";

            $guarantor.restangularized().one("access").one("isagent").get().then((access: zotec.patientExperience.authentication.Access) => {
                this.isAgent = access.isAgent;
            });

            $siteSettings.billingGroupRestangularized().one("clientinfo").get().then((clientinfo: zotec.patientExperience.client.ClientInfo) => {
                this.isGuarantorBillingClient = clientinfo.billingClientType === 2;
                this.clientPhone = clientinfo.phone || "";
            });

            $siteSettings.clientRestangularized().all("carriers").get(this.insurancePolicy.carrierId).then((carriers) => {
                this.existingCarrier = carriers[0];
                this.policyNumberChanged();
            });
            this.usingExistingCarrier = true;
            this.insurancePolicy.carrier = this.insurancePolicy.carrierName;


            var carrierQueryParams = {
                dataSourceId: $siteSettings.dataSourceId,
                billingGroupId: $siteSettings.billingGroupId,
                groupCode: this.encounterDetails != null ? this.encounterDetails.locationGroupCode : ""
            };

            this.friendlyCarriers = $siteSettings.clientRestangularized()
                .all("carriers")
                .getList(carrierQueryParams);

            this.getCarriers = (searchText: string) => {
                if (!(typeof (searchText) === "string") || searchText.length < 2) {
                    return $q.when([]);
                }

                var foundCarriers = [];

                var queryParams = {
                    carrierName: searchText,
                    profileId: this.insurancePolicy.profileId,
                    dataSourceId: $siteSettings.dataSourceId,
                    billingGroupId: $siteSettings.billingGroupId,
                    groupCode: this.encounterDetails != null ? this.encounterDetails.locationGroupCode : ""
                };

                return $siteSettings.clientRestangularized().all("carriers")
                    .getList(queryParams)
                    .then((carriers) => {
                        foundCarriers = carriers;
                        this.foundCarriers = foundCarriers;
                        
                        if (this.foundCarriers.filter(carrier => carrier.id === this.existingCarrier.id).length === 0 && this.searchMatchesExistingCarrier(searchText, this.existingCarrier.name)) {
                            this.foundCarriers.push(this.existingCarrier);
                        }

                        this.carrierChanged();

                        return this.friendlyCarriers
                            .$object
                            .concat(foundCarriers);
                    });
            };

            this.getDefaultCarrier = (carrierId: string) => {
                var defaultCarrier;
                $siteSettings.clientRestangularized().all("carriers").get(carrierId).then((carrier) => {
                    defaultCarrier = carrier;
                });
                return defaultCarrier;
            }

            Restangular.one("datasources", $siteSettings.dataSourceId)
                .all("relationshiptypes")
                .getList().then((relationshipTypes: [zotec.patientExperience.insurance.RelationshipType]) => {
                    for (let relType of relationshipTypes) {
                        if (relType.description === 'Self') {
                            this.relationshipTypes.splice(0, 0, relType);
                        }
                        else {
                            this.relationshipTypes.push(relType);
                        }
                    }
                }, (response) => {
                    console.log("error getting relationship types: ", response);
                });

            $guarantor.restangularized()
                .all("relatedpeople").getList().then((relatedPeople: [zotec.patientExperience.insurance.RelatedPerson]) => {
                    this.relatedPeople.push({
                        personId: 0,
                        name: "Other"
                    });
                    for (let r of relatedPeople) {
                        this.relatedPeople.push(r);
                        if (this.insurancePolicy.policyOwnerId === r.personId) {
                            this.$policyOwner = r;
                        }
                    }
                }, (response) => {
                    console.log("error getting related people: ", response);
                });

            this.searchMatchesExistingCarrier = (searchText: string, carrierName: string) => {
                var regex = new RegExp(".*" + searchText.toUpperCase() + ".*");
                return regex.test(carrierName.toUpperCase());
            } 

            this.policyOwnerChanged = () => {
                this.insurancePolicy.policyOwner.firstName = null;
                this.insurancePolicy.policyOwner.middleInitial = null;
                this.insurancePolicy.policyOwner.lastName = null;
                this.insurancePolicy.policyOwner.address1 = null;
                this.insurancePolicy.policyOwner.address2 = null;
                this.insurancePolicy.policyOwner.zip = null;
                this.insurancePolicy.policyOwner.city = null;
                this.insurancePolicy.policyOwner.state = null;
            }

            this.policyOwnerZipChanged = () => {
                if (this.insurancePolicy.policyOwner.zip.length < 5) {
                    this.insurancePolicy.policyOwner.state = "";
                    this.insurancePolicy.policyOwner.city = "";
                    return;
                } else if (this.insurancePolicy.policyOwner.zip.length > 5) {
                    return;
                }
                const firstFive = this.insurancePolicy.policyOwner.zip.substring(0, 5);
                Restangular.one("datasources", $siteSettings.dataSourceId)
                    .one("zipcodes", firstFive)
                    .get().then((zipCode: zotec.patientExperience.insurance.ZipCode) => {
                        if (zipCode == null) {
                            return;
                        }
                        if (zipCode.zip !== firstFive) {
                            return;
                        }
                        this.insurancePolicy.policyOwner.state = zipCode.state;
                        this.insurancePolicy.policyOwner.city = zipCode.city;
                    },
                    (response) => {
                        console.log("error getting zip code: ", response);
                    });
            }

            this.cancel = () => {
                $state.go("entity.insurance", { encounterDetails: this.encounterDetails });
            }

            this.deleteInsurance = () => {
                this.error = null;
                $promptModal(null, "Are you sure you want to remove this insurance policy from our records?", "Remove Policy", "Go back", () => {
                    var modal = $staticModal("Deleting...", () => {
                        var failed = false;
                        const deleteInsuranceRequest = this.isGuarantorBillingClient
                            ? $guarantor.restangularized()
                            .one("patients", this.insurancePolicy.personId)
                            .one("insurance")
                            .one("encounter", this.encounterDetails.patientEncounterNumber)
                            .one("profileType", this.insurancePolicy.profileId)
                            .one("policy", this.insurancePolicy.id)
                            .remove({ chargeIds: this.encounterDetails.chargeIds })
                            : $guarantor.restangularized()
                            .one("patients", this.insurancePolicy.personId)
                            .one("insurance")
                            .one("profileType", this.insurancePolicy.profileId)
                            .one("policy", this.insurancePolicy.id)
                            .remove();
                        deleteInsuranceRequest.then(null, () => {
                            failed = true;
                        });
                        $timeout(() => {
                            modal.hide();
                            if (failed) {
                                this.updateError = "There was an error deleting your insurance. Please try again.";
                            } else {
                                $state.go("entity.insurance", { pending: true, encounterDetails: this.encounterDetails });
                            }
                        }, 3000);
                    });
                }, true);
            }

            this.invalidForSubmission = (insuranceForm) => {
                return insuranceForm.$invalid || !this.carrierValid() || (this.isGuarantorBillingClient && !insuranceForm.$dirty);
            }

            this.next = (policyIsDirty: boolean) => {
                this.error = null;
                var usingExistingCarrier = this.insurancePolicy.carrier == this.insurancePolicy.carrierName;
                this.insurancePolicy.isGenericCarrier = this.genericCarrierSelected || this.matchedCarrier == null;

                var clonedPolicy: zotec.patientExperience.insurance.InsurancePolicy = { ...this.insurancePolicy };
                var clonedPolicyOwner: zotec.patientExperience.insurance.PolicyOwner = { ...this.insurancePolicy.policyOwner };
                clonedPolicy.policyOwner = clonedPolicyOwner;
                if (this.matchedCarrier == null && !usingExistingCarrier) {
                    // Use the values typed by the user
                    clonedPolicy.carrierName = this.insurancePolicy.carrier;
                    clonedPolicy.carrierId = null;
                    if (this.isGuarantorBillingClient) {
                        var groupCode = this.encounterDetails.locationGroupCode;
                        var threepf = this.getThreePF(groupCode);
                        clonedPolicy.carrierId = groupCode + threepf;
                        clonedPolicy.carrier = this.getDefaultCarrier(this.insurancePolicy.carrierId);
                    }
                }
                else if (!usingExistingCarrier) {
                    // Use the values of the selected carrier
                    clonedPolicy.carrierId = this.matchedCarrier.id;
                    clonedPolicy.carrier = this.matchedCarrier;
                    if (this.genericCarrierSelected) {
                        clonedPolicy.carrierName = this.insurancePolicy.genericCarrierName;
                    }
                    else {
                        clonedPolicy.carrierName = this.matchedCarrier.name;
                        clonedPolicy.address = null;
                        clonedPolicy.zipCode = null;
                    }
                }

                if (policyIsDirty) {
                    if (this.isGuarantorBillingClient) {
                        clonedPolicy.chargesToUpdate = this.encounterDetails.chargeIds;
                        $guarantor.restangularized().one("patients", this.insurancePolicy.personId).one("insurance").one("insuranceBucket", this.insurancePolicy.id).one("relatedEncounters", this.encounterDetails.patientEncounterNumber).getList().then((relatedEncounters: zotec.patientExperience.insurance.RelatedEncounter[]) => {
                            this.relatedEncounters = relatedEncounters.filter(encounter => encounter.charges.some((charge) => charge.filingProfileId != "SP"));
                        }).then(() => {
                            if (this.relatedEncounters.length > 1) {
                                $state.go("entity.insurance-encounters", { encounterDetails: this.encounterDetails, insurancePolicy: clonedPolicy, relatedEncounters: this.relatedEncounters });
                            }
                            else {
                                $updateInsurance.update(clonedPolicy, this.encounterDetails);
                            }
                        });
                    } else {
                        $state.go("entity.insurance-charges", {
                            insurancePolicy: clonedPolicy
                        });
                    }
                } else if (!this.insurancePolicy.verified && !this.isGuarantorBillingClient) {
                    let modal = new $staticModal("Verifying...", () => {
                        $guarantor.restangularized()
                            .one("patients", this.insurancePolicy.personId)
                            .all("insurance")
                            .all("verify")
                            .post({ policyType: this.insurancePolicy.id, profileId: this.insurancePolicy.profileId })
                            .then((success: boolean) => {
                                modal.hide();
                                if (success) {
                                    $state.go("entity.insurance", { pending: true });
                                } else {
                                    this.error = "There was an error verifying your insurance. Please try again";
                                    console.log("the insurance could not be verified");
                                }
                            }, (response) => {
                                modal.hide();
                                this.error = "There was an error verifying your insurance. Please try again";
                                console.log("there was an error verifying the insurance: ", response);
                            });
                    });
                } else {
                    $state.go("entity.insurance", { encounterDetails: this.encounterDetails });
                }
            };

            this.findCarrierMatch = () => {
                const carrier = this.insurancePolicy.carrier;
                if (carrier == null) {
                    return null;
                }
                if (typeof carrier === "string") {
                    return _.find(this.foundCarriers, (c: zotec.patientExperience.insurance.IInsuranceCarrier) => {
                        return c.name.toUpperCase() === this.insurancePolicy.carrier.toUpperCase();
                    });
                }
                return carrier;
            }

            this.carrierChanged = () => {
                this.showExtraCarrierFields = false;
                this.policyNumberRegEx = null;
                this.samplePolicyNumber = null;
                this.groupNumberRequired = false;
                this.genericCarrierSelected = false;
                this.usingExistingCarrier = this.insurancePolicy.carrier == this.insurancePolicy.carrierName;
                this.matchedCarrier = this.findCarrierMatch();
                if (this.matchedCarrier == null && (this.insurancePolicy.carrier != this.insurancePolicy.carrierName && !this.isGuarantorBillingClient)) {
                    this.showExtraCarrierFields = true;
                    return;
                }
                else if (this.matchedCarrier != null && this.matchedCarrier.isGenericCarrier && !this.isGuarantorBillingClient) {
                    this.showExtraCarrierFields = true;
                    this.genericCarrierSelected = true;
                    return;
                }

                if (this.matchedCarrier != null) {
                    this.policyNumberRegEx = this.matchedCarrier.policyNumberRegEx;
                    this.samplePolicyNumber = this.matchedCarrier.samplePolicyNumber;
                    this.groupNumberRequired = this.matchedCarrier.groupNumberRequired;
                }

                this.policyNumberChanged();
            }

            this.carrierValid = () => {
                return (!!this.matchedCarrier || !this.isGuarantorBillingClient || this.usingExistingCarrier);
            };

            this.showCarrierNotFound = () => {
                return !this.matchedCarrier && this.insurancePolicy.carrier && this.isGuarantorBillingClient && !this.usingExistingCarrier;
            };

            this.policyNumberChanged = () => {
                if (this.policyNumberRegEx == null) {
                    this.policyNumberValid = true;
                    return;
                }
                const regex = new RegExp(this.policyNumberRegEx);
                this.policyNumberValid = regex.test(this.insurancePolicy.policyNumber);
            }

            this.getThreePF = (groupCode: string) => {
                switch (groupCode) {
                    case "HOU":
                        return "899";
                    case "LEX":
                        return "BBT";
                    case "STL":
                        return "2EE";
                    default:
                        return "399";
                }
            }

        }]);
