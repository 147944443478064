/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.PaymentPlan", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.payment-plan", {
            url: "/payment-plan",
            template: "<zp-payment-plan></zp-payment-plan>",
            params: {
                creditCard: null,
                electronicCheck: null,
                selectedPaymentMethod: null,
                guarantorBalance: 0,
                pmCharges: []
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Payment Plan", entity);
                }]
            }
        });
    }]);