/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.EbcPassThrough")
    .controller("zpEbcPassThroughController", ["Restangular", "$state", "$guarantor", "$window", "$sessionStorage",
        (Restangular: restangular.IService, $state, $guarantor: zotec.patientExperience.Services.GuarantorService, $window, $sessionStorage) => {

            $sessionStorage.$reset();

            if ($state.params.t != null) {
                Restangular.one("EbcUpdateInsurance").one("Validate").get({ token: $state.params.t }).then((person: zotec.patientExperience.authentication.Person) => {
                    if (person != null) {
                        $guarantor.guarantorId = person.personId;
                        $guarantor.logIn(person);

                        $sessionStorage.guarantorId = person.personId;
                        $sessionStorage.fromDirectLink = false;
                        $sessionStorage.clientId = person.clientId;
                        $sessionStorage.dataSourceId = person.dataSourceId;
                        $sessionStorage.hidePaperlessSettingsPrompt = true;

                        $sessionStorage.$apply();

                        $window.location.href = $window.location.protocol + "//" + person.sites[0].url + '/home';
                        return;
                    }
                }, () => {
                    $window.location.href = "global";
                });
            } else {
                $window.location.href = "global";
            }

        }
    ]);
