/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedBillPay")
    .directive("zpTeleMedBillPay", () => {
        return {
            restrict: "AE",
            controller: "zpTeleMedBillPayController",
            controllerAs: "zpTeleMedBillPay",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/TeleMedBillPay/zpTeleMedBillPay"
        }
    });