/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpSubtotalWithPatientPaymentsAndBalance", [() => {
        return {
            templateUrl: "Templates/Controls/Directives/SubtotalWithPatientPaymentsAndBalance",
            scope: {
                balance: "=",
                guarantorTotalPayments: "=",
                rollUpEnabled: "="
            }
        };
    }]);