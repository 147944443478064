/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpTheme", ["$siteSettings", "$guarantor", ($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService) => {
        return {
            replace: true,
            templateUrl: "Templates/Controls/Directives/Theme",
            link: (scope: ThemeScope, element: JQuery, attributes: angular.IAttributes) => {
                scope.color = $siteSettings.primaryColor || "#005BBB";

                scope.watch = scope.$watch(() => { return $siteSettings.primaryColor }, (newColor: string, oldColor: string) => {
                    scope.color = newColor || "#005BBB";
                });

                scope.$on("$destroy", () => {
                    scope.watch();
                });
            }
        };
    }]);

interface ThemeScope extends angular.IScope {
    color: string;
    watch: Function;
}