/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../js/models.d.ts" />
/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../lodash/lodash.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").component("zpChat",
    {
        template: '<h3 ng-if="!chatController.templateLoaded"><i class="fa fa-spinner fa-spin"></i> Loading... <input type="text" ng-model="chatController.creditCard.name" class="hidden" required /></h3><div ng-include src="\'Templates/Controls/Components/Chat\'" onload="chatController.templateLoaded = true" ng-class="{\'chat-hide\': !zpChat.Enabled}"></div>',
        controllerAs: "zpChat",
        controller: ["Restangular", "$siteSettings", "$patientExperienceSettings", "$guarantor", "$window", "$q", "$timeout", "$state","$sessionStorage",
            function (Restangular: restangular.IService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
                $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings, $guarantor: zotec.patientExperience.Services.GuarantorService,
                $window: any, $q: angular.IQService, $timeout: angular.ITimeoutService, $state, $sessionStorage) {

                this.templateLoaded = false;
                this.guarantorInfo = {};
                this.embedded_svc = $window.embedded_svc;
                this.Enabled = false;

                this.accept = (embedded_svc: any) => {
                    embedded_svc.inviteAPI.inviteButton.acceptInvite();
                };
                this.reject = (embedded_svc: any) => {
                    embedded_svc.inviteAPI.inviteButton.rejectInvite();
                };

                this.onTimeout = (f, interval) => {
                    if ($siteSettings.isLoaded) {
                        f();
                    }
                    else if (interval < 8000) {
                        var self = this;
                        $timeout(() => { self.onTimeout(f, interval * 2); }, interval * 2);
                    }
                };

                this.initESW = (embedded_svc, chatSettings: zotec.patientExperience.AppSettings.ChatSettings) => {
                    var queryParams = {
                        featureName: $patientExperienceSettings.features.enableChatForClientsV1,
                        featureVersion: "1.0.0",
                        dataSourceId: $siteSettings.dataSourceId,
                        clientId: $siteSettings.clientId
                    };

                    Restangular.one("applicationfeatures")
                        .get(queryParams)
                        .then((featureStatus: any) => {

                            if (featureStatus === "False" || isTelepayPatientChat($state, $sessionStorage)) {
                                embedded_svc.settings.displayHelpButton = false;
                                return;
                            }

                            embedded_svc.settings.displayHelpButton = true; //Or false
                            embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
                            //embedded_svc.settings.smallCompanyLogoImgURL = ‘https://www.zotecpartners.com/favicon.ico’;
                            // embedded_svc.settings.defaultMinimizedText = 'Contact Us'; //(Defaults to Chat with an Expert)
                            embedded_svc.settings.disabledMinimizedText = 'Chat Agents Offline.  Please contact us at 317.705.5050'; //(Defaults to Agent Offline)

                            //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
                            //embedded_svc.settings.storageDomain = 'dev.patientexperience.com:44383'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

                            // Settings for Chat
                            //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
                            // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
                            // Returns a valid button ID.
                            //};

                            //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
                            //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)


                            embedded_svc.settings.enabledFeatures = ['LiveAgent'];
                            embedded_svc.settings.entryFeature = 'LiveAgent';

                            InitializePreChatValues(embedded_svc, $siteSettings, $guarantor);

                            embedded_svc.addEventHandler("onHelpButtonClick", function (data) {
                                //Look for the offline form to be rendered to prefill the fields
                                addHtmlListener($siteSettings, $guarantor);
                                fillPrechatInfo($guarantor, $siteSettings, embedded_svc, data, Restangular, $q);
                            });
                            embedded_svc.addEventHandler("onChatRequestSuccess", function (data) {
                                fillPrechatInfo($guarantor, $siteSettings, embedded_svc, data, Restangular, $q);
                            });
                            embedded_svc.addEventHandler("onInviteAccepted", function (data) {
                                fillPrechatInfo($guarantor, $siteSettings, embedded_svc, data, Restangular, $q);
                            });
                            embedded_svc.init(
                                chatSettings.embeddedService_SiteEndpointURL,
                                chatSettings.embeddedService_initURL,
                                chatSettings.embeddedService_SalesForceURL,
                                chatSettings.organizationId,
                                'Patient_Care_Agents',
                                {
                                    baseLiveAgentContentURL: chatSettings.baseLiveAgentContentURL,
                                    deploymentId: chatSettings.deploymentId,
                                    buttonId: chatSettings.buttonId,
                                    baseLiveAgentURL: chatSettings.baseLiveAgentURL,
                                    eswLiveAgentDevName: 'Patient_Care_Agents',
                                    isOfflineSupportEnabled: true
                                }
                            );
                            
                            this.Enabled = true;
                        });

                    $window.embedded_svc = embedded_svc;
                };
                var deferred = $q.defer();
                Restangular.one("AppSettings").one("chat").get({ appName: "PXP" })
                    .then((settings: zotec.patientExperience.AppSettings.ChatSettings) => {
                        if (!$window.embedded_svc) {
                            var me = this.initESW;
                            var s = document.createElement('script');

                            //testing if the request of the api works,
                            //in case work, map the corresponding values pd, need to add the others values to the table
                            //Dynamically pull the chat endpoint hostname  organization Id  deploymentId buttonId (values so that an app deployment isnt required                             
                            s.setAttribute('src', settings.embeddedService_AttributeURL);
                            s.onload = () => {
                                $timeout(() => {
                                    this.onTimeout(() => {
                                        me($window.embedded_svc, settings);
                                    }, 500);
                                }, 500);
                            };
                            document.body.appendChild(s);

                            deferred.resolve();

                            this.Enabled = true;
                        }
                        else {
                            $timeout(() => {
                                this.onTimeout(() => {
                                    this.initESW($window.embedded_svc, settings);
                                }, 500);
                            }, 500);
                        }

                    }, () => {
                        console.log("error in get appsettings");
                        deferred.resolve();
                    });
            }]
    });
function addHtmlListener($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService) {
    var observer = new MutationObserver(function (mutations: any) {
        let element: any = document.querySelector('#AccountNumber__c');
        if (element != null) {
            fillOfflineFields($siteSettings, $guarantor);
            observer.disconnect();
        }
    });
    const body = document.querySelector('body');
    observer.observe(body, { attributes: false, childList: true, characterData: false, subtree: true });
}

function fillOfflineFields($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService) {
    setValuesToHiddenInput('Database__c', $siteSettings.dataSourceId);
    setValuesToHiddenInput('ClientID__c', $siteSettings.clientId);

    if ($guarantor.guarantorId > 0) {
        $guarantor.restangularized()
            .one("information").get().then((guarantor) => {
                if (guarantor) {
                    var personId = guarantor.personId > 0 ? guarantor.personId.toString() : "";
                    setValuesToHiddenInput('AccountNumber__c', personId);
                }
            });
    }
}

function setValuesToHiddenInput(elementId: string, value: string) {
    let element: any = document.querySelector('#'+elementId);   

    if (element != null) {
        element.value = value;
        element.dispatchEvent(new Event('change',{'bubbles':!0}));
    }
}

function initializeChatLog(data: any): zotec.patientExperience.Chat.ChatLog {
    if (data && data.liveAgentSessionKey) {
        return {
            applicationID: 0,
            chatKey: data.liveAgentSessionKey,
            firstName: '',
            lastName: '',
            email: '',
            personID: null,
            clientID: '',
            dataSourceID: ''
        };
    }
    else {
        return {
            applicationID: 0,
            chatKey: '',
            firstName: '',
            lastName: '',
            email: '',
            personID: null,
            clientID: '',
            dataSourceID: ''
        };
    }
}

function InitializePreChatValues(embedded_svc: any, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService) {
    embedded_svc.settings.extraPrechatFormDetails = [{
        "label": "First Name",
        "transcriptFields": ["CTFirstName__c"],
        "displayToAgent": true
    }, {
        "label": "Last Name",
        "transcriptFields": ["CTLastName__c"],
        "displayToAgent": true
    }, {
        "label": "Database",
        "value": $siteSettings.dataSourceId,
        "transcriptFields": ["Database__c"],
        "displayToAgent": true
    }, {
        "label": "Client ID",
        "value": $siteSettings.clientId,
        "transcriptFields": ["Client_ID__c"],
        "displayToAgent": true
    }, {
        "label": "Patient Account #",
        "value": $guarantor.guarantorId,
        "transcriptFields": ["Account_Number__c"],
        "displayToAgent": true
    }, {
        "label": "Origin",
        "value": "Chat",
        "displayToAgent": false
    }, {
        "label": "Patient Account Number",
        "value": $guarantor.guarantorId + "-" + $siteSettings.clientId,
        "displayToAgent": false
    }];
    embedded_svc.settings.extraPrechatInfo = [{
        "entityName": "Contact",
        "entityFieldMaps": [{
            "isExactMatch": true,
            "fieldName": "FirstName",
            "doCreate": false,
            "doFind": true,
            "label": "First Name"
        }, {
            "isExactMatch": true,
            "fieldName": "LastName",
            "doCreate": false,
            "doFind": true,
            "label": "Last Name"
        }, {
            "isExactMatch": true,
            "fieldName": "Email",
            "doCreate": false,
            "doFind": true,
            "label": "Email"
        }]
    }, {
        "entityName": "Case",
        "showOnCreate": true,
        "saveToTranscript": "CaseId",
        "entityFieldMaps": [{
            "isExactMatch": false,
            "fieldName": "Origin",
            "doCreate": true,
            "doFind": false,
            "label": "Origin"
        }, {
            "isExactMatch": false,
            "fieldName": "Database__c",
            "doCreate": true,
            "doFind": false,
            "label": "Database"
        }, {
            "isExactMatch": false,
            "fieldName": "Client_ID__c",
            "doCreate": true,
            "doFind": false,
            "label": "Client ID"
        }, {
            "isExactMatch": false,
            "fieldName": "SuppliedEmail",
            "doCreate": true,
            "doFind": false,
            "label": "Email"
        }, {
            "isExactMatch": false,
            "fieldName": "PatientAccountNumber__c",
            "doCreate": true,
            "doFind": false,
            "label": "Patient Account Number"
        }]
    }];
}

function isTelepayPatientChat($state, $sessionStorage) {
    return $state.is("entity.tele-med-account-history") ||
        $state.is("entity.tele-med-payment-plan") ||
        $state.is("entity.tele-med-bill-pay") || $sessionStorage.destination === 'TOSPayment';
}

function logChatInfo(logChat: zotec.patientExperience.Chat.ChatLog, $q: ng.IQService, Restangular: restangular.IService) {

    if (logChat.chatKey && logChat.chatKey.length > 0) {
        Restangular.all("Chat").post(logChat);
    }
}

function fillPrechatInfo($guarantor: zotec.patientExperience.Services.GuarantorService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, embedded_svc: any, data: any, Restangular: restangular.IService, $q: angular.IQService) {
    try {
        var logChat: zotec.patientExperience.Chat.ChatLog = initializeChatLog(data);

        logChat.dataSourceID = $siteSettings.dataSourceId;
        logChat.clientID = $siteSettings.clientId;

        if ($guarantor.guarantorId > 0) {
            $guarantor.restangularized()
                .one("information").get().then((guarantor) => {
                    if (guarantor) {
                        var email = guarantor.email;
                        var accNumFormatted;
                        var dataSourceId = $siteSettings.dataSourceId;
                        var clientId = $siteSettings.clientId;
                        var array = guarantor.name.toString().split(" ");
                        var firstName = array[0];
                        array.shift();
                        var lastName = array.join(" ");
                        var personId = guarantor.personId > 0 ? guarantor.personId.toString() : "";
                        if (guarantor.personId && $siteSettings.clientId) {
                            accNumFormatted = personId + "-" + clientId;
                        }

                        logChat.email = email;
                        logChat.firstName = firstName;
                        logChat.lastName = lastName;
                        logChat.personID = guarantor.personId;

                        logChatInfo(logChat, $q, Restangular);

                        embedded_svc.settings.prepopulatedPrechatFields = {
                            FirstName: firstName,
                            LastName: lastName
                        };
                    }

                    embedded_svc.settings.extraPrechatFormDetails = [{
                        "label": "First Name",
                        "value": firstName,
                        "transcriptFields": ["CTFirstName__c"],
                        "displayToAgent": true
                    }, {
                        "label": "Last Name",
                        "transcriptFields": ["CTLastName__c"],
                        "value": lastName,
                        "displayToAgent": true
                    }, {
                        "label": "Database",
                        "value": dataSourceId,
                        "transcriptFields": ["Database__c"],
                        "displayToAgent": true
                    }, {
                        "label": "Email",
                        "value": email,
                        "transcriptFields": ["Email__c"],
                        "displayToAgent": true
                    }, {
                        "label": "Client ID",
                        "value": clientId,
                        "transcriptFields": ["Client_ID__c"],
                        "displayToAgent": true
                    }, {
                        "label": "Patient Account #",
                        "value": personId,
                        "transcriptFields": ["Account_Number__c"],
                        "displayToAgent": true
                    }, {
                        "label": "Origin",
                        "value": "Chat",
                        "displayToAgent": false
                    }, {
                        "label": "Patient Account Number",
                        "value": accNumFormatted,
                        "displayToAgent": false
                    }];
                    embedded_svc.settings.prepopulatedPrechatFields = {
                        FirstName: firstName,
                        LastName: lastName
                    };
                    embedded_svc.settings.extraPrechatInfo = [{
                        "entityName": "Contact",
                        "entityFieldMaps": [{
                            "isExactMatch": true,
                            "fieldName": "FirstName",
                            "doCreate": false,
                            "doFind": true,
                            "label": "First Name"
                        }, {
                            "isExactMatch": true,
                            "fieldName": "LastName",
                            "doCreate": false,
                            "doFind": true,
                            "label": "Last Name"
                        }, {
                            "isExactMatch": true,
                            "fieldName": "Email",
                            "doCreate": false,
                            "doFind": true,
                            "label": "Email",
                            "value": email
                        }]
                    }, {
                        "entityName": "Case",
                        "showOnCreate": true,
                        "saveToTranscript": "CaseId",
                        "entityFieldMaps": [{
                            "isExactMatch": false,
                            "fieldName": "Origin",
                            "doCreate": true,
                            "doFind": false,
                            "label": "Origin"
                        }, {
                            "isExactMatch": false,
                            "fieldName": "Database__c",
                            "doCreate": true,
                            "doFind": false,
                            "label": "Database"
                        }, {
                            "isExactMatch": false,
                            "fieldName": "Client_ID__c",
                            "doCreate": true,
                            "doFind": false,
                            "label": "Client ID"
                        }, {
                            "isExactMatch": false,
                            "fieldName": "SuppliedEmail",
                            "doCreate": true,
                            "doFind": false,
                            "label": "Email",
                            "value": email
                        }, {
                            "isExactMatch": false,
                            "fieldName": "PatientAccountNumber__c",
                            "doCreate": true,
                            "doFind": false,
                            "label": "Patient Account Number"
                        }]
                    }];
                }, (response) => {
                    console.log("error getting related people: ", response);
                });
        }
        else {
            logChatInfo(logChat, $q, Restangular);
        }
    }
    catch (e) {
        console.log(e);
    }
    this.embedded_svc = embedded_svc;
}