/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpChargeGroup", ["$serviceInfoService", "$state", ($serviceInfoService, $state) => {
        return {
            templateUrl: "Templates/Controls/Directives/ChargeGroup",
            scope: {
                group: "=",
                index: "=",
                expanded: "=",
                hasInsuranceOnFile: "=",
                showEncounterHeader: "=",
                showInsuranceButton: "=",
                showIneligibleBanner: "=",
                rollUpFlagEnabled: "="
            },
            link: function (scope: ChargeGroupScope) {
                scope.clickServiceInfo = (charge : zotec.patientExperience.guarantorCommunication.Charge) => {
                    if (charge.chargeId === 0) {
                        return;
                    }
                    $serviceInfoService(charge.chargeId);
                }
                scope.updateInsurance = () => {
                    $state.go("entity.insurance", {
                        encounterDetails:
                        {
                            patientEncounterNumber: scope.group.charges[0].patientEncounterNumber,
                            chargeIds: scope.group.charges.map(c => c.chargeId),
                            patientId: scope.group.charges[0].patientId,
                            locationGroupCode: scope.group.charges[0].locationGroupCode,
                            date: scope.group.activityDate
                        }
                    });
                }
            }
        };
    }]);
interface ChargeGroupScope extends angular.IScope {
    group: zotec.patientExperience.guarantorCommunication.GroupedCharges;
}
