/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.GoodFaithEstimate", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.good-faith-estimate", {
            url: "/good-faith-estimate",
            template: "<zp-good-faith-estimate></zp-good-faith-estimate>",
            resolve: {
                auth: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("GoodFaithEstimate", entity);
                }]
            }
        });
    }]);