/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceCharges", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.insurance-charges", {
            url: "/insurance-charges",
            template: "<zp-insurance-charges></zp-insurance-charges>",
            params: {
                insurancePolicy: (null as zotec.patientExperience.insurance.InsurancePolicy)
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("InsuranceCharges", entity);
                }]
            }
        });
    }]);