/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../Content/file-saver/index.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$loggingService",
    ["$http", "$siteSettings",
        function ($http, $siteSettings: zotec.patientExperience.Services.SiteSettingsService) {
            return {
                logEvent: function log(message: string) {
                    let url = $siteSettings.billingGroupRestangularized().getRestangularUrl() + "/usagelog";
                    $http.post(url, { logMessage: message });
                }
            }
        }
    ]);