/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Thanks", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.thanks", {
            url: "/thanks",
            template: "<zp-thanks></zp-thanks>",
            params: {
                source: null
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("Thanks", entity);
                }]
            }
        });
    }]);