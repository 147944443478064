/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.BillPay")
    .directive("zpBillPay", () => {
        return {
            restrict: "AE",
            controller: "zpBillPayController",
            controllerAs: "zpBillPay",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/BillPay/zpBillPay"
        }
    });