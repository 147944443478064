/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpLogo", ["$siteSettings", "$guarantor", "$state", "$sessionStorage", ($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $state, $sessionStorage) => {
        return {
            replace: true,
            templateUrl: "Templates/Controls/Directives/Logo",
            link: (scope: LogoScope, element: JQuery, attributes: angular.IAttributes) => {
                scope.companyNameDisplay = $siteSettings.companyNameDisplay || "";

                scope.isTelepayPatientLogo = () => {
                    var result = $state.is("entity.tele-med-account-history") ||
                        $state.is("entity.tele-med-payment-plan") ||
                        $state.is("entity.tele-med-bill-pay") || $sessionStorage.destination === 'TOSPayment';
                    return result;
                }

                if ($siteSettings.dataSourceId == null || $siteSettings.clientId == null || $siteSettings.billingGroupId == null) {
                    scope.src = "/Content/zotec/images/logo_transparent.png";
                } else {
                    scope.src = `/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/images/logo`;
                }

                scope.$watchCollection(() => {
                    return [$siteSettings.clientId]
                }, function (newValues, oldValues) {
                    if (newValues[0] != null) {
                        scope.companyNameDisplay = $siteSettings.companyNameDisplay;
                        scope.src = `/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/images/logo`;
                    }
                });
                
                scope.$on("$destroy", () => {
                    scope.watch();
                });
            }
        };
    }]);

interface LogoScope extends angular.IScope {
    companyNameDisplay: string;
    src: string;
    watch: Function;
    isTelepayPatientLogo: Function;
}