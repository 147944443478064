/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedAccountHistory", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.tele-med-account-history", {
            url: "/tele-med-home",
            template: "<zp-tele-med-account-history></zp-tele-med-account-history>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Tele Med Account History", entity);
                }]
            }
        });
    }]);