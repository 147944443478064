/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpZip", () => {
        return {
            restrict: "A",
            require: "ngModel",
            link: (scope, element, attrs, ngModel: angular.INgModelController) => {
                var parser = (viewValue) => {
                    var stripped = (viewValue || "").replace("-", "");
                    ngModel.$setValidity("zip", stripped.length === 5 || stripped.length === 9);
                    if (!isNaN(stripped)) {
                        var result = stripped;
                        if (stripped.length > 9) {
                            ngModel.$viewValue = stripped.substring(0, 5) + "-" + stripped.substring(5, 9);
                            result = stripped.substring(0, 9);
                        } else if (stripped.length > 5) {
                            ngModel.$viewValue = stripped.substring(0, 5) + "-" + stripped.substring(5);
                            result = stripped;
                        } else {
                            ngModel.$viewValue = String(stripped);
                            result = stripped;
                        }
                        ngModel.$commitViewValue();
                        ngModel.$render();
                        return result;
                    } else {
                        return "";
                    }
                }
                ngModel.$parsers.push(parser);

                var formatter = (modelValue) => {
                    if (modelValue != null && !isNaN(modelValue)) {
                        if (modelValue.length > 5) {
                            return modelValue.substring(0, 5) + "-" + modelValue.substring(5);
                        } else {
                            return String(modelValue);
                        }
                    } else {
                        return "";
                    }
                }
                ngModel.$formatters.push(formatter);
            }
        }
    });