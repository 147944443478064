/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Survey")
    .controller("zpSurveyController", ["Restangular", "$scope", "$staticModal", "$sce", "$mdsService", 
        function (Restangular: restangular.IService
            , $scope
            , $staticModal, $sce
            , $mdsService: zotec.patientExperience.Services.MDSService) {
       
            this.surveyUrl = "";
            this.thanksMessage = "";
            this.noSurveyMessage = "";
            this.surveyUrlHasValue = false;  

            var thanksErrorMessage = "Thanks for your patience.";
            var surveyErrorMessage = "There was a problem trying to load the survey. If the problem persists, please contact us.";

            var modal = $staticModal("Loading survey...", () => {
                $mdsService.loadQualtricsSurveyExpUrl().then(
                    (surveyUrlResult: zotec.patientExperience.MDS.QualtricsSurveyUrlResult) => {
                        if (surveyUrlResult) {
                            this.surveyUrl = surveyUrlResult.surveyUrl;
                            this.surveyUrlHasValue = surveyUrlResult.successful;
                        } else {
                            this.noSurveyMessage = surveyErrorMessage;
                            this.thanksMessage = thanksErrorMessage;
                        }                        
                        modal.hide();
                    },
                    (error) => {
                        this.noSurveyMessage = surveyErrorMessage;
                        this.thanksMessage = thanksErrorMessage;
                        console.log(error);
                        modal.hide();
                    });
            }, () => {
                modal.hide();
            });

            this.getTrustedSurveyUrl = () => {
                if (this.surveyUrl) {
                    return $sce.trustAsResourceUrl(this.surveyUrl);
                }
            }
            var lastUpdate = new Date();
            var previousSize = 0;
            function gotResizeMessage(event) {
                var matches = document.querySelectorAll('iframe');
                for (var i = 0; i < matches.length; i++) {
                    if (matches[i].contentWindow == event.source && angular.isDefined(event.data.isResizeEvent) && event.data.isResizeEvent && angular.isNumber(event.data.height))
                    {
                        var interval = Math.abs(new Date().getTime() - lastUpdate.getTime());
                        if (interval > 10 && Math.abs(event.data.height - previousSize) != 40) {
                            console.log('height, interval:', event.data.height, interval);

                            // adjust for chat button and footer height
                            matches[i].height = Number(event.data.height).toString();

                            lastUpdate = new Date();
                            return 1;
                        }
                    }
                }
            }

            window.addEventListener("message", gotResizeMessage, false);

            $scope.$on('$destroy', function () {
                window.removeEventListener("message", gotResizeMessage);
            });
    }]);
