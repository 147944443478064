/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Support")
    .controller("zpSupportController", ["Restangular", "$siteSettings", "$state", "$anchorScroll", "$timeout",
        function (Restangular: restangular.IService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $anchorScroll, $timeout) {
            this.questions = [] as zotec.patientExperience.Support.SupportQuestion[];
            this.acceptedCardTypes = [] as string[];
            this.acceptedCardsCombined = "";
            this.amex = "American Express";
            this.discover = "Discover";
            this.mastercard = "Mastercard";
            this.visa = "Visa";
            this.customFaqName = $siteSettings.companyNameDisplay || "Provider";
            this.customFaqUrl = $siteSettings.faqUrl;
            this.clientName = "";
            this.showCustomFaq = !!this.customFaqUrl;
            this.itemizedBillIndex = 9;
            this.hiddenFaq = $siteSettings.hiddenFaq === null ? [] : $siteSettings.hiddenFaq.split(',');
            if (this.hiddenFaq.length !== 0) {
                for (i = 0; i < this.hiddenFaq.length; i++) {
                    this.hiddenFaq[i] = this.hiddenFaq[i].trim();
                    this.hiddenFaq[i] = +this.hiddenFaq[i];
                    if (this.hiddenFaq[i] < 9) this.itemizedBillIndex = this.itemizedBillIndex - 1;
                }
            }
            if ($siteSettings.hoursOfOperation !== null && $siteSettings.hoursOfOperation !== undefined && $siteSettings.hoursOfOperation.length !== 0) {
                this.hoursOfOperation = $siteSettings.hoursOfOperation;
            } else {
                this.hoursOfOperation = "8:00 AM - 4:00 PM, Monday through Friday (local time)";
            }
            $anchorScroll.yOffset = 55;

            let service = $siteSettings.billingGroupRestangularized();
            if (service)
            {
                service.one("PaymentModule").one("AcceptedCards").get().then(
                    (cards: zotec.patientExperience.payments.AcceptedCards) => {
                        if (cards.amex) { this.acceptedCardTypes.push(this.amex); }
                        if (cards.discover) { this.acceptedCardTypes.push(this.discover); }
                        if (cards.mastercard) { this.acceptedCardTypes.push(this.mastercard); }
                        if (cards.visa) { this.acceptedCardTypes.push(this.visa); }

                        if (this.acceptedCardTypes.length === 1) {
                            this.acceptedCardsCombined = this.acceptedCardTypes[0];
                        } else if (this.acceptedCardTypes.length === 2) {
                            this.acceptedCardsCombined = _.join(this.acceptedCardTypes, " and ");
                        } else if (this.acceptedCardTypes.length > 2) {
                            const firstPart = _.join(_.initial(this.acceptedCardTypes), ", ");
                            this.acceptedCardsCombined = firstPart + ", and " + _.last(this.acceptedCardTypes);
                        }
                    });

                service.one("clientinfo").get()
                    .then((info: zotec.patientExperience.client.ClientInfo) => {
                        this.clientName = info.name;
                    }, () => {
                        console.log("error getting settings");
                    });
            }

            for (var i = 0; i < 23; i++) {
                this.questions[i] = { expanded: false };
            }

            this.expandQuestion = (index: number) => {
                _.forEach(this.questions, (question: zotec.patientExperience.Support.SupportQuestion) => {
                    question.expanded = false;
                });
                this.questions[index - 1].expanded = true;
            }

            this.scrollToQuestion = (index: number) => {
                this.expandQuestion(index);
                $timeout(function () {
                    $anchorScroll('question_' + index);
                }, 0);
            }

            this.isExpanded = (index: number) => {
                return this.questions[index - 1].expanded;
            }

            this.isQuestionHidden = (index: number) => {
                if (this.hiddenFaq.includes(index)) return true;
                else return false;
            }

            this.getClientName = () => {                
                return this.clientName && this.clientName !== "" ? " for " + this.clientName : "";
            }

        }]);