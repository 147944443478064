/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.InsuranceEdit", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.insurance-edit", {
            url: "/insurance-edit",
            template: "<zp-insurance-edit></zp-insurance-edit>",
            params: {
                encounterDetails: (null as zotec.patientExperience.insurance.EncounterDetails),
                policy: (null as zotec.patientExperience.insurance.InsurancePolicy)
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Insurance Verify", entity);
                }]
            }
        });
    }]);