/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$donationModal", ["$modal", "$rootScope",
    ($modal, $rootScope) => {

        function $donationModal(amount: Number, dismissFunction: Function) {
            var scope = $rootScope.$new();
            var modal = $modal({
                templateUrl: "Templates/Controls/Services/DonationModal",
                scope: scope
            });
            scope.amount = amount;

            scope.acceptAction = () => {
                modal.hide();
                dismissFunction();
            };

            return modal;
        }

        return $donationModal;
    }
]);
