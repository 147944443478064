/// <reference path="../../../../../../Content/angular/angular.d.ts" />

var siteResolver = {
    entity: ["SiteModel", "$location", "$transition$", "$window", (SiteModel, $location, $transition$, $window) => {
        const entityId = $transition$.params().entityId;
        const host = $location.host();
        const url = $location.url();
        const isGlobalLogin = url === "/global/login";
        const combinedUrl = host + "/" + entityId; 
        return SiteModel.getSettings(host, entityId).then(
            (details: zotec.patientExperience.SiteUrlDetails) => {
                if (details.clientId && details.clientId !== "") {
                    if ((!details.urlHasEntityID && details.siteUrl.toLowerCase() !== $location.host().toLowerCase()) ||
                        (details.urlHasEntityID  && details.siteUrl.toLowerCase() !== combinedUrl.toLowerCase())) {

                        const port = $window.location.port !== "" && $window.location.port !== 80 && $window.location.port !== 433
                            ? ":" + $window.location.port
                            : "";

                        const path = isGlobalLogin ? "/home" : "";
                        if (!details.urlHasEntityID) {
                            $window.location.href =
                                $window.location.protocol + "//" + details.siteUrl + port + "/" + details.clientId + path;
                        } else {
                            const urlSections = details.siteUrl.split("/");
                            $window.location.href =
                                $window.location.protocol + "//" + urlSections[0] + port + "/" + urlSections[1] + path;
                        }

                    }
                }

                return details;
            });
    }]
}

angular.module("Zotec.PatientExperience.Entity", [])
    .config(["$stateProvider", "$urlMatcherFactoryProvider", ($stateProvider, $urlMatcherFactoryProvider) => {
        $urlMatcherFactoryProvider.caseInsensitive(true);
        $urlMatcherFactoryProvider.strictMode(false);
        $stateProvider.state("entity",
            {
                url: "/{entityId}",
                template: "<zp-entity></zp-entity>",
                resolve: siteResolver
            });
    }]);