/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../Content/file-saver/index.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$pdf",
    ["$window", "$http", "$staticModal",
        function ($window, $http, $staticModal) {

            var showPdfFunction = function showPdf(data: any) {
                const blob = new Blob([data], { type: 'application/pdf' });
                const now = moment().format("YYYY_MM_DD_HH_mm_ss");
                const fileName = now + '.pdf';
                saveAs(blob, fileName);
            }

            var openFunction = function open(url: string, parameters: any, modalTitle: string, completion: (success: boolean) => void) {
                var modal = $staticModal(modalTitle, () => {
                    var config = {
                        responseType: "arraybuffer",
                        headers: { accept: "application/pdf" },
                        params: parameters
                    };
                    $http.get(url, config).then((response) => {
                        showPdfFunction(response.data);
                        modal.hide();
                        if (completion != null) {
                            completion(true);
                        }
                    }, () => {
                        modal.hide();
                        console.log("error getting pdf data");
                        if (completion != null) {
                            completion(false);
                        }
                    });
                });

            }

            var openPostFunction = function open(url: string, parameters: any, modalTitle: string, completion: (success: boolean) => void) {
                var modal = $staticModal(modalTitle, () => {
                    var config = {
                        responseType: "arraybuffer",
                        headers: { accept: "application/pdf" }
                    };
                    $http.post(url, parameters, config).then((response) => {
                        showPdfFunction(response.data);
                        modal.hide();
                        if (completion != null) {
                            completion(true);
                        }
                    }, () => {
                        modal.hide();
                        console.log("error getting pdf data");

                        if (completion != null) {
                            completion(false);
                        }
                    });
                });

            }

            return {
                open: function o(url: string, parameters: any, modalTitle: string, completion: (success: boolean) => void) {
                    return openFunction(url, parameters, modalTitle, completion);
                },
                openPost: function o(url: string, parameters: any, modalTitle: string, completion: (success: boolean) => void) {
                    return openPostFunction(url, parameters, modalTitle, completion);
                }
            }

        }
    ]);