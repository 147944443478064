/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Support", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.support", {
            url: "/support",
            template: "<zp-support></zp-support>",
            params: {
                key: null
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.tryAuthenticate("Support", entity);
                }]
            }
        });
    }]);