/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$pendoService",
    ["$window", "Restangular", "$siteSettings", "$patientExperienceSettings", "$q",
        function ($window,
            Restangular: restangular.IService,
            $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings,
            $q: angular.IQService) {

            var configuration: zotec.patientExperience.Pendo.PendoConfiguration = { serviceEnabled: false, apiKey: null, appName: null };

            var loadConfiguration = function () {
                var queryParams = {
                    featureName: $patientExperienceSettings.features.enablePendoForClientsV1,
                    featureVersion: "1.0.0",
                    dataSourceId: $siteSettings.dataSourceId,
                    clientId: $siteSettings.clientId
                };

                var deferred = $q.defer();
                $q.all([
                    Restangular.one("applicationfeatures").get<string>(queryParams),
                    Restangular.one("AppSettings").one("pendo").get<zotec.patientExperience.AppSettings.PendoSettings>({ appName: "PXP" }),
                    Restangular.one("pendo").get<zotec.patientExperience.Pendo.PendoDetails>()]
                ).then(function (responses) {
                    if (responses[0] == "True") {
                        configuration.serviceEnabled = true;
                        configuration.apiKey = responses[1].apiKey;
                        configuration.appName = responses[2].applicationName;
                    }
                }).catch((error) => {
                    console.log(error);
                    deferred.reject("PendoService: Could not load the agent configuration.");
                }).finally(() => {
                    deferred.resolve(configuration);
                });

                return deferred.promise;
            }

            var loadAgent = function (apiKey: string): void {
                (function (p, e, n, d, o) {
                    var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];
                    v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
                        o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
                    })(v[w]);
                    y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
                    z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
                })($window, document, 'script', 'pendo');
            }

            var startAgentAnonymously = function (clientId: string, applicationName: string): void {
                $window.pendo.initialize({
                    visitor: {
                        id: ""
                    },
                    account: {
                        id: clientId || "",
                        applicationname: applicationName,
                    }
                });
            }

            var identify = function (guarantorId: Number, isPatient: boolean, clientId: string, applicationName: string): void {
                $window.pendo.identify({
                    visitor: {
                        id: guarantorId,
                        isPatient: isPatient
                    },
                    account: {
                        id: clientId,
                        applicationname: applicationName
                    }
                });
            }

            return {
                loadConfiguration: loadConfiguration,
                loadAgent: loadAgent,
                startAgentAnonymously: startAgentAnonymously,
                identify: identify
            }
        }
    ]);