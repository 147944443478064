/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.EbcPassThrough")
    .directive("zpEbcpassthrough", () => {
        return {
            restrict: "E",
            controller: "zpEbcPassThroughController",
            controllerAs: "zpEbcPassThrough",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/EbcPassThrough/zpEbcPassThrough"
        }
    });