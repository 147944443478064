/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedBillPay", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.tele-med-bill-pay", {
            url: "/tele-med-bill-pay",
            template: "<zp-tele-med-bill-pay></zp-tele-med-bill-pay>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Tele Med Bill Pay", entity);
                }]
            }
        });
    }]);