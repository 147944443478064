/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Thanks")
    .directive("zpThanks", () => {
        return {
            restrict: "AE",
            controller: "zpThanksController",
            controllerAs: "zpThanks",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Thanks/zpThanks"
        }
    });