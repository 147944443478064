/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpCollapse", [() => {
            return {
                restrict: "A",
                link: (scope, element: JQuery, attrs) => {
                    element.click((event) => {
                        element.removeClass("in");
                    });
                }
            };
        }
    ]);
