/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.GuarantorPatients")
    .directive("zpGuarantorPatients", () => {
        return {
            restrict: "AE",
            controller: "zpGuarantorPatientsController",
            controllerAs: "zpGuarantorPatients",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/GuarantorPatients/zpGuarantorPatients"
        }
    });