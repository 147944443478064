/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$showDateOfBirthModal", ["$modal", "$rootScope",
    ($modal, $rootScope) => {

        return () => {
            var scope = $rootScope.$new();

            var modal = $modal({
                scope: scope,
                templateUrl: "/Templates/Controls/Services/showDateOfBirthModal",
                backdrop: "static"
            });

            scope.close = () => {
                modal.hide();
            }

            return modal;

        }
    }
]);
