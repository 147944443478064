/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.PrePay")
    .directive("zpPrePay", () => {
        return {
            restrict: "AE",
            controller: "zpPrePayController",
            controllerAs: "zpPrePay",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/PrePay/zpPrePay"
        }
    });