/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpDate", () => {
        return {
            restrict: "A",
            require: "ngModel",
            link: (scope, element, attrs, ctrl: any) => {
                var minDate = null;
                var maxDate = null;

                attrs.$observe("zpDateMin",
                    value => {
                        if (value !== undefined && value !== null && value !== "") {
                            minDate = moment(value).isValid() ? moment(value) : null;
                        }
                    });

                attrs.$observe("zpDateMax",
                    value => {
                        if (value !== undefined && value !== null && value !== "") {
                            maxDate = moment(value).isValid() ? moment(value) : null;
                        }
                    });

                ctrl.$validators.zpValidDate = (modelValue, viewValue) => ctrl.$isEmpty(modelValue) ||
                (moment(modelValue).isValid() &&
                    (minDate === null || moment(modelValue) >= minDate) &&
                    (maxDate === null || moment(modelValue) <= maxDate));
            }
        }
    });