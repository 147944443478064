/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$helpModal", ["$modal", "$rootScope",
    ($modal, $rootScope) => {

        function $helpModal(title: string, text: string) {
            var scope = $rootScope.$new();
            scope.title = title;
            scope.text = text;
            var modal = $modal({
                templateUrl: "Templates/Controls/Services/HelpModal",
                scope: scope,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom"
            });

            scope.close = () => {
                modal.hide();
            }

            scope.goToHelp = () => {
                modal.hide();
            }

            return modal;
        }

        return $helpModal;
    }
]);
