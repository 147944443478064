/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpChargePaidDetails", [() => {
        return {
            templateUrl: "Templates/Controls/Directives/ChargePaidDetails",
            scope: {
                adjustments: "=",
                balance: "=",
                guarantorTotalPayments: "=",
                totalInsPayments: "=",
                showInsuranceBasedInfo: "=",
                hidePaymentsAndTotal: "="
            }
        };
    }]);