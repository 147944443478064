/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.TeleMedPaymentPlan", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.tele-med-payment-plan", {
            url: "/tele-med-payment-plan",
            template: "<zp-tele-med-payment-plan></zp-tele-med-payment-plan>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("Tele Med Payment Plan", entity);
                }]
            }
        });
    }]);