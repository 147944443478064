/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$showAccountLookUp", ["Restangular", "$modal", "$rootScope", "$pendoService", "$guarantor",
    "$siteSettings", "$siteSelector", "$sessionStorage", "$statementModal", "$staticModal", "$state", "$q", "$siteTimeoutService",
    (Restangular: restangular.IService, $modal, $rootScope, $pendoService: zotec.patientExperience.Services.PendoService, $guarantor: zotec.patientExperience.Services.GuarantorService,
        $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $siteSelector, $sessionStorage, $statementModal, $staticModal, $state, $q: angular.IQService,
        $siteTimeoutService: zotec.patientExperience.Services.SiteTimeoutService) => {

        return () => {
            this.sessionTimeoutInMinutes = $siteSettings.sessionExpirationInMinutes || 15;

            var scope = $rootScope.$new();

            scope.dataSourceId = $siteSettings.dataSourceId || $sessionStorage.dataSourceId || null;
            scope.clientId = $siteSettings.clientId || $sessionStorage.clientId || null;
            scope.directLinkId = ($sessionStorage.directLinkId != null) ? $sessionStorage.directLinkId : 0;
            scope.directLinkBillingGroupId = $sessionStorage.directLinkBillingGroupId;
            scope.billingGroupId = $siteSettings.billingGroupId || 0;
            scope.billingGroupLocalId = $siteSettings.billingGroupLocalId || null;
            scope.chargeId = $sessionStorage.chargeId || 0;
            scope.paymentsOnPatientDeviceDataID = $sessionStorage.paymentsOnPatientDeviceDataID || 0;

            scope.authBillingGroupLocalId =
                (scope.directLinkId > 0 && (scope.directLinkBillingGroupId == null || scope.directLinkBillingGroupId == 0))
                    ? ""
                    : (scope.billingGroupLocalId || "");

            var modal = $modal({
                scope: scope,
                templateUrl: "/Templates/PatientExperience/AccountLookup/zpAccountLookUp",
                backdrop: "static"
            });

            scope.close = () => {
                modal.hide();
            }

            //set communication options -- client specific
            var initialOptions = [
                { "value": "email", "label": "Email" },
                { "value": "sms", "label": "Text" }
            ]; //lower cased email, sms
            scope.options = initialOptions;
            scope.requestSuccess = false;
            scope.verificationCode = null;
            scope.sendto = null;
            scope.email = null;
            scope.sms = null;
            scope.dateOfBirthMonth = null;
            scope.dateOfBirthDay = null;
            scope.dateOfBirthYear = null;
            scope.disableButton = false;
            scope.modalLoginError = null;
            scope.minutesToExpireSecureCode = null;
            scope.modalRequestCodeError = null;
            scope.preferredCommunication = scope.options[0].value;
            scope.recaptchaResponse = "";
            scope.allowRecaptchaResponse = false;

            var queryParams = {
                featureName: "AllowRecaptchaResponse",
                featureVersion: "1.0.0",
                dataSourceId: $siteSettings.dataSourceId,
                clientId: $siteSettings.clientId
            };

            var deferred = $q.defer();
            $siteSettings.recaptchaToggle(queryParams)
                .then((enabled) => {
                    scope.allowRecaptchaResponse = enabled;
                    deferred.resolve();
                }, () => {
                    console.log("error in get appsettings");
                    deferred.resolve();
                });

            if (scope.dataSourceId !== null && scope.clientId !== null) {
                Restangular.one("datasources", scope.dataSourceId)
                    .one("clients", scope.clientId)
                    .one("billinggroups", scope.billingGroupId)
                    .all("clientinfo")
                    .one("charge")
                    .get({ chargeId: $sessionStorage.chargeId || 0 })
                    .then(
                        (clientInfo: zotec.patientExperience.client.ClientInfo) => {
                            if (clientInfo !== null) {
                                if (clientInfo.offerEmail === false) {
                                    scope.options = scope.options.filter(each => each.value != 'email');
                                }
                                if (clientInfo.offerTextMessages === false) {
                                    scope.options = scope.options.filter(each => each.value != 'sms');
                                }
                                scope.preferredCommunication = scope.options[0].value;
                            }
                        }, (response) => {
                            console.log("error with status code:", response.status);
                        });
            }

            if (scope.dataSourceId !== null && scope.clientId !== null) {
                Restangular.one("AppSettings")
                    .one("pxpAccountLookupSettings")
                    .get({ dataSourceId: scope.dataSourceId, clientId: scope.clientId })
                    .then((response: zotec.patientExperience.AppSettings.PxpAccountLookupSettings) => {
                        scope.minutesToExpireSecureCode = response.minutesToExpireSecureCode;
                    }, (response) => {
                        console.log("error with status code:", response.status);
                    });
            }

            const rest = $siteSettings.billingGroupRestangularized();
            if (rest != null) {
                rest.one("clientinfo").get().then((clientInfo: zotec.patientExperience.client.ClientInfo) => {
                    let unformattedPhone = clientInfo.phone || "";
                    const cleaned = ('' + unformattedPhone).replace(/\D/g, '');
                    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    scope.clientPhone = match != null ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : null;
                }, () => {
                    console.log("error getting client info");
                });
            }

            scope.requestSecureCode = (dateOfBirthMonth: any, dateOfBirthDay: any, dateOfBirthYear: any,
                preferredCommunication: string, email: string, sms: string) => {
                var sendto = '';
                if (preferredCommunication === 'email') {
                    sendto = email;
                    scope.email = email;
                } else {
                    sendto = sms;
                    scope.sms = sms;
                }
                if (sendto.length === 0) return;

                scope.guarantorBirthDate = dateOfBirthMonth.toString().padStart(2, '0') + '/' + dateOfBirthDay.toString().padStart(2, '0') + '/' + dateOfBirthYear;
                scope.sendto = sendto;
                scope.preferredCommunication = preferredCommunication;
                scope.dateOfBirthMonth = dateOfBirthMonth;
                scope.dateOfBirthDay = dateOfBirthDay;
                scope.dateOfBirthYear = dateOfBirthYear;
                scope.disableButton = true;

                Restangular.all("authentication/requestSecureCode")
                    .post({
                        dataSourceId: scope.dataSourceId,
                        clientId: scope.clientId,
                        sendto: sendto,
                        preferredCommunicationEmail: preferredCommunication,
                        dateOfBirth: scope.guarantorBirthDate,
                        recaptchaResponse: scope.recaptchaResponse,
                        billingGroupId: scope.billingGroupId
                    })
                    .then((response: number) => {
                        if (response === 1) {
                            scope.requestSuccess = true;
                            scope.disableButton = false;
                        }
                        else if (response === 2) {
                            scope.modalRequestCodeError = `We cannot process your request at this time.  Please contact us at ${scope.clientPhone}`
                        }
                        else {
                            scope.modalRequestCodeError = "Your account has been locked out."
                        }
                    }, (response) => {
                        scope.requestSuccess = true;
                        scope.disableButton = false;
                    });
            }

            var pendoConfiguration: zotec.patientExperience.Pendo.PendoConfiguration = null;

            $pendoService.loadConfiguration().then(
                (config) => {
                    pendoConfiguration = config;
                    if (config.serviceEnabled) {
                        $pendoService.loadAgent(config.apiKey);
                        $pendoService.startAgentAnonymously(scope.clientId, config.appName);
                    }
                },
                (error) => {
                    console.log(error);
                });

            scope.authenticateverificationcode = (verificationCode: any) => {
                if (verificationCode === null || verificationCode === undefined) return;
                var loadingModal = $staticModal("Signing in...", () => {
                    Restangular.all("authentication/authenticateverificationcode")
                        .post({
                            dataSourceId: scope.dataSourceId,
                            clientId: scope.clientId,
                            verificationCode: verificationCode,
                            sendto: scope.sendto,
                            preferredCommunicationEmail: scope.preferredCommunication,
                            dateOfBirth: scope.guarantorBirthDate,
                            billingGroupLocalId: scope.authBillingGroupLocalId,
                            directLinkId: scope.directLinkId,
                            paymentsOnPatientDeviceDataID: scope.paymentsOnPatientDeviceDataID
                        })
                        .then((auth: zotec.patientExperience.authentication.AuthenticationResult) => {
                            if (auth.authenticated) {
                                $siteTimeoutService.SetSiteTimeout(this.sessionTimeoutInMinutes);
                                if (pendoConfiguration.serviceEnabled) {
                                    $pendoService.identify(auth.person.personId, null, auth.person.clientId, pendoConfiguration.appName);
                                }
                                $sessionStorage.directLinkBillingGroupId = null;
                                $sessionStorage.directLinkId = null;
                                if (auth.person.sites.length === 1) {
                                    $guarantor.logIn(auth.person);
                                    const splitUrl = auth.person.sites[0].url.split("/");
                                    const entityId = splitUrl.length == 1 ? auth.person.clientId : splitUrl[splitUrl.length - 1];
                                    if ($sessionStorage.destination === 'TOSPayment') {
                                        $state.go("entity.tele-med-account-history", { entityId: entityId }).then(() => { modal.hide(); loadingModal.hide();});
                                    } else {
                                        $state.go("entity.account-history", { entityId: entityId }).then(() => { modal.hide(); loadingModal.hide();});
                                    }
                                } else {
                                    $siteSelector(auth.person);
                                }
                            } else if (auth.lockedOut) {
                                loadingModal.hide();
                                $('#verificationEntry').val("");
                                scope.modalLoginError = "Your account has been locked out.";
                            } else {
                                loadingModal.hide();
                                $('#verificationEntry').val("");
                                scope.modalLoginError = "";
                            }
                        }, (response) => {
                            loadingModal.hide();
                            $('#verificationEntry').val("");
                            scope.modalLoginError = "There was an error logging you in. Please try again.";
                        });
                });
            }

            return modal;

        }
    }
]);
