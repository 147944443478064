/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.PaperlessBilling", []);

angular.module("Zotec.PatientExperience.PaperlessBilling").factory("$paperlessBillingState",
    ["$guarantor","$q", function (
        $guarantor: zotec.patientExperience.Services.GuarantorService,
        $q: angular.IQService
    ) {

        this.EnrollmentStates = {
            LOADING_ENROLLMENTS: 'LOADING ENROLLMENTS' as zotec.patientExperience.Services.EnrollmentState,
            NOT_ENROLLED: 'NOT ENROLLED' as zotec.patientExperience.Services.EnrollmentState,
            DO_NOTHING: 'DO NOTHING' as zotec.patientExperience.Services.EnrollmentState,
            OPTED_OUT: 'OPTED OUT' as zotec.patientExperience.Services.EnrollmentState,
            FAILED_TO_ENROLL: 'FAILED TO ENROLL' as zotec.patientExperience.Services.EnrollmentState,
            ENROLLED: 'ENROLLED' as zotec.patientExperience.Services.EnrollmentState,
            DISCOVERED: 'DISCOVERED' as zotec.patientExperience.Services.EnrollmentState,
        };

        this.EnrollmentStatesArray = [
            this.EnrollmentStates.NOT_ENROLLED,
            this.EnrollmentStates.OPTED_OUT,
            this.EnrollmentStates.FAILED_TO_ENROLL,
            this.EnrollmentStates.ENROLLED,
            this.EnrollmentStates.DISCOVERED
        ];

        this.enrollmentState = this.EnrollmentStates.LOADING_ENROLLMENTS;

        this.getEmailSettings = () => {
            return $guarantor.restangularized()
                .one("paperless")
                .one("emailsettings")
                .get();
        }

        this.callEnroll = (getEndpoint: (element: restangular.IElement) => restangular.IElement) => {
            const prefix = $guarantor.restangularized().one("paperless");
            getEndpoint(prefix)
                .put();
        }

        this.enroll = () => {
            return $guarantor.restangularized().one("paperless").one("enroll").put();
        }

        this.enrollWithMobile = (mobileNumber: string) => {
            var request = $guarantor.restangularized().one("paperless").one("enrollWithMobile", mobileNumber).put();
            return request;
        }

        this.enrollWithEmail = (email: string) => {
            var params = { emailAddress: email };
            return $guarantor.restangularized().one("paperless").one("enrollWithEmail")
                .put(params);
        }

        $guarantor.safeRestangularized().then(restangularized => {
            restangularized.one("paperless")
                .one("enrolled")
                .get().then((enrollment: string) => {
                    switch (enrollment) {
                        case this.EnrollmentStates.NOT_ENROLLED:
                        case this.EnrollmentStates.OPTED_OUT:
                        case this.EnrollmentStates.ENROLLED:
                        case this.EnrollmentStates.DISCOVERED:
                            this.enrollmentState = enrollment;
                            break;
                        default:
                            // Hide UI
                            this.enrollmentState = this.EnrollmentStates.DO_NOTHING;
                    }
                    $guarantor.isEnrolled = (enrollment == 'ENROLLED');
                })
                .catch(err => { console.log(err) });
        });

        return this;
    }
    ]);
