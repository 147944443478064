/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.PaymentPlan")
    .controller("zpPaymentPlanController", ["Restangular", "$scope", "$siteSettings", "$guarantor", "$state", "$paymentFailureModal", "$q", "$loadPaymentModuleLibrary", "$pdf", "$promptModal", "$sessionStorage", "$patientExperienceSettings",
        function (Restangular: restangular.IService, $scope, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $state, $paymentFailureModal, $q: angular.IQService, $loadPaymentModuleLibrary, $pdf: zotec.patientExperience.Services.PdfService, $promptModal, $sessionStorage, $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings) {

            this.numberOfOptionsToShow = 3;
            this.guarantorBalance = $state.params.guarantorBalance || 0.0;
            this.pmCharges = $state.params.pmCharges || [];
            this.dependantResponsibles;
            this.monthlyIncome;
            this.selectedPaymentPlan;
            this.isPredefinedPlanSelected = false;
            this.paymentPlanSetup = new PaymentPlanSetUp("", 0, "", "", "", 0, false);
            this.defaultPlans = [];
            this.hasCalculatedPPOptions = false as boolean;
            this.enableCreditCardFormWithBillingAddress = false as boolean;

            this.personIdLog = 0;
            if (this.guarantorBalance === 0 || this.pmCharges.length === 0) {
                $state.go("entity.account-history");
            }

            this.allowPaymentPlanUpFront = $siteSettings.allowPaymentPlanUpFront;
            this.showIncomeQuestions = $siteSettings.showIncomeQuestions;

            this.LOADING_STATE = 0;
            this.NORMAL_STATE = 1;
            this.ERROR_STATE = 2;

            this.currentState = this.LOADING_STATE;
            this.setState = (state: number) => {
                this.currentState = state;
            }

            this.isLoading = () => {
                return this.currentState == this.LOADING_STATE;
            }

            this.isInErrorState = () => {
                return this.currentState == this.ERROR_STATE;
            }
            this.setSelectedPaymentPlan = (plan: DefaultPaymentPlan) => {
                this.isPredefinedPlanSelected = (plan != null && plan.paymentAmountPerMonth > 0);
                this.selectedPaymentPlan = plan;
            }

            this.goToEditPaymentPlan = () => {
                this.page = this.SETUP_PAGE;
            }

            this.TERMS_PAGE = 1.5;
            this.QUESTION_PAGE = 0;
            this.SETUP_PAGE = 1;
            this.PAYMENT_PAGE = 2;
            this.SUMMARY_PAGE = 3;


            this.LAST_PAGE_INDEX = this.SUMMARY_PAGE;

            if (this.allowPaymentPlanUpFront) {
                this.page = this.showIncomeQuestions ? this.QUESTION_PAGE : this.SETUP_PAGE;
            }
            else {
                this.isPredefinedPlanSelected = false;
                this.page = this.TERMS_PAGE;
            }

            this.shouldShowQuestions = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.QUESTION_PAGE && this.allowPaymentPlanUpFront;
            }

            this.shouldShowSetup = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.SETUP_PAGE && this.allowPaymentPlanUpFront;
            }

            this.shouldShowTerms = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.TERMS_PAGE;
            }

            this.shouldShowPayment = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.PAYMENT_PAGE;
            }

            this.shouldShowSummary = () => {
                return this.currentState == this.NORMAL_STATE && this.page == this.SUMMARY_PAGE;
            }

            this.moveForward = () => {
                if (this.page < this.LAST_PAGE_INDEX) {
                    this.page += 1;
                }
            }

            this.moveBack = () => {
                if (this.page > 0) {
                    this.page -= 1;
                } 
            }

            this.paymentError = null;
            this.internalRequest = Restangular.one("internalnetwork").get().$object;

            this.email = "";
            this.minimumPaymentAmount = 10;
            this.clientMinPaymentAmount = 10;
            this.numberOfPayments = 1;
            this.paymentAmount = 10;
            this.sessionData = null;
            this.minStartDate = moment(new Date()).format("MM/DD/YYYY");
            this.maxStartDate = moment(new Date()).add(15, "days").format("MM/DD/YYYY");
            this.startDate = moment(new Date()).format("MM/DD/YYYY");
            this.endDate = moment(new Date()).add(1, "months").format("MM/DD/YYYY");
            this.ppStartDate = moment(new Date()).format("MM/DD/YYYY");

            this.paymentAmountChanged = () => {
                this.startDateChanged();
            }

            this.startDateChanged = () => {
                this.numberOfPayments = Math.min(this.maxNumberOfPayments, Math.ceil(this.guarantorBalance / this.paymentAmount));
                const mStartDate = moment(this.startDate, "MM/DD/YYYY");
                this.endDate = mStartDate.add(this.numberOfPayments - 1, "months").format("MM/DD/YYYY");
            }

            this.goToErrorState = (logErrorMessage: string) => {
                if (!this.isInErrorState()) {
                    this.currentState = this.ERROR_STATE;
                    $paymentFailureModal();
                }
                this.logError(logErrorMessage);
                console.log(logErrorMessage);
            }

            this.logError = (message: string) => {
                $siteSettings.billingGroupRestangularized().all("PostProcess").all("Log").post({ logMessage: message, guarantorId: $guarantor.guarantorId, transactionId: this.transactionId });
            }

            this.logPatientCensusInfo = (log: zotec.patientExperience.PaymentPlan.PatientCensusInfo) => {
                $siteSettings.billingGroupRestangularized().all("PatientCensusLog").post(log);
            }

            this.getTermsOfUseUrl = () => {
                var returnUrl = $siteSettings.getTermsOfUseUrl();
                return returnUrl;
            }

            this.getPrivacyPolicyUrl = () => {
                var returnUrl = $siteSettings.getPrivacyPolicyUrl();
                return returnUrl;
            }

            this.selectedPaymentMethod = $state.params.selectedPaymentMethod || "cc";
            this.availablePaymentMethods = [
                {
                    name: "Credit Card",
                    key: "cc"
                }
            ];

            if ($guarantor.guarantorId > 0) {
                $guarantor.restangularized()
                    .one("information").get().then((guarantor) => {
                        if (guarantor) {
                            this.email = guarantor.email;
                            this.personIdLog = guarantor.personId;
                        }
                    });
            }

            let paymentModuleLibraryPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("Library").get()
                .then((response: string) => {
                    $loadPaymentModuleLibrary(response);
                }, () => {
                    this.goToErrorState("Payment plans could not load the Payment Module.")
                });

            let sessionDataPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("SessionData").get()
                .then((data: string) => {
                    this.sessionData = data
                }, () => {
                    this.goToErrorState("Payment plans could not load the Payment Module.")
                });

            let clientInfoPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    if (info.acceptElectronicCheck) {
                        this.availablePaymentMethods.push({
                            name: "Electronic Check",
                            key: "echk"
                        });
                    }

                    this.clientMinPaymentAmount = info.minPaymentAmount;
                    this.minimumPaymentAmount = Math.max(Math.ceil(this.guarantorBalance / info.maxNumberOfPayments * 100) / 100, Math.min(info.minPaymentAmount, this.guarantorBalance));
                    this.paymentAmount = this.minimumPaymentAmount;
                    this.maxNumberOfPayments = info.maxNumberOfPayments;
                    this.startDateChanged();
                }, () => {
                    this.goToErrorState("Payment plans could not retrieve client details.");
                });

            let enableCreditCardFormWithBillingAddressTogglePromise = Restangular.one("ApplicationFeatures").get({
                featureName: $patientExperienceSettings.features.enableCreditCardFormWithBillingAddress,
                featureVersion: "1.0.0"
                }).then((response: string) => {
                    this.enableCreditCardFormWithBillingAddress = response === 'True';
                }, () => {
                    this.enableCreditCardFormWithBillingAddress = false;
                });

            $q.all([enableCreditCardFormWithBillingAddressTogglePromise, paymentModuleLibraryPromise, sessionDataPromise, clientInfoPromise]).finally(() => {
                if (!this.isInErrorState()) {
                    this.setState(this.NORMAL_STATE);
                }
                
                if (!this.showIncomeQuestions) {
                    if (this.page == this.SETUP_PAGE) {

                        this.setPaymentPlanOptions(this.numberOfOptionsToShow);

                        if (!this.hasCalculatedPPOptions) {
                            this.page = this.TERMS_PAGE;
                        }
                    }
                }
            });

            this.sendPlanTerms = () => {
                this.currentState = this.NORMAL_STATE;
                this.moveForward();
            }

            this.cancel = () => {
                this.currentState = this.NORMAL_STATE;
                this.moveBack();
            }
            this.cancelAlternative = () => {
                this.currentState = this.NORMAL_STATE;
                this.page = this.showIncomeQuestions ? this.QUESTION_PAGE : $state.go("entity.account-history");
            }

            this.nextQuestionStep = () => {
                this.currentState = this.NORMAL_STATE;
                var log: zotec.patientExperience.PaymentPlan.PatientCensusInfo = {
                    personId: this.personIdLog,
                    clientId: $siteSettings.clientId,
                    houseHoldIncome: this.monthlyIncome,
                    financiallyResponsibleDependents: this.dependantResponsibles
                };
                this.logPatientCensusInfo(log);
                this.setPaymentPlanOptions(3);
                if (this.hasCalculatedPPOptions) {
                    this.page = this.SETUP_PAGE;
                }
                else {
                    this.page = this.TERMS_PAGE;
                }
            }
            this.nextSetupStep = () => {
                this.currentState = this.NORMAL_STATE;
                if (this.isPredefinedPlanSelected) {
                    this.paymentPlanSetup = new PaymentPlanSetUp('', this.selectedPaymentPlan.paymentAmountPerMonth,
                        this.ppStartDate,
                        moment(this.ppStartDate, "MM/DD/YYYY").add(this.selectedPaymentPlan.numberOfPayments - 1, "months").format("MM/DD/YYYY"),
                        '',
                        this.selectedPaymentPlan.numberOfPayments, false);
                }
                else {
                    this.paymentPlanSetup = new PaymentPlanSetUp('', this.paymentAmount,
                        this.startDate,
                        this.endDate,
                        '',
                        this.numberOfPayments, false);
                }
                this.page = this.PAYMENT_PAGE;
            }

            this.nextSetupStepAlternative = () => {
                this.currentState = this.NORMAL_STATE;
                this.page = this.TERMS_PAGE;
            }

            this.onPaymentMethodChanged = () => {
                document.getElementById("payment-module-credit-card-container").style.display = 'none';
                document.getElementById("payment-module-electronic-check-container").style.display = 'none';

                var paymentModuleApi: zotec.patientExperience.payments.IPaymentModuleApiV3 = window["PaymentModule"];
                switch (this.selectedPaymentMethod) {
                    case "cc":
                        document.getElementById("payment-module-credit-card-container").style.display = '';
                        const ccForm = paymentModuleApi.InitCreditCardForm("payment-module-credit-card-container", { includeSwipe: false, includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.configurePaymentModuleForm(ccForm);
                        break;
                    case "echk":
                        document.getElementById("payment-module-electronic-check-container").style.display = '';
                        const ecForm = paymentModuleApi.InitECheckForm("payment-module-electronic-check-container", { includeNotes: false, includeBillingAddress: this.enableCreditCardFormWithBillingAddress });
                        this.configurePaymentModuleForm(ecForm);
                        break;
                    default:
                        break;
                }
            }

            this.configurePaymentModuleForm = (pmForm: zotec.patientExperience.payments.IPaymentModuleForm) => {
                pmForm.OnSuccess((data) => this.handlePaymentCompletion(data))
                    .WithCharges(this.pmCharges)
                    .IncludePlan({
                        monthlyPaymentAmount: this.paymentPlanSetup.paymentAmount,
                        initialContractAmount: Math.min((this.paymentPlanSetup.paymentAmount * this.paymentPlanSetup.numberOfPayments), this.guarantorBalance),
                        firstInstallmentDate: this.paymentPlanSetup.startDate
                    })
                    .WithChargeType("PatientPaymentPlan")
                    .WithAppName("PXP")
                    .WithWebHook(`${window.location.origin}/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/PostProcess/PaymentPlan/${$guarantor.guarantorId}`, this.email)
                    .ConfigurePatientInternal({
                        dataSourceId: $siteSettings.dataSourceId,
                        clientId: $siteSettings.clientId,
                        locationId: "",
                        billingGroupId: $siteSettings.billingGroupId,
                        entityId: "",
                        guarantorId: $guarantor.guarantorId,
                        patientId: 0,
                    })
                    .OnVerificationRequest(() => {
                        let response = $.ajax({
                            url: $siteSettings.billingGroupRestangularized().all("PaymentModule").one("AccessToken").getRestangularUrl(),
                            async: false,
                            cache: false,
                        }).responseText;
                        return JSON.parse(response);
                    });
            }

            this.validateDigit = (event) => {
                const whitelist = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                if (!whitelist.includes(event.key)) {
                    event.preventDefault();
                }
            }


            this.planSummary = null;

            this.handlePaymentCompletion = (response: zotec.patientExperience.payments.WebHookResponse) => {
                const method = this.availablePaymentMethods.find(m => m.key == this.selectedPaymentMethod);
                this.planSummary = new PaymentPlanSummary(response.webhookResponse, this.paymentAmount, this.startDate, this.endDate, response.maskedAccountNumber);
                this.paymentPlanSetup.paymentMethod = method.name + ' ' + response.maskedAccountNumber.substr(-8);
                this.paymentPlanSetup.confirmationNumber = response.webhookResponse;
                this.paymentPlanSetup.isSuccessfull = parseInt(response.webhookResponse) > 0;
                this.moveForward();
                this.setupFeedback();
                $scope.$digest();
            }

            this.useFeedbackOverride = false;
            this.feedbackUrl = null as string;
            this.feedbackPromptButtonText = null as string;

            this.setupFeedback = () => {
                this.useFeedbackOverride = $siteSettings.feedbackUrl && $siteSettings.feedbackUrl.length > 0;
                this.feedbackUrl = $siteSettings.feedbackUrl || $state.href("entity.feedback");
                this.feedbackPromptButtonText = $siteSettings.feedbackPromptButtonText || "Leave Feedback";

                this.promptFeedbackIfEnabled();
            }

            this.promptFeedbackIfEnabled = () => {
                if ($siteSettings.feedbackPromptAfterPayment) {
                    const feedbackPromptText = $siteSettings.feedbackPromptText || "Tell us about your billing experience by leaving feedback.";

                    $promptModal("Payment successful", feedbackPromptText, this.feedbackPromptButtonText, "No thanks", () => {
                        window.open(this.feedbackUrl);
                    }, () => {
                        if ($siteSettings.sendPatientSurveyUponPaymentAndNoFeedback  && $sessionStorage.fromDirectLink) {
                            var surveyRequest = {
                                AppName: "PXP",
                                PersonId: $guarantor.guarantorId,
                                DataSourceId: $siteSettings.dataSourceId,
                                ClientId: $siteSettings.clientId,
                                BillingGroupId: $siteSettings.billingGroupId,
                                ChannelType: "",
                                SendTo: "",
                            }
                            Restangular.all("PaymentReceived").post(surveyRequest);
                        }
                    });
                }
            }

            this.printReceipt = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/download", { paymentId: parseInt(this.paymentPlanSetup.confirmationNumber), isPrePay: false, isTOSReceipt: false }, "Generating receipt...", null);
            }

            this.truncateNumber2Decimals = (num: number) => {
                return +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }

            this.calculateWithMinimumAllowedAmount = (numberOfOptions: number, maxPaymentsWithMinimumAmount: number) => {
                this.defaultPlans = [];
                let singleInterval = maxPaymentsWithMinimumAmount <= numberOfOptions ? 1 : Math.floor(maxPaymentsWithMinimumAmount / numberOfOptions);
                for (var option = 1; option <= numberOfOptions; option++) {
                    let numberOfPayments: number = singleInterval * option;
                    const rawPaymentAmount: number = (this.guarantorBalance / numberOfPayments) * 100;
                    let paymentAmount: number = ($siteSettings.usePaymentRounding ? Math.floor(rawPaymentAmount) : Math.ceil(rawPaymentAmount)) / 100;

                    if (paymentAmount >= this.minimumPaymentAmount && numberOfPayments > 1) {
                        this.defaultPlans.push(new DefaultPaymentPlan(paymentAmount, numberOfPayments));
                    }
                }
            }

            this.calculateWithMaxNumberOfPaymentAllowed = (numberOfOptions: number) => {
                this.defaultPlans = [];
                
                let singleInterval = this.maxNumberOfPayments <= numberOfOptions ? 1 : Math.floor(this.maxNumberOfPayments / numberOfOptions);
                for (var option = 1; option <= numberOfOptions; option++) {
                    let numberOfPayments: number = singleInterval * option;
                    const rawPaymentAmount: number = (this.guarantorBalance / numberOfPayments) * 100;
                    let paymentAmount: number = ($siteSettings.usePaymentRounding ? Math.floor(rawPaymentAmount) : Math.ceil(rawPaymentAmount)) / 100;

                    if (paymentAmount >= this.minimumPaymentAmount && numberOfPayments > 1) {
                        this.defaultPlans.push(new DefaultPaymentPlan(paymentAmount, numberOfPayments));
                    }
                }
            }

            this.setPaymentPlanOptions = (numberOfOptions: number) => {
                let maxPaymentsWithMinimumAmount = Math.floor(this.guarantorBalance / this.minimumPaymentAmount);
                
                if (maxPaymentsWithMinimumAmount > 1) {
                    this.hasCalculatedPPOptions = true;

                    if ((maxPaymentsWithMinimumAmount / numberOfOptions) > this.maxNumberOfPayments) {
                        this.calculateWithMaxNumberOfPaymentAllowed(numberOfOptions);
                    } else {
                        this.calculateWithMinimumAllowedAmount(numberOfOptions, maxPaymentsWithMinimumAmount);
                    }
                    this.selectedPaymentPlan = this.defaultPlans[0];
                    this.isPredefinedPlanSelected = true;
                }
            }
        }]);

class PaymentPlanSummary {
    confirmationNumber: string;
    paymentAmount: number;
    paymentId: number;
    startDate: string;
    endDate: string;
    paymentMethod: string;
    constructor(confirmationNumber: string, amount: number, start: string, end: string, method: string) {
        this.paymentAmount = amount;
        this.confirmationNumber = confirmationNumber;
        this.startDate = start;
        this.endDate = end;
        this.paymentMethod = method;
        this.paymentId = parseInt(confirmationNumber);
    }
}

class PaymentPlanSetUp {
    isSuccessfull: boolean;
    confirmationNumber: string;
    paymentAmount: number;
    startDate: string;
    endDate: string;
    numberOfPayments: number;
    paymentMethod: string;
    constructor(confirmationNumber: string, amount: number, start: string, end: string, method: string, numberOfPayments, isSucess: boolean) {
        this.confirmationNumber = confirmationNumber;
        this.paymentAmount = amount;
        this.startDate = start;
        this.endDate = end;
        this.paymentMethod = method;
        this.numberOfPayments = numberOfPayments;
        this.isSuccessfull = isSucess;
    }
}

class DefaultPaymentPlan {
    paymentAmountPerMonth: number;
    numberOfPayments: number;

    constructor(amount: number, numberOfPayments: number) {
        this.paymentAmountPerMonth = amount;
        this.numberOfPayments = numberOfPayments;
    }
}