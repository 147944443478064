/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$clientInfoService", ["Restangular", "$geocode", "$modal", "$rootScope", "$sce", "$siteSettings", "$sessionStorage",
    function (Restangular: restangular.IService, $geocode, $modal, $rootScope, $sce, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $sessionStorage) {

        function $getClient(dataSourceId: string, clientId: string, billingGroupId: number) {
            Restangular.one("datasources", dataSourceId).one("clients", clientId).one("billinggroups", billingGroupId).all("clientinfo").one("charge").get({chargeId: $sessionStorage.chargeId || 0}).then((clientInfo: zotec.patientExperience.client.ClientInfo) => {
                $geocode({
                    streetAddress: clientInfo.streetAddress1,
                    city: clientInfo.city,
                    state: clientInfo.state,
                    zip: clientInfo.zipCode
                }, (result: zotec.patientExperience.Coordinate) => {
                    var scope = $rootScope.$new();

                    if (result != null) {
                        clientInfo.lat = result.lat;
                        clientInfo.lng = result.lng;
                        var url = `https://maps.googleapis.com/maps/api/staticmap?center=${result.lat},${result.lng}&size=600x250&key=AIzaSyDhdrRjAIoJwHfbqXoMji4zb9V_cxaPORI`;
                        url = url + `&markers=color:0x${$siteSettings.primaryColorTrimmed() || "009193"}|label:${clientInfo.name.substring(0, 1).toUpperCase()}|${result.lat},${result.lng}`;
                        //var url = "https://www.google.com/maps/embed/v1/view?zoom=15&center=" +
                        //    result.lat +
                        //    "," +
                        //    result.lng +
                        //    "&key=AIzaSyDhdrRjAIoJwHfbqXoMji4zb9V_cxaPORI";
                        clientInfo.mapSource = $sce.trustAsResourceUrl(url);
                        scope.mapHeight = 250;
                    } else {
                        scope.mapHeight = 0;
                        clientInfo.mapSource = null;
                    }

                    scope.clientInfo = clientInfo;
                    var modal = $modal({
                        templateUrl: "Templates/Controls/Services/ClientInfo",
                        scope: scope,
                        animation: "am-fade-custom",
                        backdropAnimation: "am-fade-custom",
                        show: true
                    });
                    scope.close = () => {
                        modal.hide();
                    }
                });
            }, (response) => {
                console.log("error with status code:", response.status);
            });
        }

        return $getClient;
    }
]);