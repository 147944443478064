/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience")
    .factory("$siteTimeoutService",
        ["$timeout",
            "$guarantor",
            ($timeout,
                $guarantor: zotec.patientExperience.Services.GuarantorService) => {

        const millisecondsPerMinute = 1000 * 60;

        var timeOutOccurred = () => {
            $guarantor.logOut();
            this.timeOutOccured = true;
        };

        this.timeOutPromise = null;
        this.timeOutOccured = false;

        this.SetSiteTimeout = ($timeoutInMinutes) => {
            this.CancelCurrentTimeout();
            this.timeOutOccured = false;
            this.timeOutPromise = $timeout(timeOutOccurred, $timeoutInMinutes * millisecondsPerMinute);
        }

        this.CancelCurrentTimeout = () => {
            if (this.timeOutPromise != null) {
                $timeout.cancel(this.timeOutPromise);
            }
        }

        this.DidAutoTimeoutOccur = (): boolean => {
            var currentValue = this.timeOutOccured;
            this.timeOutOccured = false;
            return currentValue;
        }

        return this;
    }
]);
