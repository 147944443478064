/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../models.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$siteSelector", ["$state", "$modal", "$rootScope", "$siteSettings", "$guarantor", "$window",
    ($state, $modal, $rootScope, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $window) => {

        return (person: zotec.patientExperience.authentication.Person) => {
            var scope = $rootScope.$new();
            scope.sites = person.sites;
            scope.currentClientId = person.clientId;

            var modal = $modal({
                scope: scope,
                templateUrl: "/Templates/Controls/Services/SiteSelector",
                backdrop: "static"
            });

            scope.selectSite = (site: zotec.patientExperience.authentication.Site) => {
                const splitUrl = site.url.split("/");
                const siteUrl = splitUrl.length == 1 ? site.url + "/" + scope.currentClientId : site.url;
                $window.location.href = $window.location.protocol + "//" + siteUrl + "/home";
                modal.hide();
            }

            return modal;

        }
    }
]);
