/// <reference path="../../models.d.ts" />
angular.module("Zotec.PatientExperience").factory("$phoneNumberUtils", [function () {
    return {
        getFormattedPhoneNumber: (phoneNumber: string) => {
            if (phoneNumber === undefined || phoneNumber === null || phoneNumber.length === 0) {
                return null;
            }
            var cleaned = phoneNumber.replace(/\D/g, '');
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]}-${match[3]}`;
            }
            return null;
        },

        getUnformattedPhoneNumber: (phoneNumber: string) => {
            if (phoneNumber === undefined || phoneNumber === null || phoneNumber.length === 0) {
                return null;
            }
            return phoneNumber.replace(/\D/g, '');
        }
    };
}]);