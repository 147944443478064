/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Contact") 
    .controller("zpContactController",
        ["$siteSettings", "$location", "$guarantor", "Restangular", "$sessionStorage", "$state",
            function (
                $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
                $location,
                $guarantor: zotec.patientExperience.Services.GuarantorService,
                Restangular: restangular.IService,
                $sessionStorage,
                $state
            ) {
                //angular bindings
                this.textcount = 0;
                this.confirmEmail = "";
                this.loggedIn = $guarantor.isLoggedIn;
                this.isFeatureToggleEnabled = $siteSettings.enableContactUsForm;
                this.siteUrl = $location.host();
                this.isMultiplePatient = false;
                this.error = null;
                this.submitButtonEnabled = this.loggedIn;

                this.requestData = {
                    clientId: $siteSettings.clientId || $sessionStorage.clientId || null,
                    dataSourceId: $siteSettings.dataSourceId || $sessionStorage.dataSourceId || null,
                    description: "",
                    email: "",
                    guarantorName: `${$guarantor.firstName} ${$guarantor.lastName}`,
                    guarantorDOB: $guarantor.dateOfBirth,
                    patientName: null,
                    patientDOB: null,
                    personId: null,
                    retUrl: `https://${this.siteUrl}${$state.href('entity.thanks')}`,
                    topic: null
                }

                const rest = $siteSettings.billingGroupRestangularized();
                if (rest != null) {
                    rest.one("clientinfo").get().then((clientInfo: zotec.patientExperience.client.ClientInfo) => {
                        if (this.requestData.clientId === null) {
                            this.requestData.clientId = clientInfo.clientId;
                        }
                        this.clientPhone = clientInfo.phone || "";
                        this.clientEmailAddress = clientInfo.billingEmailAddress || "";
                    }, () => {
                        console.log("error getting client info");
                    });
                }

                this.PatientChanged = () => {
                    for (let patient of this.relatedPatients) {
                        if (this.requestData.personId === patient.personId) {
                            this.requestData.personId = patient.personId;
                            this.requestData.patientDOB = patient.dateOfBirth;
                            this.requestData.patientName = patient.name;
                        }
                    }
                }

                this.TextChanged = () => {
                    this.textcount = $("#message").val().length;
                }

                if (this.loggedIn) {
                    $guarantor.restangularized()
                        .all("patients")
                        .getList().then((patients: [zotec.patientExperience.guarantorPatient.Patient]) => {
                            this.relatedPatients = patients;
                            if (patients.length > 1) {
                                this.isMultiplePatient = true;
                            } else {
                                this.isMultiplePatient = false;
                                if (patients.length === 1) {
                                    this.requestData.personId = patients[0].personId;
                                    this.requestData.patientName = patients[0].name;
                                    this.requestData.patientDOB = patients[0].dateOfBirth;
                                }
                            };
                        })
                        .catch(err => console.log(err));

                    Restangular.one("ContactUsForm").one("topics").get({ clientId: this.requestData.clientId, dataSourceId: this.requestData.dataSourceId })
                        .then((topics: [zotec.patientExperience.ContactUsForm.FormTopics]) => {
                            this.topics = topics;
                            this.mainTopic = topics[0].topic;
                        })
                        .catch(err => console.log(err));

                    this.submitForm = () => {
                        this.submitButtonEnabled = false;
                        this.error = null;
                        if (this.loggedIn) {
                            Restangular.all("ContactUsForm").all("formPost").post(this.requestData).then(res => {
                                if (res) {
                                    $state.go('entity.thanks');
                                } else {
                                    this.error = "Sorry, we were unable to submit your request."
                                }
                            }).catch(err => {
                                console.log(err);
                                this.error = "Sorry, we were unable to submit your request."
                            });
                        }
                        else
                        {
                            console.log("Attempt to submit the contact us form without logging in");
                            this.submitButtonEnabled = true;
                        }
                    }
                }
            }
        ]);
