/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Contact")
    .directive("zpContact", () => {
        return {
            restrict: "AE",
            controller: "zpContactController",
            controllerAs: "zpContact",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/Contact/zpContact"
        }
    });