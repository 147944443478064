/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Login")
    .controller("zpLoginController",
        ["Restangular", "$zpModal", "$staticModal", "$state", "$clientInfoService", "$siteSettings", "$guarantor",
            "$siteSelector", "$window", "$sessionStorage", "$statementModal", "$showDateOfBirthModal", "$showAccountLookUp", "$pendoService", "$patientExperienceSettings", "$q", "$siteTimeoutService",
            function (Restangular: restangular.IService, $zpModal, $staticModal, $state, $clientInfoService,
                $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService,
                $siteSelector, $window, $sessionStorage, $statementModal, $showDateOfBirthModal, $showAccountLookUp, $pendoService: zotec.patientExperience.Services.PendoService,
                $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings, $q: angular.IQService, $siteTimeoutService: zotec.patientExperience.Services.SiteTimeoutService) {

                this.sessionTimeoutInMinutes = $siteSettings.sessionExpirationInMinutes || 15;
                this.dataSourceId = $siteSettings.dataSourceId || $sessionStorage.dataSourceId || null;
                this.clientId = $siteSettings.clientId || $sessionStorage.clientId || null;
                this.clientIdProvided = this.clientId != null;
                this.formattedClientId = this.clientIdProvided ? `Q${this.clientId}`: this.clientId; 
                this.directLinkId = ($sessionStorage.directLinkId != null) ? $sessionStorage.directLinkId : 0;
                this.directLinkBillingGroupId = $sessionStorage.directLinkBillingGroupId;
                this.billingGroupId = $siteSettings.billingGroupId || 0;
                this.billingGroupLocalId = $siteSettings.billingGroupLocalId || null;
                this.billingGroupLocalIdProvided = this.billingGroupLocalId != null;
                this.chargeId = $sessionStorage.chargeId || 0;
                this.paymentsOnPatientDeviceDataID = $sessionStorage.paymentsOnPatientDeviceDataID || 0;

                this.accountNumber = $guarantor.guarantorId || $sessionStorage.guarantorId || null;
                this.accountNumberProvided = this.accountNumber != null;
                this.guarantorBirthDate = null;
                this.dateOfBirthMonth = null;
                this.dateOfBirthDay = null;
                this.dateOfBirthYear = null;
                this.emailAddress = null;
                this.mobilePhone = null;
                this.recaptchaResponse = "";
                this.allowRecaptchaResponse = false;
                this.timeoutOccurred = false;

                $sessionStorage.guarantorId = null;

                this.authBillingGroupLocalId =
                    (this.directLinkId > 0 && (this.directLinkBillingGroupId == null || this.directLinkBillingGroupId == 0))
                        ? ""
                        : (this.billingGroupLocalId || "");

                if ($guarantor.isLoggedIn) {
                    $state.go("entity.account-history");
                }

                this.loginError = null;
                this.showNeedHelpButton = false;

                if (this.dataSourceId !== null && this.clientId !== null && this.directLinkId === 0) {
                    Restangular.one("datasources", this.dataSourceId)
                        .one("clients", this.clientId)
                        .one("billinggroups", this.billingGroupId)
                        .all("clientinfo")
                        .one("charge")
                        .get({ chargeId: this.chargeId })
                        .then(
                            (clientInfo: zotec.patientExperience.client.ClientInfo) => {
                                if (clientInfo !== null) {
                                    if (clientInfo.offerEmail === false && clientInfo.offerTextMessages === false) {
                                        this.showNeedHelpButton = false;
                                    } else {
                                        Restangular.one("AppSettings")
                                            .one("pxpAccountLookupSettings")
                                            .get({ dataSourceId: this.dataSourceId, clientId: this.clientId })
                                            .then((response: zotec.patientExperience.AppSettings.PxpAccountLookupSettings) => {
                                                this.showNeedHelpButton = response.enableAccountLookup;
                                            }, (response) => {
                                                this.showNeedHelpButton = false;
                                                console.log("error with status code:", response.status);
                                            });
                                    }
                                }
                            },
                            (response) => {
                                console.log("error with status code:", response.status);
                                this.showNeedHelpButton = false;
                            });
                } else {
                    this.showNeedHelpButton = false;
                }

                var pendoConfiguration: zotec.patientExperience.Pendo.PendoConfiguration = null;

                $pendoService.loadConfiguration().then(
                    (config) => {
                        pendoConfiguration = config;
                        if (config.serviceEnabled) {
                            $pendoService.loadAgent(config.apiKey);
                            $pendoService.startAgentAnonymously(this.clientId, config.appName);
                        }
                    },
                    (error) => {
                        console.log(error);
                    });

                this.showExampleStatement = () => {
                    $statementModal();
                }

                this.showDateOfBirthTooltip = () => {
                    $showDateOfBirthModal();
                }

                this.accountLookup = () => {
                    $showAccountLookUp();
                }

                this.getTermsOfUseUrl = () => {
                    var returnUrl = $siteSettings.getTermsOfUseUrl();
                    return returnUrl;
                }

                this.autoLogoutCheck = () => {
                    this.timeoutOccurred = $siteTimeoutService.DidAutoTimeoutOccur();
                }

                this.attemptLogin = () => {
                    this.autoLogoutCheck();
                    this.guarantorBirthDate = this.dateOfBirthMonth.toString().padStart(2, '0') + '/' + this.dateOfBirthDay.toString().padStart(2, '0') + '/' + this.dateOfBirthYear;
                    var dt = new Date(this.guarantorBirthDate);
                    if (dt < new Date(1900, 1, 1) || dt > new Date()) {
                        this.loginError = "Invalid date of birth. Please try again with a valid date.";
                    }
                    else
                        var modal = $staticModal("Signing in...", () => {
                            Restangular.all("authentication")
                                .post({
                                    dataSourceId: this.dataSourceId,
                                    clientId: this.clientId,
                                    accountNumber: this.accountNumber,
                                    chargeId: $guarantor.chargeId || $sessionStorage.chargeId || 0,
                                    dateOfBirth: this.guarantorBirthDate,
                                    billingGroupLocalId: this.authBillingGroupLocalId,
                                    directLinkId: this.directLinkId,
                                    paymentsOnPatientDeviceDataID: this.paymentsOnPatientDeviceDataID,
                                    recaptchaResponse: this.recaptchaResponse
                                })
                                .then((auth: zotec.patientExperience.authentication.AuthenticationResult) => {
                                    this.loginError = null;
                                    if (auth.authenticated) {
                                        $siteTimeoutService.SetSiteTimeout(this.sessionTimeoutInMinutes);
                                        if (pendoConfiguration.serviceEnabled) {
                                            $pendoService.identify(auth.person.personId, null, auth.person.clientId, pendoConfiguration.appName);
                                        }
                                        $sessionStorage.directLinkBillingGroupId = null;
                                        $sessionStorage.directLinkId = null;
                                        if (auth.person.sites.length === 1) {
                                            $guarantor.logIn(auth.person);
                                            const splitUrl = auth.person.sites[0].url.split("/");
                                            const entityId = splitUrl.length == 1 ? auth.person.clientId : splitUrl[splitUrl.length - 1];
                                            if ($sessionStorage.destination === 'TOSPayment') {
                                                $state.go("entity.tele-med-account-history", { entityId: entityId }).then(() => { modal.hide(); });
                                            } else {
                                                $state.go("entity.account-history", { entityId: entityId }).then(() => { modal.hide(); });
                                            }
                                        } else {
                                            $siteSelector(auth.person);
                                        }
                                    } else if (auth.lockedOut) {
                                        modal.hide();
                                        this.loginError = "Your account has been locked out due to repeated login attempts.";
                                    } else if (auth.isMinor) {
                                        modal.hide();
                                        this.loginError = "Please check the birthdate you entered. It should be for the guarantor (name on the bill) and not the patient’s birthdate.";
                                    } else {
                                        modal.hide();
                                        this.loginError = "Your account number and date of birth did not match. Please try again.";
                                    }
                                }, (response) => {
                                    modal.hide();
                                    this.loginError = "There was an error logging you in. Please try again.";
                                    console.log(response);
                                });
                        });
                }

                this.allowPatientLogin = false;
                // warning! there's another feature validation inside the AuthenticationHandler.cs
                // this was done in case someone uses the global login, so we let the user log in
                // if the feature is enabled for that client.
                var queryParams = {
                    featureName: $patientExperienceSettings.features.enablePatientLoginForClientsV1,
                    featureVersion: "1.0.0",
                    dataSourceId: $siteSettings.dataSourceId,
                    clientId: $siteSettings.clientId
                };

                var deferred = $q.defer();
                Restangular.one("applicationfeatures").get<string>(queryParams)
                    .then((enabled) => {
                        if (enabled == "True") {
                            this.allowPatientLogin = true;
                        }
                        deferred.resolve();
                    }, () => {
                        console.log("error in get appsettings");
                        deferred.resolve();
                    });

                queryParams.featureName = "AllowRecaptchaResponse";

                deferred = $q.defer();
                $siteSettings.recaptchaToggle(queryParams)
                    .then((enabled) => {
                        this.allowRecaptchaResponse = enabled;
                        deferred.resolve();
                    }, () => {
                        console.log("error in get appsettings");
                        deferred.resolve();
                    });
            }
        ]);
