/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.GoodFaithEstimate")
    .controller("zpGoodFaithEstimateController",
    ["Restangular", "$zpModal", "$staticModal", "$state", "$clientInfoService", "$siteSettings", "$guarantor", "$pdf", "$http",
        function (Restangular: restangular.IService, $zpModal, $staticModal, $state, $clientInfoService, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $pdf: zotec.patientExperience.Services.PdfService, $http: angular.IHttpService) {

            this.clientName = "";
            
            this.recaptchaResponse = "";

            this.procedurePage = ({ items: [], total: 0 });
            this.selectedProcedures = ([]);

            this.showingEmail = false;
            this.emailSuccess = null;

            $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    this.clientName = info.name;
                }, () => {
                    console.log("error getting settings");
                });

            this.getProcedures = (searchTerm, select) => {
                $siteSettings.billingGroupRestangularized().all("goodfaithestimate").one("procedures").get({ filter: searchTerm, page: 1 })
                    .then((procedurePage: zotec.patientExperience.Page<zotec.patientExperience.goodFaithEstimate.ProcedureOrGroup>) => {
                        this.procedurePage = procedurePage;
                        select.refreshItems();
                    }, () => {
                        console.log("error getting settings");
                    });
            }

            this.getEstimate = () => {
                this.estimateError = null;
                return {
                    patient: {
                        name: this.patientName,
                        streetAddress: this.address,
                        zipCode: this.zipCode,
                        phoneNumber: this.phoneNumber
                    },
                    procedures: _.map(this.selectedProcedures, (procedure: zotec.patientExperience.goodFaithEstimate.ProcedureOrGroup) => { return procedure.id }),
                    acceptTerms: this.checkAgree,
                    recaptchaResponse: this.recaptchaResponse,
                    email: this.emailAddress,
                    confirmEmail: this.emailAddressConfirm,
                    acceptEmailTerms: this.emailAgree
                };
            }

            this.getPdf = () => {
                $pdf.openPost($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/goodfaithestimate/pdf", this.getEstimate(), "Generating good faith estimate...", (success: boolean) => {
                    if (!success) {
                        this.estimateError = "An error occured while processing the estimate, please try again.";
                    } else {
                        this.successfulEstimate = true;
                    }
                });
            }

            this.sendEmail = () => {
                var modal = $staticModal("Preparing email...", () => {
                    $http.post($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/goodfaithestimate/email", this.getEstimate()).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                        this.emailSuccess = response.data;
                        modal.hide();
                    }, () => {
                        console.log("error");
                        this.emailSuccess = false;
                        modal.hide();
                    });
                });
            }
       
        }
    ]);